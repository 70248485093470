import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright(props) {
    const { t } = useTranslation();
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href="/terms-and-conditions/">
                {t("TERMS_AND_CONDITIONS")}
            </Link>{" "}
            <Link color="inherit" href="/legal-notice/">
                {t("LEGAL_NOTICE")}
            </Link>{" "}
            <Link color="inherit" href="/privacy-policy/">
                {t("PRIVACY_POLICY")}
            </Link>
            <br />
            &copy;{" "}
            <Link color="inherit" href="https://esaqa.com/">
                esaqa GmbH
            </Link>
        </Typography>
    );
}

export default Copyright;
