import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import logo from "../../assets/img/logo.png";

import Copyright from "../../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function ServiceSpecificTermsForPsonoLicenseView(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Service specific terms for Psono license
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        1. Preamble
                    </Typography>
                    <Typography component="p">
                        These service specific terms are incorporated into the agreement under which esaqa has agreed to provide Services (as described at{" "}
                        <Link color="inherit" href="/terms-and-conditions/">
                            https://console.esaqa.com/terms-and-conditions/
                        </Link>
                        ) to Customer (the "Agreement"). These terms and condition are specific to "Psono license", a license key for a Psono Enterprise
                        Edition server ("software").
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        2. License types
                    </Typography>
                    <Typography component="p">There are two types of licenses:</Typography>
                    <Typography component="p">
                        - Psono EE (limited) license: Psono EE (limited) license entitles Customer to use the software for free for up to 10 End Users.
                    </Typography>
                    <Typography component="p">
                        - Psono EE license : Psono EE license entitles Customer to use the software up to a certain amount of End Users for a full year.
                    </Typography>
                    <Typography component="p">Both licenses grant Customer a non-exclusive, non-transfferable license to use the software.</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        2. Property rights
                    </Typography>
                    <Typography component="p">
                        The software is protected by international copyright laws, treaties, and other laws. esaqa and its licensors own and retain all
                        rights, ownership and all claims to the software, including all copyrights, patents, trade and trade secrets, trademarks and other
                        intellectual property rights. This terms do not transfer ownership of the software to you. With the exception of the rights
                        described in paragraphs 2, you do not acquire any rights to the software.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        3. Usage
                    </Typography>
                    <Typography component="p">
                        The service is offered on 8760 hours (12 x 730 hours, approximately 1 year) terms or a specific amount of End Users. Customer is
                        obliged to pay for the full time, cannot cancel nor downgrade the license before. The license obtained is not transferable and may only be used for a single instance of
                        the software. The license may be used for multiple instances for high availability (max 2 instances), active-passive failover (max 1
                        failover instance) or temporary (max 1 test instance) to test updates. Other setups where multiple instances of the software are
                        using the same license are forbidden. Additional instances re-using the same license will be treated as unlicensed and will require
                        the purchase of additional licenses. If a violation of these terms is discovered Customer must pay for past usage as well.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        4. Loss
                    </Typography>
                    <Typography component="p">
                        A license key is bound to the cryptographic identity of the software. In the event of loss of the keys determining the cryptographic
                        identity of the server (PRIVATE_KEY, PUBLIC_KEY, SECRET_KEY which the Customer generates during the setup of the server) or the
                        license key itself, esaqa is not obliged to provide any replacement or refunds.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        5. Cancellation
                    </Typography>
                    <Typography component="p">
                        The Customer may not terminate the license before the end of the license terms. Once a license is terminated or expired the customer
                        must delete the local installation or bring it into a license compliant state by either renewing the license or deleting all users
                        above the user limit of the Psono EE (limited) license.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        6. Refund Policy
                    </Typography>
                    <Typography component="p">
                        We offer a free 10-user Psono license, allowing potential
                        customers to test Psono thoroughly before committing to a purchase. As this free license
                        is available, we encourage all prospective buyers to utilize this opportunity to evaluate the
                        product.
                    </Typography>
                    <Typography component="p">
                        Given that a free trial option is available, purchases of Yearly Psono Licenses are non-refundable.
                        We recommend careful evaluation using the free 10-user license before making a purchase.
                    </Typography>
                    <Typography component="p">
                        If you believe you have been incorrectly charged for your license, please contact our support team at support@esaqa.com
                        within 30 days of the billing date. We will review your account and usage records to address any
                        discrepancies. Refunds for disputed charges will be issued at our discretion.
                    </Typography>
                    <Typography component="p">
                        This no-refund policy does not affect your statutory rights under applicable law, particularly
                        if you are entitled to remedies for defective or non-conforming products.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        7. Renewal
                    </Typography>
                    <Typography component="p">
                        The license does not automatically renew if not explicitly specified and needs to be renewed by Customer. If Customer fails to renew
                        the license in time esaqa may charge past usage upon renewal for the unlicensed use. If auto renewal is specified the license will
                        be automatically renewed for 8760 hours (12 x 730 hours, approximately 1 year) upon expiration and invoiced accordingly.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        8. Responsibility
                    </Typography>
                    <Typography component="p">
                        It is Customer's sole responsibility to set up a functional hardware and software environment for the software. The same applies to
                        regular data backups of involved IT systems.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        9. Warranty for software
                    </Typography>
                    <Typography component="p">
                        esaqa does not provide any warranty about the software or any of the other open source components that may be used together with the
                        software. You are advised that, given the current state of technology, program errors cannot be ruled out with 100% certainty
                        despite the greatest diligence and care.
                    </Typography>
                    <Typography component="p">
                        The software and service is provided "as is", without warranty of any kind, express or implied, including but not limited to the
                        warranties of merchantability, fitness for a particular purpose and noninfringement. in no event shall esaqa, the authors or
                        copyright holders be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising
                        from, out of or in connection with the software or the use or other dealings in the software.
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default ServiceSpecificTermsForPsonoLicenseView;
