import apiServer from "./api-server";

/**
 * GET: Returns all invoices
 *
 * @param billingAccountId
 *
 * @returns {Promise<Array>}
 */
function getInvoices(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.readInvoices(billingAccountId).then(
            (response) => {
                return resolve(response.data.invoices);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_d63a2d1f-d53a-40a1-a978-eb0c7ba3fad6");
                reject(response);
            }
        );
    });
}

/**
 * GET: Reads the PDF from the server
 *
 * @param invoiceId
 *
 * @returns {Promise<Array>}
 */
function downloadInvoice(invoiceId) {
    return new Promise((resolve, reject) => {
        return apiServer.downloadInvoice(invoiceId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * GET: Trys to pay an invoice again
 *
 * @param invoiceId
 *
 * @returns {Promise<Array>}
 */
function payInvoice(invoiceId) {
    return new Promise((resolve, reject) => {
        return apiServer.payInvoice(invoiceId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_e6860da4-e325-4e3b-b786-f4ffe048c0dc");
                reject(response);
            }
        );
    });
}

const service = {
    getInvoices,
    downloadInvoice,
    payInvoice,
};

export default service;
