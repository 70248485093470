import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import BillingAccountUserLIstTable from "./billingAccountUserListTable";
import ContentBody from "../../../container/content-body";
import ContentHeader from "../../../container/content-header";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    titleText: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
}));

function BillingAccountUserList (props) {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const params = useParams();

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                <span className={classes.titleText}>{t("BILLING_ACCOUNT_PERMISSIONS")}</span>
            </Typography>
        </ContentHeader>
        <ContentBody>
            <BillingAccountUserLIstTable billingAccountId={params.billingAccountId} />
        </ContentBody>
    </>;

}
export default BillingAccountUserList;
