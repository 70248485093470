import React from "react";

import { Route, Switch } from "react-router-dom";
import GeneralTermsAndConditions from "./general-terms-and-conditions";
import PsonoSaas from "./psono-saas";
import PsonoLicenses from "./psono-license";
import AcceptableUsePolicy from "./acceptable-use-policy";


function Index(props) {
    return (
        <Switch>
            <Route path="/terms-and-conditions/psono-saas/">
                <PsonoSaas />
            </Route>
            <Route path="/terms-and-conditions/psono-license/">
                <PsonoLicenses />
            </Route>
            <Route path="/terms-and-conditions/acceptable-use-policy/">
                <AcceptableUsePolicy />
            </Route>
            <Route path="/terms-and-conditions/">
                <GeneralTermsAndConditions />
            </Route>
        </Switch>
    );
}

export default Index;
