import { combineReducers } from "redux";
import {LOGOUT, SET_PROJECT} from "./action-types";

const defaultProject = null;

function main(
    state = {
        project: defaultProject,
    },
    action
) {
    switch (action.type) {
        case LOGOUT:
            return Object.assign({}, state, {
                project: defaultProject,
            });
        case SET_PROJECT:
            return Object.assign({}, state, {
                project: action.project,
            });
        default:
            return state;
    }
}


const rootReducer = combineReducers({
    main,
});

export default rootReducer;
