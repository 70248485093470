import React from "react";

import { Route, Switch } from "react-router-dom";
import ProjectPermission from "./permissions/index";

function Index(props) {
    return (
        <Switch>
            <Route path="/projects/permissions/:id/">
                <ProjectPermission />
            </Route>
        </Switch>
    );
}
export default Index;
