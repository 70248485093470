import apiServer from "./api-server";

/**
 * Returns all billing account invites for a certain billing account or a certain specific billing account invite
 *
 * @param billingAccountId
 * @param billingAccountInviteUserId
 * @param billingAccountInviteSecret
 *
 * @returns {Promise<Array>}
 */
function getBillingAccountInviteUsers(billingAccountId, billingAccountInviteUserId, billingAccountInviteSecret) {
    return new Promise((resolve, reject) => {
        return apiServer.readBillingAccountInviteUsers(billingAccountId, billingAccountInviteUserId, billingAccountInviteSecret).then(
            (response) => {
                if (billingAccountId) {
                    return resolve(response.data.billing_account_invite_users);
                } else {
                    return resolve(response.data.billing_account_invite);
                }
            },
            (response) => {
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a billing account invite
 *
 * @param billingAccountId
 * @param email
 * @param billingAccountRoleId
 *
 * @returns {Promise<unknown>}
 */
function createBillingAccountInviteUser(billingAccountId, email, billingAccountRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.createBillingAccountInviteUser(billingAccountId, email, billingAccountRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Updates a billing account invite
 *
 * @param billingAccountInviteId
 * @param billingAccountRoleId
 *
 * @returns {Promise<unknown>}
 */
function updateBillingAccountInviteUser(billingAccountInviteId, billingAccountRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.updateBillingAccountInviteUser(billingAccountInviteId, billingAccountRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Accepts a billing account invite
 *
 * @param billingAccountInviteId
 * @param billingAccountInviteSecret
 *
 * @returns {Promise<unknown>}
 */
function acceptBillingAccountInviteUsers(billingAccountInviteId, billingAccountInviteSecret) {
    return new Promise((resolve, reject) => {
        return apiServer.acceptBillingAccountInviteUsers(billingAccountInviteId, billingAccountInviteSecret).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Rejects a billing account invite
 *
 * @param billingAccountInviteId
 * @param billingAccountInviteSecret
 *
 * @returns {Promise<unknown>}
 */
function rejectBillingAccountInviteUsers(billingAccountInviteId, billingAccountInviteSecret) {
    return new Promise((resolve, reject) => {
        return apiServer.rejectBillingAccountInviteUsers(billingAccountInviteId, billingAccountInviteSecret).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Deletes a billing account invite
 *
 * @param billingAccountInviteUserId
 *
 * @returns {Promise<unknown>}
 */
function deleteBillingAccountInviteUser(billingAccountInviteUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteBillingAccountInviteUser(billingAccountInviteUserId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getBillingAccountInviteUsers,
    deleteBillingAccountInviteUser,
    createBillingAccountInviteUser,
    updateBillingAccountInviteUser,
    acceptBillingAccountInviteUsers,
    rejectBillingAccountInviteUsers,
};

export default service;
