import React from "react";
import PropTypes from "prop-types";

import { default as DefaultDialog } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

function Dialog(props) {
        const { maxWidth, fullWidth, onClose, open, title, children } = props;

        return (
            <DefaultDialog maxWidth={maxWidth} fullWidth={fullWidth} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                {children}
            </DefaultDialog>
        );

}

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
    fullWidth: PropTypes.bool,
};

export default Dialog;
