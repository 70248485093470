import apiServer from "./api-server";

let cachedProjectRoles;

/**
 * GET: Returns the roles for projects
 *
 * @returns {Promise<Array>}
 */
function getProjectRoles() {
    return new Promise((resolve, reject) => {
        if (cachedProjectRoles && cachedProjectRoles.length) {
            return resolve(cachedProjectRoles);
        } else {
            return apiServer.readProjectRoles().then(
                (response) => {
                    cachedProjectRoles = response.data.project_roles;
                    return resolve(cachedProjectRoles);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_fe0cbd05-2661-427b-bb8e-91c324b2761e");
                    reject(response);
                }
            );
        }
    });
}

const service = {
    getProjectRoles,
};

export default service;
