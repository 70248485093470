import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import makeStyles from '@mui/styles/makeStyles';

import BillingAccountRoleSelectField from "../../../components/billing-account-role-select-field";
import ContentBody from "../../../container/content-body";
import ContentHeader from "../../../container/content-header";
import billingAccountPermissionUserService from "../../../services/billing-account-permission-users";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },

}));


function BillingAccountDetail(props) {

    const classes = useStyles();
    const params = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const [userEmail, setUserEmail] = useState("");
    const [role, setRole] = useState("");
    const [errors, setErrors] = useState({});

    React.useEffect(() => {
        const billingAccountPermissionUserId = params.billingAccountPermissionUserId;
        billingAccountPermissionUserService.getBillingAccountPermissionUser(billingAccountPermissionUserId).then(
            (billingAccountPermissionUser) => {
                setUserEmail(billingAccountPermissionUser.user_email);
                setRole(billingAccountPermissionUser.role);
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);

    const onEdit = () => {
        setErrors({})

        const billingAccountPermissionUserId = params.billingAccountPermissionUserId;
        billingAccountPermissionUserService.updateBillingAccountPermissionUser(billingAccountPermissionUserId, role).then(
            (response) => {
                history.goBack();
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("EDIT_BILLING_ACCOUNT_PERMISSION")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        disabled
                        id="userEmail"
                        label={t("EMAIL")}
                        name="userEmail"
                        autoComplete="userEmail"
                        value={userEmail}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BillingAccountRoleSelectField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="role"
                        error={errors && "role" in errors}
                        helperText={errors && "role" in errors ? t(errors["role"][0]) : ""}
                        value={role}
                        onChange={(role) => {
                            setRole(role)
                        }}
                    />
                </Grid>
            </Grid>

            <div className={classes.buttons}>
                <Button variant="contained" color="primary" onClick={onEdit}>
                    {t("EDIT")}
                </Button>
            </div>
        </ContentBody>
    </>;

}
export default BillingAccountDetail;
