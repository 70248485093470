import React from "react";

import Alert from '@mui/material/Alert';

import ContentBody from "../container/content-body";
import {useTranslation} from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({}));

function SelectProject(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <ContentBody>
                <Alert variant="outlined" severity="warning" className={classes.alert}>
                    {t("SELECT_OR_CREATE_PROJECT_FIRST")}
                </Alert>
            </ContentBody>
        </>
    );
}
export default SelectProject;
