import apiServer from "./api-server";

let cachedBillingAccounts;

/**
 * Returns all billing accounts
 *
 * @returns {Promise<Array>}
 */
function getBillingAccounts() {
    return new Promise((resolve, reject) => {
        if (cachedBillingAccounts && cachedBillingAccounts.length) {
            return resolve(cachedBillingAccounts);
        } else {
            return apiServer.readBillingAccounts().then(
                (response) => {
                    cachedBillingAccounts = response.data.billing_accounts;
                    return resolve(cachedBillingAccounts);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_2bcc78c8-9f26-471a-a307-a868fcb53ecc");
                    reject(response);
                }
            );
        }
    });
}

/**
 * Returns the details of a specific billing account
 *
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function getBillingAccount(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.readBillingAccounts(billingAccountId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_3ee62df5-c7ea-4e20-8da8-a152bc24453c");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a billing account
 *
 * @param name
 * @param companyName
 * @param address1
 * @param address2
 * @param zipCode
 * @param city
 * @param country
 * @param state
 * @param vatRegNo
 *
 * @returns {Promise<unknown>}
 */
function createBillingAccount(name, companyName, address1, address2, zipCode, city, country, state, vatRegNo) {
    return new Promise((resolve, reject) => {
        return apiServer.createBillingAccount(name, companyName, address1, address2, zipCode, city, country, state, vatRegNo).then(
            (response) => {
                cachedBillingAccounts = undefined;

                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * update a billing account
 *
 * @param billingAccountId
 * @param name
 * @param companyName
 * @param address1
 * @param address2
 * @param zipCode
 * @param city
 * @param state
 * @param vatRegNo
 *
 * @returns {Promise<unknown>}
 */
function updateBillingAccount(billingAccountId, name, companyName, address1, address2, zipCode, city,  state, vatRegNo) {
    return new Promise((resolve, reject) => {
        return apiServer.updateBillingAccount(billingAccountId, name, companyName, address1, address2, zipCode, city, state, vatRegNo).then(
            (response) => {
                cachedBillingAccounts = undefined;
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * deletes a billing account
 *
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function deleteBillingAccount(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteBillingAccount(billingAccountId).then(
            (response) => {
                cachedBillingAccounts = undefined;
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getBillingAccounts,
    getBillingAccount,
    deleteBillingAccount,
    createBillingAccount,
    updateBillingAccount,
};

export default service;
