import apiServer from "./api-server";
import { getStore } from "./store";
import action from "../redux/bound-action-creators";

let projectsSingleton;

/**
 * GET: Returns all projects
 *
 * @returns {Promise<Array>}
 */
function _getProjects() {
    return apiServer.readProjects().then(
        (response) => {
            const selectedProject = getStore().getState().main.project;
            const projects = response.data.projects
            const projectIds = projects.map((project) => project.id)

            if (projects.length > 0 && (!selectedProject || !projectIds.includes(selectedProject.id))) {
                action().setProject(projects[0]);
            } else if (projects.length > 0 && selectedProject && projectIds.includes(selectedProject.id)) {
                const matchingProject = projects.find((project) => project.id === selectedProject.id);
                action().setProject(matchingProject);
            } else if (projects.length === 0 && selectedProject && !projectIds.includes(selectedProject.id)) {
                action().setProject(null);
            }
            return projects;
        },
        (response) => {
            console.log("UNEXPECTED_ERROR_63522d26-9aee-4fe1-adbb-270de7c6ab99");
            return Promise.reject(response)
        }
    );
}

/**
 * GET: Returns all projects
 *
 * @returns {Promise<Array>}
 */
function getProjects() {
    if (!projectsSingleton) {
        projectsSingleton = _getProjects();
    }
    return projectsSingleton;
}

/**
 * GET: Returns all projects
 *
 * @returns {Promise<Array>}
 */
function refreshProject() {
    projectsSingleton = _getProjects();
    return projectsSingleton;
}

/**
 * GET: Returns a single project by its id
 *
 * @returns {Promise<unknown>}
 */
function getProject(projectId) {
    return new Promise((resolve, reject) => {
        return apiServer.readProjects(projectId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_a57ebf78-06f8-439f-9851-2daa761a6c38");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a project
 *
 * @param name
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function createProject(name, billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.createProject(name, billingAccountId).then(
            (response) => {
                projectsSingleton = null;
                action().setProject(response.data)
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Updates a project
 *
 * @param projectId
 * @param name
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function updateProject(projectId, name, billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.updateProject(projectId, name, billingAccountId).then(
            (response) => {
                projectsSingleton = null;
                action().setProject(response.data)
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Marks another project as selected
 *
 * @param newSelectedProject
 *
 * @returns {Promise<unknown>}
 */
function selectProject(newSelectedProject) {
    action().setProject(newSelectedProject)
}

const service = {
    getProjects,
    refreshProject,
    getProject,
    updateProject,
    createProject,
    selectProject,
};

export default service;
