import React from "react";
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import logo from "../../assets/img/logo.png";

import Copyright from "../../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function GeneralTermsAndConditionsView(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="md">
            <Helmet>
                <title>Terms and conditions</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Terms and conditions
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        1. Preamble
                    </Typography>
                    <Typography component="p">
                        These terms and conditions including all linked documents ("Agreement") are entered into by esaqa GmbH, Tiergartenstr. 13, 91247
                        Vorra, Germany ("esaqa") and the entity or person agreeing to these terms and conditions ("Customer") and govern Customer's access
                        to and use of all online Services, licenses and its corresponding software (all together "Services") that can be obtained on
                        console.esaqa.com ("Console").
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        2. General
                    </Typography>
                    <Typography component="p">2.1. esaqa may make Services available to Customer, including third-party services.</Typography>
                    <Typography component="p">2.2. esaqa may modify Services from time to time.</Typography>
                    <Typography component="p">
                        2.3. esaqa may modify this agreement from time to time including pricing (and any linked documents) with an appropriate notice of at
                        least 4 weeks.
                    </Typography>
                    <Typography component="p">
                        2.4. Unless otherwise agreed Customer must have an account to use Services and sole responsible for the provided information,
                        security of is authentication credentials and use of its account.
                    </Typography>
                    <Typography component="p">2.5. Customer may only use the Services if agreeing to these terms and condtions.</Typography>
                    <Typography component="p">
                        2.6. Customer must be an entrepreneur with the meaning of § 14 BGB and not a consumer within the meaning of § 13 BGB
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        3. Payment terms
                    </Typography>
                    <Typography component="p">
                        3.1 At the end of every month or as otherwise stated by esaqa in the Console, esaqa will issue and electronic bill to the customer
                        for all charges based on Customer's use of the Services. The customer will pay all fees in the currency stated in the invoice. If
                        customer elects to pay by credit card, debit card or any other non-invoices form of payment esaqa will charge and Customer will pay
                        all fees immediately. esaqa's measurement of Customer's use of the Services is final. esaqa has no obligation to provide multiple
                        bills.
                    </Typography>
                    <Typography component="p">
                        3.2 Customer is responsible for any taxes and Customer will pay esaqa for the Services without any reduction for taxes. If esaqa is
                        obliged to collect or pay taxes, the taxes will be invoices to Customer and Customer will pay such taxes to esaqa in addition to the
                        fees, unless Customer provides esaqa with a timely and valid tax exemption certificate.
                    </Typography>
                    <Typography component="p">
                        3.3 If required under applicable law, Customer will provide esaqa with applicable tax information that esaqa may require to ensure
                        compliance with applicable authorities and tax regulations in applicable jurisdictions. Customer will be liable to pay (or reimburse
                        esaqa for) any taxes, interest, penalties, or fines arising out of any mis-declaration by Customer.
                    </Typography>
                    <Typography component="p">
                        3.4 Any invoice disputes must be submitted before the payment due date. If the parties determine that certain billing inaccuracies
                        are attributable to esaqa, esaqa will not issue a corrected invoice, but will instead issue a credit memo specifying the incorrect
                        amount in the affected invoice. If the disputed invoice has not yet been paid, esaqa will apply the credit memo amount to the
                        disputed invoice and Customer will be responsible for paying the resulting net balance due on that invoice. To the fullest extent
                        permitted by law, Customer waives all claims relating to fees unless claimed within 60 days after the invoice date. Refunds (if any)
                        are at esaqa's discretion and will only be in the form of credit for the Services. Nothing in this Agreement obligates esaqa to
                        extend credit to any party.
                    </Typography>
                    <Typography component="p">
                        3.5 Late payments may bear interest at the rate of 1.5% per month (or the highest rate permitted by law, if less) from the payment
                        due date until paid in full. Customer will be responsible for all reasonable expenses (including attorneys' fees) incurred by esaqa
                        in collecting such delinquent amounts. If Customer is late on payment for the Services, esaqa may Suspend the Services or terminate
                        the Agreement for breach under Section 4.1.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        4. Termination
                    </Typography>
                    <Typography component="p">
                        4.1 Either party may terminate this Agreement if (a) the other party is in material breach of the Agreement and fails to cure that
                        breach within 30 days after receipt of written notice or (b) the other party ceases its business operations or becomes subject to
                        insolvency proceedings and the proceedings are not dismissed within 90 days.
                    </Typography>
                    <Typography component="p">
                        4.2 esaqa reserves the right to terminate the provision of the Services to a Project upon 30 days' advance notice if, for a period
                        of 60 days (a) Customer has not accessed the Console or the Project has had no network activity and (b) such Project has not
                        incurred any Fees for such Services.
                    </Typography>
                    <Typography component="p">
                        4.3 Customer may stop using the Services at any time. Customer may terminate this Agreement for its convenience at any time on prior
                        written notice and, upon termination, must cease use of the applicable Services. esaqa may terminate this Agreement for its
                        convenience at any time with 30 days' prior written notice to Customer.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        5. Subcontracting
                    </Typography>
                    <Typography component="p">
                        esaqa may subcontract obligations under the Agreement but will remain liable to Customer for any subcontracted obligations.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        6. Customer Obligations
                    </Typography>
                    <Typography component="p">
                        6.1. Customer will (a) ensure that Customer and its End Users' use of the Services complies with the Agreement, (b) use commercially
                        reasonable efforts to prevent and terminate any unauthorized use of, or access to, the Services, and (c) promptly notify esaqa of
                        any unauthorized use of, or access to, the Services, account, or Customer's authentication credentials of which Customer becomes
                        aware. esaqa reserves the right to investigate any potential violation of the Acceptable Use Policy (AUP{" "}
                        <Link color="inherit" href="/terms-and-conditions/acceptable-use-policy/">
                            https://console.esaqa.com/terms-and-conditions/acceptable-use-policy/
                        </Link>
                        ) by Customer, which may include reviewing Customer applications, customer data, or projects.
                    </Typography>
                    <Typography component="p">
                        6.2. Customer is responsible for any consents and notices required to permit (a) Customer and End User's use and receipt of Services
                        and (b) esaqa's accessing, storing, and processing of data provided by Customer and End User (including customer data, if
                        applicable) under the Agreement.
                    </Typography>
                    <Typography component="p">
                        6.3. Customer will not, and will not allow End Users to, (a) copy, modify, or create a derivative work of the Services; (b) reverse
                        engineer, decompile, translate, disassemble, or otherwise attempt to extract any or all of the source code of the Services (except
                        to the extent such restriction is expressly prohibited by applicable law); (c) sell, resell, sublicense, transfer, or distribute any
                        or all of the Services; or (d) access or use the Services (i) for High Risk Activities; (ii) in violation of the AUP; (iii) in a
                        manner intended to avoid incurring Fees (including creating multiple customer applications, accounts, or projects to simulate or act
                        as a single customer application, account, or project (respectively)) or to circumvent service-specific usage limits or quotas; (iv)
                        to engage in cryptocurrency mining without esaqa's prior written approval; (v) to operate or enable any telecommunications service
                        or in connection with any customer application that allows End Users to place calls or to receive calls from any public switched
                        telephone network, unless otherwise described in the service specific terms (described in Section 14); (vi) for materials or
                        activities that are subject to the International Traffic in Arms Regulations (ITAR); (vii) in a manner that breaches, or causes the
                        breach of, Export Control Laws; or (viii) to transmit, store, or process health information subject to government regulations.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        7. Intellectual Property
                    </Typography>
                    <Typography component="p">
                        Except as expressly stated in this Agreement, this Agreement does not grant Customer any rights, implied or otherwise, to the
                        other's content or any of the other's intellectual property. esaqa owns all Intellectual Property Rights in the Services and
                        software.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        8. Technical support
                    </Typography>
                    <Typography component="p">
                        esaqa offers technical support under separate support contracts. The provision of technical support without support contracts is at
                        the sole discretion of esaqa and is not associated with any guarantee or warranty. It is Customer responsibility to back up all of
                        existing data, software and programs before receiving technical support from esaqa. esaqa reserves the right, in its sole
                        discretion, to refuse, suspend or terminate any technical support.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        9. Limitation of liability
                    </Typography>
                    <Typography component="p">
                        Neither we nor our related parties are liable to you or any third party for any loss of profits, loss of use, loss of revenue, loss
                        of goodwill, any interruption of business, loss of or damage to business or reputation, loss of opportunity, loss of advantage, loss
                        of use of any software or data, loss of use of any mobile phone or other equipment, loss of use of the system on which the site,
                        esaqa software or esaqa services are used, or for any indirect, special, incidental, exemplary, punitive or consequential damages of
                        any kind arising out of or in connection with this agreement, the site, the esaqa software, the esaqa services or third party
                        content, regardless of the form of action, whether in contract, tort, strict liability or otherwise, even if we have been advised or
                        are otherwise aware of the possibility of such damages.
                    </Typography>
                    <Typography component="p">
                        In no event shall our and our related party’s total cumulative liability for any loss of profits, loss of use, loss of revenue, loss
                        of goodwill, any interruption of business, loss of or damage to business or reputation, loss of opportunity, loss of advantage, loss
                        of use of any software or data, loss of use of any mobile phone or other equipment, loss of use of the system on which the site,
                        esaqa software or esaqa services are used, or for any direct, indirect, special, incidental, exemplary, punitive or consequential
                        damages of any kind arising out of or in connection with this agreement, the site, the esaqa software, the esaqa services or third
                        party content, regardless of the form of action, whether in contract, tort, strict liability or otherwise, even if we or our related
                        parties have been advised or are otherwise aware of the possibility of such damages, exceed the amount paid by you for your use of
                        the esaqa services and the esaqa software in the six (6) months prior to the accrual of the first claim. multiple claims will not
                        expand this limitation. this section will be given full effect even if any remedy specified in this agreement is deemed to have
                        failed of its essential purpose.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        10. Legal notice
                    </Typography>
                    <Typography component="p">
                        Communications made through e-mail and other non-physical messaging systems, will not constitute legal notice to esaqa or any of its
                        officers, employees, agents or representatives in any situation where written paper notice to esaqa is required by contract or any
                        law or regulation.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        11. No agency
                    </Typography>
                    <Typography component="p">This agreement does not create an agency, partnership, or joint venture.</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        12. German export jurisdiction
                    </Typography>
                    <Typography component="p">
                        German export jurisdiction. The Products are subject to German export jurisdiction. You must comply with all applicable laws,
                        including the German Export Regulations, the International Traffic in Arms Regulations, and end-user, end-use and destination
                        restrictions issued by Germany and other governments.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        13. Force majeure
                    </Typography>
                    <Typography component="p">
                        Neither party will be liable for any failure in performance due to causes beyond that party’s reasonable control (such as fire,
                        explosion, power blackout, earthquake, flood, severe storms, strike, embargo, labor disputes, acts of civil or military authority,
                        war, terrorism (including cyber terrorism), acts of God, acts or omissions of Internet traffic carriers, actions or omissions of
                        regulatory or governmental bodies (including the passage of laws or regulations or other acts of government that impact the delivery
                        of Services)). This Section will not, however, apply to your payment obligations under this agreement.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        14. No third-party beneficiaries
                    </Typography>
                    <Typography component="p">There are no third-party beneficiaries to this agreement.</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        15. Change of control
                    </Typography>
                    <Typography component="p">
                        If a party experiences a change of control (for example, through a stock purchase or sale, merger, or other form of corporate
                        transaction), that party will give written notice to the other party within 30 days after the change of control.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        16. Authority
                    </Typography>
                    <Typography component="p">
                        If you are an individual accepting these terms on behalf of an entity, you represent that you have the legal authority to enter into
                        this agreement on that entity’s behalf. If you specify an entity, or you use an email address provided by an entity you are
                        affiliated with (such as an employer) in connection with a service purchase or renewal, that entity will be treated as the owner of
                        the service for purposes of this agreement.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        17. Waiver
                    </Typography>
                    <Typography component="p">Failure to enforce any provision of this agreement will not constitute a waiver.</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        18. Jurisdiction
                    </Typography>
                    <Typography component="p">The place of jurisdiction is Nuremberg, Germany.</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        19. Service Specific Terms
                    </Typography>
                    <Typography component="p">
                        Customer's use of any Software is subject in addition to the applicable provisions in the Service Specific Terms:
                    </Typography>
                    <Typography component="p">
                        - Service Specific Terms for Psono SaaS:{" "}
                        <Link color="inherit" href="/terms-and-conditions/psono-saas/">
                            https://console.esaqa.com/terms-and-conditions/psono-saas/
                        </Link>
                    </Typography>
                    <Typography component="p">
                        - Service Specific Terms for Psono licenses:{" "}
                        <Link color="inherit" href="/terms-and-conditions/psono-license/">
                            https://console.esaqa.com/terms-and-conditions/psono-license/
                        </Link>
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        20. Other documents
                    </Typography>
                    <Typography component="p">
                        Other documents that are incorporated into this Agreement include:
                    </Typography>
                    <Typography component="p">
                        - Data Processing Addendum here <Link color="inherit" href="/dpa/">https://console.esaqa.com/dpa/</Link>
                    </Typography>
                    <Typography component="p">
                        - Technical Organizational Measures here <Link color="inherit" href="/tom/">https://console.esaqa.com/tom/</Link>
                    </Typography>
                    <Typography component="p">
                        - Subprocessors here <Link color="inherit" href="/subprocessor/">https://console.esaqa.com/subprocessor/</Link>
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        21. Severability clause
                    </Typography>
                    <Typography component="p">
                        Should individual terms of this Agreement be entirely or partly ineffective or lose their legal effectiveness due to later
                        circumstances, the validity of the other provisions is not affected. The invalid provision shall be replaced by an appropriate
                        provision which comes as close as possible to what the Parties to the agreement would have wanted, had they known about the invalid
                        provision.
                    </Typography>


                    <Typography style={{ marginTop: "20px", marginBottom: "20px" }}>
                        Last updated Aug 10th 2024
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default GeneralTermsAndConditionsView;
