import React, {useState} from 'react';
import i18n from "i18next";
import {useTranslation} from "react-i18next";

import {Box, Button, Menu, MenuItem, Typography} from '@mui/material';

import MeService from "../services/me";
import { languages } from "../i18n";

const Footer = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const loadLanguage = async () => {
        const me = await MeService.readMe();
        i18n.changeLanguage(me.locale || 'en')
    }

    React.useEffect(() => {
        loadLanguage();
    }, []);

    // Handles opening the dropdown menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handles closing the dropdown menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLanguage = (language) => {
        setAnchorEl(null);
        MeService.updateMe(language)
        i18n.changeLanguage(language);
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 4,
                right: 8,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Button
                onClick={handleClick}
                sx={{
                    textTransform: 'none',
                    color: 'primary.main',
                    textDecoration: 'underline',
                    '&:hover': {textDecoration: 'none'},
                }}
            >
                {t("LANGUAGE")}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {Object.entries(languages)
                    .filter(([key, value]) => value.active)
                    .sort(([, a], [, b]) => a.lng_title_native.localeCompare(b.lng_title_native))
                    .map(([key, value]) => (
                        <MenuItem
                            key={value.code}
                            onClick={() => onLanguage(value.code)}
                        >
                            {value.lng_title_native}
                        </MenuItem>
                    ))}
            </Menu>

            <Typography variant="body2" sx={{ml: 2, color: 'text.secondary'}}>
                © {new Date().getFullYear()} esaqa GmbH
            </Typography>
        </Box>
    );
};

export default Footer;