import apiServer from "./api-server";

/**
 * Returns all psono saas instances
 *
 * @returns {Promise<Array>}
 */
function getPsonoLicenses(projectId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoLicenses(undefined, projectId).then(
            (response) => {
                return resolve(response.data.psono_licenses);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_963f99cc-fbc4-4881-ad5a-27ea5a808658");
                reject(response);
            }
        );
    });
}

/**
 * Returns the details of a specific psono saas instance
 *
 * @returns {Promise<Array>}
 */
function getPsonoLicense(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoLicenses(billingAccountId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_e34530e6-563c-4594-8e24-5f5f67f37939");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a psono saas instance
 *
 * @param {string} projectId
 * @param {string} name
 * @param {string} publicKey
 * @param {string} variation
 * @param {boolean} autoRenew
 *
 * @returns {Promise<unknown>}
 */
function createPsonoLicense(projectId, name, publicKey, variation, autoRenew) {
    return new Promise((resolve, reject) => {
        return apiServer.createPsonoLicense(projectId, name, publicKey, variation, autoRenew).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * update a psono saas instance
 *
 * @param {string} psonoLicenseId
 * @param {string} name
 * @param {string} variation
 * @param {boolean} autoRenew
 *
 * @returns {Promise<unknown>}
 */
function updatePsonoLicense(psonoLicenseId, name, variation, autoRenew) {
    return new Promise((resolve, reject) => {
        return apiServer.updatePsonoLicense(psonoLicenseId, name, variation, autoRenew).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getPsonoLicenses,
    getPsonoLicense,
    createPsonoLicense,
    updatePsonoLicense,
};

export default service;
