import apiServer from "./api-server";

/**
 *
 * Creates a stripe intent
 *
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function createStripeIntent(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.createStripeIntent(billingAccountId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}
/**
 *
 * Notifies the backend to update a stripe payment
 *
 * @param stripePaymentId
 *
 * @returns {Promise<unknown>}
 */
function updateStripePayment(stripePaymentId) {
    return new Promise((resolve, reject) => {
        return apiServer.updateStripePayment(stripePaymentId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}
const service = {
    createStripeIntent,
    updateStripePayment,
};

export default service;
