import apiServer from "./api-server";

/**
 * Returns the details of a specific psono saas custom domain
 *
 * @returns {Promise<Array>}
 */
function getPsonoSaasLog(psonoSaasInstanceId, startTime, endTime, query) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoSaasLogs(psonoSaasInstanceId, startTime, endTime, query).then(
            (response) => {
                return resolve(response.data.psono_saas_logs);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_ad1b3356-19b9-496e-a174-741bd424a7cf");
                reject(response);
            }
        );
    });
}

const service = {
    getPsonoSaasLog,
};

export default service;
