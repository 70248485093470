import apiServer from "./api-server";

/**
 * Returns all psono saas instances
 *
 * @returns {Promise<Array>}
 */
function getPsonoSaasInstances(projectId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoSaasInstances(undefined, projectId).then(
            (response) => {
                return resolve(response.data.psono_saas_instances);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_8444de7f-f374-475c-8b10-f92277f42dfd");
                reject(response);
            }
        );
    });
}

/**
 * Returns the details of a specific psono saas instance
 *
 * @returns {Promise<Array>}
 */
function getPsonoSaasInstance(psonoSaasInstanceId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoSaasInstances(psonoSaasInstanceId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_3ee62df5-c7ea-4e20-8da8-a152bc24453c");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a psono saas instance
 *
 * @param projectId
 * @param psonoSaasRegionId
 * @param name
 * @param slug
 * @param variation
 *
 * @returns {Promise<unknown>}
 */
function createPsonoSaasInstance(projectId, psonoSaasRegionId, name, slug, variation) {
    return new Promise((resolve, reject) => {
        return apiServer.createPsonoSaasInstance(projectId, psonoSaasRegionId, name, slug, variation).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * update a psono saas instance
 *
 * @param {string} psonoSaasInstanceId
 * @param {string} cPortalConfigJson
 * @param {string} cWebclientConfigJson
 * @param {string} name
 * @param {number} sActivationLinkTimeValid
 * @param {boolean} sAllowLostPassword
 * @param {boolean} sAllowMultipleSessions
 * @param {boolean} sAllowRegistration
 * @param {boolean} sAllowUserSearchByEmail
 * @param {boolean} sAllowUserSearchByUsernamePartial
 * @param {string} sAllowedDomains
 * @param {string} sAllowedSecondFactors
 * @param {string} sAuthenticationMethods
 *
 * @param {string} sSplunkHost
 * @param {number} sSplunkPort
 * @param {string} sSplunkToken
 * @param {string} sSplunkIndex
 * @param {string} sSplunkProtocol
 * @param {boolean} sSplunkVerify
 * @param {string} sSplunkSourcetype
 *
 * @param {string} sS3LoggingBucket
 * @param {string} sS3LoggingAccessKeyId
 * @param {string} sS3LoggingSecretAccessKey
 *
 * @param {string} sLogstashTransport
 * @param {string} sLogstashHost
 * @param {number} sLogstashPort
 * @param {boolean} sLogstashSslEnabled
 * @param {boolean} sLogstashSslVerify
 *
 * @param {number} sAutoProlongationTokenTimeValid
 * @param {number} sCCentralSecurityReportSecurityRecurrenceInterval
 *
 * @param {boolean} sCDisableApiKeys
 * @param {string} sCServerSecrets
 * @param {boolean} sCDisableDeleteAccount
 * @param {boolean} sCDisableEmergencyCodes
 * @param {boolean} sCDisableExport
 * @param {boolean} sCDisableExportOfSharedItems
 * @param {boolean} sCDisableFileRepositories
 * @param {boolean} sCDisableLinkShares
 * @param {boolean} sCDisableRecoveryCodes
 * @param {boolean} sCEnforce2fa
 * @param {boolean} sCDisableUnmanagedGroups
 * @param {boolean} sCEnforceCentralSecurityReports
 * @param {number} sCMinMasterPasswordComplexity
 * @param {number} sCMinMasterPasswordLength
 * @param {number} sCMaxOfflineCacheTimeValid
 * @param {string} sCIPRestrictions
 *
 * @param {number} sCPasswordGeneratorDefaultPasswordLength
 * @param {string} sCPasswordGeneratorDefaultLettersUppercase
 * @param {string} sCPasswordGeneratorDefaultLettersLowercase
 * @param {string} sCPasswordGeneratorDefaultNumbers
 * @param {string} sCPasswordGeneratorDefaultSpecialChars

 * @param {number}sDefaultTokenTimeValid
 * @param {boolean} sDisableCentralSecurityReports
 * @param {number} sDisableLastPasswords
 * @param {string} sDuoIntegrationKey
 * @param {string} sDuoSecretKey
 * @param {string} sDuoApiHostname
 * @param {boolean} sEnforceMatchingUsernameAndEmail
 * @param {boolean} sFilesEnabled
 * @param {boolean} sLoggingAudit
 * @param {boolean} sFileserverHandlerEnabled
 * @param {number} sMaxApiKeyTokenTimeValid
 * @param {number} sMaxAppTokenTimeValid
 * @param {number} sMaxWebclientTokenTimeValid
 * @param {boolean} sMultifactorEnabled
 * @param {number} sRecoveryVerifierTimeValid
 * @param {string} sRegistrationEmailFilter
 * @param {string} sLdapgateway
 * @param {string} sScimConfigurations
 * @param {string} sSamlConfigurations
 * @param {string} sOidcConfigurations
 * @param {string} variation
 *
 * @returns {Promise<unknown>}
 */
function updatePsonoSaasInstance(
    psonoSaasInstanceId,
    cPortalConfigJson,
    cWebclientConfigJson,
    name,
    sActivationLinkTimeValid,
    sAllowLostPassword,
    sAllowMultipleSessions,
    sAllowRegistration,
    sAllowUserSearchByEmail,
    sAllowUserSearchByUsernamePartial,
    sAllowedDomains,
    sAllowedSecondFactors,
    sAuthenticationMethods,

    sSplunkHost,
    sSplunkPort,
    sSplunkToken,
    sSplunkIndex,
    sSplunkProtocol,
    sSplunkVerify,
    sSplunkSourcetype,

    sS3LoggingBucket,
    sS3LoggingAccessKeyId,
    sS3LoggingSecretAccessKey,

    sLogstashTransport,
    sLogstashHost,
    sLogstashPort,
    sLogstashSslEnabled,
    sLogstashSslVerify,

    sAutoProlongationTokenTimeValid,
    sCCentralSecurityReportSecurityRecurrenceInterval,

    sCDisableApiKeys,
    sCServerSecrets,
    sCDisableDeleteAccount,
    sCDisableEmergencyCodes,
    sCDisableExport,
    sCDisableExportOfSharedItems,
    sCDisableFileRepositories,
    sCDisableLinkShares,
    sCDisableRecoveryCodes,
    sCEnforce2fa,
    sCDisableUnmanagedGroups,
    sCEnforceCentralSecurityReports,
    sCMinMasterPasswordComplexity,
    sCMinMasterPasswordLength,
    sCMaxOfflineCacheTimeValid,
    sCIPRestrictions,

    sCPasswordGeneratorDefaultPasswordLength,
    sCPasswordGeneratorDefaultLettersUppercase,
    sCPasswordGeneratorDefaultLettersLowercase,
    sCPasswordGeneratorDefaultNumbers,
    sCPasswordGeneratorDefaultSpecialChars,

    sDefaultTokenTimeValid,
    sDisableCentralSecurityReports,
    sDisableLastPasswords,
    sDuoIntegrationKey,
    sDuoSecretKey,
    sDuoApiHostname,
    sEnforceMatchingUsernameAndEmail,
    sFilesEnabled,
    sLoggingAudit,
    sFileserverHandlerEnabled,
    sMaxApiKeyTokenTimeValid,
    sMaxAppTokenTimeValid,
    sMaxWebclientTokenTimeValid,
    sMultifactorEnabled,
    sRecoveryVerifierTimeValid,
    sRegistrationEmailFilter,
    sLdapgateway,
    sScimConfigurations,
    sSamlConfigurations,
    sOidcConfigurations,
    variation
) {
    return new Promise((resolve, reject) => {
        return apiServer
            .updatePsonoSaasInstance(
                psonoSaasInstanceId,
                cPortalConfigJson,
                cWebclientConfigJson,
                name,
                sActivationLinkTimeValid,
                sAllowLostPassword,
                sAllowMultipleSessions,
                sAllowRegistration,
                sAllowUserSearchByEmail,
                sAllowUserSearchByUsernamePartial,
                sAllowedDomains,
                sAllowedSecondFactors,
                sAuthenticationMethods,

                sSplunkHost,
                sSplunkPort,
                sSplunkToken,
                sSplunkIndex,
                sSplunkProtocol,
                sSplunkVerify,
                sSplunkSourcetype,

                sS3LoggingBucket,
                sS3LoggingAccessKeyId,
                sS3LoggingSecretAccessKey,

                sLogstashTransport,
                sLogstashHost,
                sLogstashPort,
                sLogstashSslEnabled,
                sLogstashSslVerify,

                sAutoProlongationTokenTimeValid,
                sCCentralSecurityReportSecurityRecurrenceInterval,

                sCDisableApiKeys,
                sCServerSecrets,
                sCDisableDeleteAccount,
                sCDisableEmergencyCodes,
                sCDisableExport,
                sCDisableExportOfSharedItems,
                sCDisableFileRepositories,
                sCDisableLinkShares,
                sCDisableRecoveryCodes,
                sCEnforce2fa,
                sCDisableUnmanagedGroups,
                sCEnforceCentralSecurityReports,
                sCMinMasterPasswordComplexity,
                sCMinMasterPasswordLength,
                sCMaxOfflineCacheTimeValid,
                sCIPRestrictions,

                sCPasswordGeneratorDefaultPasswordLength,
                sCPasswordGeneratorDefaultLettersUppercase,
                sCPasswordGeneratorDefaultLettersLowercase,
                sCPasswordGeneratorDefaultNumbers,
                sCPasswordGeneratorDefaultSpecialChars,

                sDefaultTokenTimeValid,
                sDisableCentralSecurityReports,
                sDisableLastPasswords,
                sDuoIntegrationKey,
                sDuoSecretKey,
                sDuoApiHostname,
                sEnforceMatchingUsernameAndEmail,
                sFilesEnabled,
                sLoggingAudit,
                sFileserverHandlerEnabled,
                sMaxApiKeyTokenTimeValid,
                sMaxAppTokenTimeValid,
                sMaxWebclientTokenTimeValid,
                sMultifactorEnabled,
                sRecoveryVerifierTimeValid,
                sRegistrationEmailFilter,
                sLdapgateway,
                sScimConfigurations,
                sSamlConfigurations,
                sOidcConfigurations,
                variation
            )
            .then(
                (response) => {
                    return resolve(response.data);
                },
                (response) => {
                    reject(response.data);
                }
            );
    });
}

/**
 *
 * deletes a psono saas instance
 *
 * @param psonoSaasInstanceId
 *
 * @returns {Promise<unknown>}
 */
function deletePsonoSaasInstance(psonoSaasInstanceId) {
    return new Promise((resolve, reject) => {
        return apiServer.deletePsonoSaasInstance(psonoSaasInstanceId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getPsonoSaasInstances,
    getPsonoSaasInstance,
    deletePsonoSaasInstance,
    createPsonoSaasInstance,
    updatePsonoSaasInstance,
};

export default service;
