import apiServer from "./api-server";

let cachedStates;

/**
 * GET: Returns all states
 *
 * @returns {Promise<Array>}
 */
function getStates() {
    return new Promise((resolve, reject) => {
        if (cachedStates && cachedStates.length) {
            return resolve(cachedStates);
        } else {
            return apiServer.readStates().then(
                (response) => {
                    cachedStates = response.data.states;
                    return resolve(cachedStates);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_d9938d3f-6960-4816-852d-e57a66f9c591");
                    reject(response);
                }
            );
        }
    });
}

const service = {
    getStates,
};

export default service;
