/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';

import psonoSaasRegionService from "../services/psono-saas-regions";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
}));

function PsonoSaasRegionSelectField(props) {
    const { fullWidth, variant, margin, size, helperText, error, required, disabled, onChange, value, className } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [psonoSaasRegions, setPsonoSaasRegions] = useState(null);

    React.useEffect(() => {
        psonoSaasRegionService.getPsonoSaasRegions(props.project.id).then(
            (psonoSaasRegions) => {
                setPsonoSaasRegions(psonoSaasRegions);
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);


    let defaulValue = null;
    if (value && psonoSaasRegions && psonoSaasRegions.length) {
        defaulValue = psonoSaasRegions.find(function (billingAccount) {
            return billingAccount.id === value;
        });
    }

    return (
        <Autocomplete
            options={psonoSaasRegions || []}
            disabled={disabled}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => {
                return option ? option.name : "";
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {option.name}
                </li>
            )}
            onChange={(event, newValue) => {
                if (newValue === null) {
                    onChange(newValue);
                } else {
                    onChange(newValue.id);
                }
            }}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            value={defaulValue}
            renderInput={(params) => (
                <TextField
                    className={className}
                    {...params}
                    label={t("REGION")}
                    required={required}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    variant={variant}
                    helperText={helperText}
                    error={error}
                    fullWidth={fullWidth}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );

}

PsonoSaasRegionSelectField.defaultProps = {
    error: false,
};

PsonoSaasRegionSelectField.propTypes = {
    project: PropTypes.object.isRequired,
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    size: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default PsonoSaasRegionSelectField;
