import apiServer from "./api-server";

/**
 * Returns the details of a specific psono saas custom domain
 *
 * @returns {Promise<Array>}
 */
function getPsonoSaasCustomDomain(psonoSaasInstanceId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoSaasCustomDomains(psonoSaasInstanceId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_ad1b3356-19b9-496e-a174-741bd424a7cf");
                reject(response);
            }
        );
    });
}

/**
 * Creates a psono saas custom domain
 *
 * @param psonoSaasInstanceId
 * @param domain
 *
 * @returns {Promise<unknown>}
 */
function createPsonoSaasCustomDomain(psonoSaasInstanceId, domain) {
    return new Promise((resolve, reject) => {
        return apiServer.createPsonoSaasCustomDomain(psonoSaasInstanceId, domain).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Update a psono saas custom domain
 *
 * @param {string} psonoSaasInstanceId
 * @param {string} domain
 *
 * @returns {Promise<unknown>}
 */
function updatePsonoSaasCustomDomain(
    psonoSaasInstanceId,
    domain
) {
    return new Promise((resolve, reject) => {
        return apiServer
            .updatePsonoSaasCustomDomain(
                psonoSaasInstanceId,
                domain
            )
            .then(
                (response) => {
                    return resolve(response.data);
                },
                (response) => {
                    reject(response.data);
                }
            );
    });
}

/**
 * Deletes a psono saas custom domain
 *
 * @param psonoSaasInstanceId
 *
 * @returns {Promise<unknown>}
 */
function deletePsonoSaasCustomDomain(psonoSaasInstanceId) {
    return new Promise((resolve, reject) => {
        return apiServer.deletePsonoSaasCustomDomain(psonoSaasInstanceId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getPsonoSaasCustomDomain,
    deletePsonoSaasCustomDomain,
    createPsonoSaasCustomDomain,
    updatePsonoSaasCustomDomain,
};

export default service;
