import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

import PaymentMethodListTable from "./paymentMethodListTable";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import BillingAccountDropDown from "../../components/billing-account-drop-down";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginRight: theme.spacing(1),
    },
}));

function PaymentMethodList(props) {
    const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <ContentHeader>
                <Typography component="h1" variant="h5" className={classes.title}>
                    <span className={classes.titleText}>{t("PAYMENT_METHODS")}</span>
                    <BillingAccountDropDown
                        onSelect={(billingAccount) => {
                            setSelectedBillingAccount(billingAccount);
                        }}
                    />
                </Typography>
            </ContentHeader>
            <ContentBody>
                {selectedBillingAccount ? (
                    <PaymentMethodListTable billingAccount={selectedBillingAccount} />
                ) : (
                    <Alert variant="outlined" severity="warning">
                        {t("CREATE_OR_SELECT_BILLING_ACCOUNT_FIRST")}
                    </Alert>
                )}
            </ContentBody>
        </>
    );

}
export default PaymentMethodList;
