import React  from "react";
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../assets/img/logo.png";

import Copyright from "../components/copyright";
import ContactDetails from "../components/contact-details";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function LegalNoticeView(props) {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="md">
            <Helmet>
                <title>{t("LEGAL_NOTICE")}</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    {t("LEGAL_NOTICE")}
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("ADDRESS")}
                    </Typography>
                    <ContactDetails component="p" />
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("EMAIL")}
                    </Typography>
                    <Typography component="p">support@esaqa.com</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("LIMITATION_OF_LIABILITY_FOR_INTERNAL_CONTENT")}
                    </Typography>
                    <Typography component="p">{t("LIMITATION_OF_LIABILITY_FOR_INTERNAL_CONTENT_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("LIMITATION_OF_LIABILITY_FOR_EXTERNAL_LINKS")}
                    </Typography>
                    <Typography component="p">{t("LIMITATION_OF_LIABILITY_FOR_EXTERNAL_LINKS_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("COPYRIGHT")}
                    </Typography>
                    <Typography component="p">{t("COPYRIGHT_DETAILS")}</Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default LegalNoticeView;
