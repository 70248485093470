import apiServer from "./api-server";

let cachedPaymentMethods = {};

/**
 * Returns all payment methods
 *
 * @param billingAccountId
 *
 * @returns {Promise<Array>}
 */
function getPaymentMethods(billingAccountId) {
    return new Promise((resolve, reject) => {
        if (cachedPaymentMethods.hasOwnProperty(billingAccountId) && cachedPaymentMethods[billingAccountId].length) {
            return resolve(cachedPaymentMethods[billingAccountId]);
        } else {
            return apiServer.readPaymentMethods(null, billingAccountId).then(
                (response) => {
                    cachedPaymentMethods[billingAccountId] = response.data.payment_methods;
                    return resolve(response.data.payment_methods);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_7be474e1-7c27-4f68-bac7-ea5fee9702f7");
                    reject(response);
                }
            );
        }
    });
}

/**
 * Returns the details of a specific payment method
 *
 * @returns {Promise<Array>}
 */
function getPaymentMethod(paymentMethodId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPaymentMethods(paymentMethodId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_3ee62df5-c7ea-4e20-8da8-a152bc24453c");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a payment method
 *
 * @param billingAccountId
 * @param provider
 * @param setupIntent
 *
 * @returns {Promise<unknown>}
 */
function createPaymentMethod(billingAccountId, provider, setupIntent) {
    return new Promise((resolve, reject) => {
        return apiServer.createPaymentMethod(billingAccountId, provider, setupIntent).then(
            (response) => {
                delete cachedPaymentMethods[billingAccountId];
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * update a payment method
 *
 * @param paymentMethodId
 * @param name
 * @param companyName
 * @param address1
 * @param address2
 * @param zipCode
 * @param city
 * @param country
 * @param state
 * @param vatRegNo
 *
 * @returns {Promise<unknown>}
 */
function promotePaymentMethod(paymentMethodId) {
    return new Promise((resolve, reject) => {
        return apiServer.updatePaymentMethod(paymentMethodId, true).then(
            (response) => {
                cachedPaymentMethods = {};
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * deletes a payment method
 *
 * @param paymentMethodId
 *
 * @returns {Promise<unknown>}
 */
function deletePaymentMethod(paymentMethodId) {
    return new Promise((resolve, reject) => {
        return apiServer.deletePaymentMethod(paymentMethodId).then(
            (response) => {
                cachedPaymentMethods = {};
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getPaymentMethods,
    getPaymentMethod,
    deletePaymentMethod,
    createPaymentMethod,
    promotePaymentMethod,
};

export default service;
