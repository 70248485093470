import React, { Suspense } from "react";
import { HashLoader } from "react-spinners";

// initially required for stripe
import "core-js/stable";
import "regenerator-runtime/runtime";
import { I18nextProvider } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'

import { initStore } from "./services/store";
import i18n from "./i18n";
import theme from "./theme";
import SignInView from "./views/signin";
import TermsAndConditionsView from "./views/terms-and-conditions";
import LegalNoticeView from "./views/legal-notice";
import PrivacyPolicyView from "./views/privacy-policy";
import SubprocessorView from "./views/subprocessor";
import DPAView from "./views/dpa";
import TOMView from "./views/tom";
import IndexView from "./views/index";

const customHistory = createBrowserHistory();

async function initAndRenderApp(props) {
    const store = await initStore();
    let persistor = persistStore(store, null, () => {
    });

    function App(props) {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Provider store={store}>
                    <PersistGate loading={<HashLoader />} persistor={persistor}>
                        <I18nextProvider i18n={i18n}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <Router history={customHistory}>
                                        <div>
                                            <Switch>
                                                <Route path="/signin/">
                                                    <SignInView />
                                                </Route>
                                                <Route path="/terms-and-conditions/">
                                                    <TermsAndConditionsView />
                                                </Route>
                                                <Route path="/legal-notice/">
                                                    <LegalNoticeView />
                                                </Route>
                                                <Route path="/privacy-policy/">
                                                    <PrivacyPolicyView />
                                                </Route>
                                                <Route path="/subprocessor/">
                                                    <SubprocessorView />
                                                </Route>
                                                <Route path="/dpa/">
                                                    <DPAView />
                                                </Route>
                                                <Route path="/tom/">
                                                    <TOMView />
                                                </Route>
                                                <Route path="/">
                                                    <IndexView />
                                                </Route>
                                            </Switch>
                                        </div>
                                    </Router>
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </I18nextProvider>
                    </PersistGate>
                </Provider>
            </LocalizationProvider>
        );

    }

    const container = document.getElementById("app");
    render(
        <Suspense fallback="loading...">
            <App />
        </Suspense>,
        container
    );
}


initAndRenderApp();

console.log("%cDanger:", "color:red;font-size:40px;");
console.log(
    "%cDo not type or paste anything here. This feature is for developers and typing or pasting something here can compromise your account.",
    "font-size:20px;"
);
