import apiServer from "./api-server";

/**
 * Returns all billing accounts permissions
 *
 * @param billingAccountId
 *
 * @returns {Promise<Array>}
 */
function getBillingAccountPermissionUsers(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.readBillingAccountPermissionUsers(null, billingAccountId).then(
            (response) => {
                return resolve(response.data.billing_account_permission_users);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_f1492cf5-094f-47cd-b419-fc7c385f9c58");
                reject(response);
            }
        );
    });
}

/**
 * Returns the details of a specific billing account permission
 *
 * @param billingAccountPermissionUserId
 *
 * @returns {Promise<unknown>}
 */
function getBillingAccountPermissionUser(billingAccountPermissionUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.readBillingAccountPermissionUsers(billingAccountPermissionUserId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_95efeb2c-6620-4d60-9bec-9a27299edb60");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a billing account permission
 *
 * @param billingAccountId
 * @param email
 * @param billingAccountRoleId
 *
 * @returns {Promise<unknown>}
 */
function createBillingAccountPermissionUser(billingAccountId, email, billingAccountRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.createBillingAccountPermissionUser(billingAccountId, email, billingAccountRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Update a billing account permission
 *
 * @param billingAccountPermissionUserId
 * @param billingAccountRoleId
 *
 * @returns {Promise<unknown>}
 */
function updateBillingAccountPermissionUser(billingAccountPermissionUserId, billingAccountRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.updateBillingAccountPermissionUser(billingAccountPermissionUserId, billingAccountRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Deletes a billing account permission
 *
 * @param billingAccountPermissionUserId
 *
 * @returns {Promise<unknown>}
 */
function deleteBillingAccountPermissionUser(billingAccountPermissionUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteBillingAccountPermissionUser(billingAccountPermissionUserId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getBillingAccountPermissionUsers,
    getBillingAccountPermissionUser,
    deleteBillingAccountPermissionUser,
    createBillingAccountPermissionUser,
    updateBillingAccountPermissionUser,
};

export default service;
