import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import logo from "../../assets/img/logo.png";

import Copyright from "../../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function ServiceSpecificTermsForPsonoSaasView(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Service specific terms for Psono SaaS
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        1. Preamble
                    </Typography>
                    <Typography component="p">
                        These service specific terms are incorporated into the agreement under which esaqa has agreed to provide Services (as described at{" "}
                        <Link color="inherit" href="/terms-and-conditions/">
                            https://console.esaqa.com/terms-and-conditions/
                        </Link>
                        ) to Customer (the "Agreement"). These terms and condition are specific to "Psono Saas" the cloud hosted solution of Psono with a
                        Psono Enterprise Edition server.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        2. Data Location
                    </Typography>
                    <Typography component="p">
                        Customer may configure the service to store customer data in a specific region, and esaqa will store that customer data at rest only
                        in the selected region. The service do not limit the locations from which Customer or End Users may access customer data or to which
                        they may move customer data. Current supported locations are:
                    </Typography>
                    <ul>
                        <li>Belgium</li>
                    </ul>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        3. Usage
                    </Typography>
                    <Typography component="p">
                        The service is offered on a pay per use basis. Customer is only paying for the actual usage, per minute billing without upfront
                        payment. All in the Console displayed estimated price per month for this service are calculated based on an average of 730 hours per
                        month.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        4. Cancellation
                    </Typography>
                    <Typography component="p">The service may be cancelled at any time.</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        5. Refund Policy
                    </Typography>
                    <Typography component="p">
                        As Psono SaaS is billed based on usage at the end of each month, refunds are generally not applicable.
                    </Typography>
                    <Typography component="p">
                        If you believe you have been incorrectly charged for your instance, please contact our support team at support@esaqa.com
                        within 30 days of the billing date. We will review your account and usage records to address any
                        discrepancies. Refunds for disputed charges will be issued at our discretion.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        6. Features
                    </Typography>
                    <Typography component="p">
                        The service is trying to provide all the features / functionality of an on premise installation with a Psono Enterprise Edition
                        server yet may not offer some features / functionality. It is the responsibility of the Customer to check whether the offered
                        features / functionality are sufficient. Customer has no right to demand any unpresent features / functionality.
                    </Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        7. Warranty for software
                    </Typography>
                    <Typography component="p">
                        The service uses multiple software components. The service only hosts these components yet is not responsible for the software
                        itself. You are advised that, given the current state of technology, program errors cannot be ruled out with 100% certainty despite
                        the greatest diligence and care.
                    </Typography>
                    <Typography component="p">
                        The software and service is provided "as is", without warranty of any kind, express or implied, including but not limited to the
                        warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall esaqa, the authors or
                        copyright holders be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising
                        from, out of or in connection with the software or the use or other dealings in the software.
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default ServiceSpecificTermsForPsonoSaasView;
