import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    contentBody: {
        padding: theme.spacing(2),
    },
}));


function ContentBody(props) {
    const classes = useStyles();
    const {  children } = props;
    return <div className={classes.contentBody}>{children}</div>;
}
export default ContentBody;
