import React from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import StripeCardSetupForm from "./stripe-card-setup-form";
import PropTypes from "prop-types";


function InjectedStripeCardSetupForm({ clientSecret, onCreate, billingDetails }) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <StripeCardSetupForm stripe={stripe} elements={elements} clientSecret={clientSecret} onCreate={onCreate} billingDetails={billingDetails} />
            )}
        </ElementsConsumer>
    );
}
InjectedStripeCardSetupForm.propTypes = {
    onCreate: PropTypes.func.isRequired,
    clientSecret: PropTypes.string.isRequired,
    billingDetails: PropTypes.object.isRequired,
};

export default InjectedStripeCardSetupForm;
