import apiServer from "./api-server";

let cachedPricing = {};

/**
 * GET: Returns the pricing
 *
 * @returns {Promise<Array>}
 */
function getPricing(billingAccountId, projectId, product) {
    return new Promise((resolve, reject) => {
        if (cachedPricing && cachedPricing.hasOwnProperty(billingAccountId) && cachedPricing[billingAccountId].hasOwnProperty(projectId) && cachedPricing[billingAccountId][projectId].length) {
            if (product) {
                return resolve(cachedPricing[billingAccountId][projectId]["products"][product]);
            }
            return resolve(cachedPricing[billingAccountId][projectId]);
        } else {
            return apiServer.readPricing(billingAccountId, projectId).then(
                (response) => {
                    if (!cachedPricing.hasOwnProperty(billingAccountId)) {
                        cachedPricing[billingAccountId] = {}
                    }
                    cachedPricing[billingAccountId][projectId] = response.data.pricing;
                    if (product) {
                        return resolve(cachedPricing[billingAccountId][projectId]["products"][product]);
                    }
                    return resolve(cachedPricing[billingAccountId][projectId]);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_da217b94-e74b-4b37-b1b8-05fc630eaf3c");
                    reject(response);
                }
            );
        }
    });
}

const service = {
    getPricing,
};

export default service;
