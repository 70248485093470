import React, { Component, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { alpha, useTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

function StripeInput(props) {
    const {
        component: Component,
        inputRef,
        /* eslint-disable no-unused-vars */
        "aria-invalid": ariaInvalid,
        "aria-describedby": ariaDescribeBy,
        defaultValue,
        required,
        onKeyDown,
        onKeyUp,
        readOnly,
        autoComplete,
        autoFocus,
        type,
        name,
        rows,
        options,
        /* eslint-enable no-unused-vars */
        ...other
    } = props;
    const theme = useTheme();
    const [mountNode, setMountNode] = React.useState(null);

    useImperativeHandle(
        inputRef,
        () => ({
            focus: () => mountNode.focus(),
        }),
        [mountNode]
    );

    return (
        <Component
            onReady={setMountNode}
            options={{
                ...options,
                style: {
                    base: {
                        color: theme.palette.text.primary,
                        fontSize: `${theme.typography.fontSize}px`,
                        fontFamily: theme.typography.fontFamily,
                        "::placeholder": {
                            color: alpha(theme.palette.text.primary, 0.42),
                        },
                    },
                    invalid: {
                        color: theme.palette.text.primary,
                    },
                },
            }}
            {...other}
        />
    );
}

StripeInput.propTypes = {
    component: PropTypes.func.isRequired,
    inputRef: PropTypes.func,
    "aria-invalid": PropTypes.bool,
    "aria-describedby": PropTypes.string,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.bool,
    autoFocus: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    options: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function StripeTextField({ InputLabelProps, stripeElement, InputProps = {}, inputProps, ...other }) {
    const classes = useStyles();

    return (
        <TextField
            className={classes.textField}
            variant="outlined"
            autoFocus
            margin="dense" size="small"
            required
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true,
            }}
            InputProps={{
                ...InputProps,
                inputProps: {
                    ...inputProps,
                    ...InputProps.inputProps,
                    component: stripeElement,
                },
                inputComponent: StripeInput,
            }}
            {...other}
        />
    );

}

StripeTextField.propTypes = {
    ...TextField.propTypes,
    stripeElement: PropTypes.func.isRequired,
};

export default StripeTextField;
