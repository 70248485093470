import React from "react";

import { Route, Switch } from "react-router-dom";
import PsonoLicenseList from "./psonoLicenseList";
import PsonoLicenseCreate from "./psonoLicenseCreate";
import PsonoLicenseDetail from "./psonoLicenseDetail";


function Index(props) {
    return (
        <Switch>
            <Route path="/psono-license/create/">
                <PsonoLicenseCreate />
            </Route>
            <Route path="/psono-license/detail/:id/">
                <PsonoLicenseDetail />
            </Route>
            <Route path="/psono-license/">
                <PsonoLicenseList />
            </Route>
        </Switch>
    );
}

export default Index;
