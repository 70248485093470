import React from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import BillingAccountListTable from "./billingAccountListTable";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
}));

function BillingAccountList (props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <ContentHeader>
                <Typography component="h1" variant="h5" className={classes.title}>
                    {t("BILLING_ACCOUNTS")}
                </Typography>
            </ContentHeader>
            <ContentBody>
                <BillingAccountListTable />
            </ContentBody>
        </>
    );

}
export default BillingAccountList;
