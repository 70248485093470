import React from "react";

import { Route, Switch } from "react-router-dom";
import PsonoSaasInstanceList from "./psonoSaasInstanceList";
import PsonoSaasInstanceCreate from "./psonoSaasInstanceCreate";
import PsonoSaasInstanceDetail from "./psonoSaasInstanceDetail";
import PsonoSaasCustomDomain from "./psonoSaasCustomDomain";
import PsonoSaasLogs from "./psonoSaasLogs";

function Index(props) {
    return (
        <Switch>
            <Route path="/psono-saas-instance/create/">
                <PsonoSaasInstanceCreate />
            </Route>
            <Route path="/psono-saas-instance/detail/:id/custom-domain/">
                <PsonoSaasCustomDomain />
            </Route>
            <Route path="/psono-saas-instance/detail/:id/logs/">
                <PsonoSaasLogs />
            </Route>
            <Route path="/psono-saas-instance/detail/:id/">
                <PsonoSaasInstanceDetail />
            </Route>
            <Route path="/psono-saas-instance/">
                <PsonoSaasInstanceList />
            </Route>
        </Switch>
    );
}

export default Index;
