import apiServer from "./api-server";

let cachedBillingAccountRoles;

/**
 * GET: Returns the roles for billing accounts
 *
 * @returns {Promise<Array>}
 */
function getBillingAccountRoles() {
    return new Promise((resolve, reject) => {
        if (cachedBillingAccountRoles && cachedBillingAccountRoles.length) {
            return resolve(cachedBillingAccountRoles);
        } else {
            return apiServer.readBillingAccountRoles().then(
                (response) => {
                    cachedBillingAccountRoles = response.data.billing_account_roles;
                    return resolve(cachedBillingAccountRoles);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_fe0cbd05-2661-427b-bb8e-91c324b2761e");
                    reject(response);
                }
            );
        }
    });
}

const service = {
    getBillingAccountRoles,
};

export default service;
