import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";

import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

import StripeTextField from "./stripe-text-field";

const useStyles = makeStyles((theme) => ({
    alertInfo: {
        marginBottom: theme.spacing(2),
    },
    alertError: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    cardElement: {
        marginTop: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function StripeCardSetupForm(props)  {

    const classes = useStyles();
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        const { stripe, elements, clientSecret } = props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setError("")
        setDisableSubmit(true)
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: props.billingDetails,
            },
        });

        if (result.error) {
            // Display result.error.message in your UI.
            console.log(result);
            setDisableSubmit(false);
            setError(result.error.message)
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            props.onCreate(result);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Alert variant="outlined" severity="info" className={classes.alertInfo}>
                {t("ENTER_PAYMENT_DETAILS_BELOW_AND_AUTHORISE_ESAQA")}
            </Alert>
            <StripeTextField label={t("CREDIT_CARD")} stripeElement={CardElement} />
            {error && (
                <Alert variant="outlined" severity="error" className={classes.alertError}>
                    {error}
                </Alert>
            )}
            <div className={classes.buttons}>
                <Button disabled={!props.stripe || disableSubmit} variant="contained" color="primary" type="submit">
                    {t("CREATE")}
                </Button>
            </div>
        </form>
    );

}

StripeCardSetupForm.propTypes = {
    clientSecret: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
    billingDetails: PropTypes.object.isRequired,
};

export default StripeCardSetupForm;
