import React from "react";
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../../assets/img/logo.png";

import Copyright from "../../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function AcceptableUsePolicyView(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="md">
            <Helmet>
                <title>Acceptable Use Policy</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Acceptable Use Policy (AUP)
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="p">Customer agrees not to, and not to allow third parties to use the Services:</Typography>
                    <Typography component="p">
                        - to violate, or encourage the violation of, the legal rights of others (for example, this may include allowing End Users to
                        infringe or misappropriate the intellectual property rights of others in violation of the German copyright laws);
                    </Typography>
                    <Typography component="p">- to engage in, promote or encourage illegal activity;</Typography>
                    <Typography component="p">
                        - for any unlawful, invasive, infringing, defamatory or fraudulent purpose (for example, this may include phishing, creating a
                        pyramid scheme or mirroring a website);
                    </Typography>
                    <Typography component="p">
                        - to intentionally distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a destructive or deceptive
                        nature;
                    </Typography>
                    <Typography component="p">
                        - to interfere with the use of the Services, or the equipment used to provide the Services, by customers, authorized resellers, or
                        other authorized users;
                    </Typography>
                    <Typography component="p">- to disable, interfere with or circumvent any aspect of the Services;</Typography>
                    <Typography component="p">
                        - to generate, distribute, publish or facilitate unsolicited mass email, promotions, advertisings or other solicitations ("spam");
                        or
                    </Typography>
                    <Typography component="p">
                        - to use the Services, or any interfaces provided with the Services, to access any other esaqa product or service in a manner that
                        violates the terms of service of such other esaqa product or service.
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default AcceptableUsePolicyView;
