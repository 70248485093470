import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import PublishIcon from "@mui/icons-material/Publish";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import paymentMethodService from "../../services/payment-methods";
import Table from "../../container/table";
import PropTypes from "prop-types";

function PaymentMethodListTable(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [errors, setErrors] = useState([]);

    const loadPaymentLists = ()  => {
        paymentMethodService.getPaymentMethods(props.billingAccount.id).then(
            (paymentMethods) => {
                setPaymentMethods(paymentMethods.map((paymentMethod) => [
                        paymentMethod.id,
                        paymentMethod.type,
                        paymentMethod.details,
                        paymentMethod.primary,
                        paymentMethod.verified,
                        !paymentMethod.primary && paymentMethod.verified ? "PROMOTE" : "",
                    ]));
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect(() => {
        loadPaymentLists();
    }, []);

    React.useEffect(() => {
        loadPaymentLists();
    }, [props.billingAccount.id]);

    const onCreate = () => {
        history.push("/payment-methods/create/" + props.billingAccount.id + "/");
    };
    const onDelete = (rowData) => {
        paymentMethodService.deletePaymentMethod(rowData[0]).then(
            () => {
                loadPaymentLists();
            },
            (response) => {
                if (response.hasOwnProperty("payment_method_id")) {
                    setErrors(response["payment_method_id"]);
                } else {
                    console.log(response);
                }
            }
        );
    };
    const onPromote = (rowData) => {
        paymentMethodService.promotePaymentMethod(rowData[0]).then(
            () => {
                loadPaymentLists();
            },
            (response) => {
                if (response.hasOwnProperty("payment_method_id")) {
                    setErrors(response["payment_method_id"])
                } else if (response.hasOwnProperty("primary")) {
                    setErrors(response["primary"])
                } else {
                    console.log(response);
                }
            }
        );
    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        {
            name: t("TYPE"),
            options: {
                customBodyRender: (value) => {
                    if (value === "card") {
                        return t("CREDIT_CARD");
                    } else if (value === "paypal") {
                        return t("PayPal");
                    } else {
                        return t("UNKNOWN");
                    }
                },
            },
        },
        { name: t("DETAILS") },
        {
            name: t("PRIMARY"),
            options: {
                customBodyRender: (value) => {
                    return <span>{value && <CheckIcon />}</span>;
                },
            },
        },
        {
            name: t("VERIFIED"),
            options: {
                customBodyRender: (value) => {
                    return <span>{value && <CheckIcon />}</span>;
                },
            },
        },
        {
            name: t("ACTION"),
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <span>
                            {value === "PROMOTE" && (
                                <Button
                                    startIcon={<PublishIcon />}
                                    onClick={() => {
                                        onPromote(tableMeta.rowData);
                                    }}
                                >
                                    {t("PROMOTE_TO_PRIMARY")}
                                </Button>
                            )}
                        </span>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return (
        <>
            <Table data={paymentMethods} columns={columns} options={options} onCreate={onCreate} onDelete={onDelete} />
            {errors.length > 0 && (
                <Alert variant="outlined" severity="error">
                    {errors.map((error) => t(error))}
                </Alert>
            )}
        </>
    );

}

PaymentMethodListTable.propTypes = {
    billingAccount: PropTypes.object.isRequired,
};

export default PaymentMethodListTable;
