import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {initializePaddle, Paddle} from '@paddle/paddle-js';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import makeStyles from '@mui/styles/makeStyles';

import ContentBody from "../../../container/content-body";
import ContentHeader from "../../../container/content-header";
import billingAccountService from "../../../services/billing-accounts";
import paddleService from "../../../services/paddle";
import meService from "../../../services/me";
import paymentMethodService from "../../../services/payment-methods";
import projectService from "../../../services/projects";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function PaymentMethodPaddleCreate(props) {

    const history = useHistory();
    const params = useParams();
    const [paddle, setPaddle] = useState();
    const classes = useStyles();
    const {t} = useTranslation();
    const [errors, setErrors] = useState([]);

    React.useEffect(async () => {
        const paddleAccount = await paddleService.readPaddleAccount(params.billingAccountId);

        initializePaddle({
            environment: paddleAccount.environment,
            token: paddleAccount.token,
            checkout: {
                settings: {
                    displayMode: "inline",
                    frameTarget: "checkout-container",
                    frameInitialHeight: "450",
                    frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;",
                }
            },
            eventCallback: async function (result) {
                if (result.name == "checkout.completed") {
                    paymentMethodService.createPaymentMethod(params.billingAccountId, 'paddle', result.data).then(
                        async (response) => {
                            await projectService.refreshProject();
                            history.push("/payment-methods/");
                        },
                        (errors) => {
                            setErrors(errors)
                        }
                    );
                    // console.log(data);
                    // {
                    //     "name": "checkout.completed", "data": {
                    //         "id": "che_01j62bh4s0a6q2wbmvwk6eyzj3",
                    //         "transaction_id": "txn_01j62bh4nb1rpagxabdm5rm410",
                    //         "status": "completed",
                    //         "custom_data": null,
                    //         "currency_code": "EUR",
                    //         "customer": {
                    //             "id": "ctm_01j61pzvs1bmfarjpmf8r8q2p2",
                    //             "email": "saschapfeiffer1337@gmail.com",
                    //             "address": {
                    //                 "id": "add_01j61zv5enz8d7pge0m8yr67w0",
                    //                 "country_code": "BR",
                    //                 "postal_code": "12345",
                    //                 "city": "Brasilia",
                    //                 "region": "",
                    //                 "first_line": "Teststreet 47"
                    //             },
                    //             "business": {
                    //                 "id": "biz_01j61zv6sh9ywctky92vxrv0h7",
                    //                 "name": "Test Ltd3",
                    //                 "tax_identifier": ""
                    //             }
                    //         },
                    //         "items": [{
                    //             "price_id": "pri_01j61ejxdfm9fq1xmc6a8asccj",
                    //             "product": {
                    //                 "id": "pro_01j61egb4rqkzayb415srpw7t5",
                    //                 "name": "Account Setup Verification",
                    //                 "description": "This is a one-time verification to securely store your payment details. No charges will be made at this time.",
                    //                 "image_url": ""
                    //             },
                    //             "billing_cycle": {"interval": "month", "frequency": 1},
                    //             "trial_period": null,
                    //             "quantity": 1,
                    //             "totals": {
                    //                 "subtotal": 0,
                    //                 "tax": 0,
                    //                 "total": 0,
                    //                 "discount": 0,
                    //                 "balance": 0,
                    //                 "credit": 0
                    //             },
                    //             "recurring_totals": {"subtotal": 0, "tax": 0, "discount": 0, "total": 0}
                    //         }],
                    //         "totals": {"subtotal": 0, "tax": 0, "total": 0, "discount": 0, "credit": 0, "balance": 0},
                    //         "payment": {
                    //             "method_details": {
                    //                 "type": "card",
                    //                 "card": {"type": "visa", "last4": "4242", "expiry_month": 2, "expiry_year": 2042}
                    //             }
                    //         },
                    //         "settings": {"display_mode": "inline", "theme": "light"},
                    //         "recurring_totals": {
                    //             "subtotal": 0,
                    //             "tax": 0,
                    //             "discount": 0,
                    //             "total": 0,
                    //             "balance": 0,
                    //             "credit": 0
                    //         }
                    //     }
                    // }
                }
            }
        }).then(
            (paddleInstance) => {
                if (paddleInstance) {
                    setPaddle(paddleInstance);
                    paddleInstance.Checkout.open({
                        items: [{priceId: paddleAccount.price_id_eur, quantity: 1}],
                        customer: {
                            "id": paddleAccount.customer_id,
                            "address": {
                                "id": paddleAccount.address_id
                            },
                            "business": {
                                "id": paddleAccount.business_id
                            }
                        }
                    });
                }
            },
        );
    }, []);

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon/>
                </IconButton>
                {t("CREATE_PAYMENT_METHOD")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12}>
                    <div className="checkout-container"></div>
                </Grid>
            </Grid>
        </ContentBody>
    </>;

}

export default PaymentMethodPaddleCreate;
