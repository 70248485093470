import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import ProjectRoleSelectField from "../../../components/project-role-select-field";
import ContentBody from "../../../container/content-body";
import ContentHeader from "../../../container/content-header";
import projectInviteUserService from "../../../services/project-invite-users";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function ProjectUserCreate(props) {

    const history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [projectRoleId, setProjectRoleId] = useState(null);
    const [errors, setErrors] = useState({});

    const onCreate = () => {

        projectInviteUserService.createProjectInviteUser(params.projectId, email, projectRoleId).then(
            (response) => {
                history.push("/projects/permissions/" + params.projectId + "/");
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("CREATE_PROJECT_PERMISSION")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                autoFocus
                                margin="dense" size="small"
                                required
                                id="email"
                                label={t("EMAIL")}
                                name="email"
                                autoComplete="email"
                                error={errors && "email" in errors}
                                helperText={errors && "email" in errors ? t(errors["email"][0]) : ""}
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProjectRoleSelectField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="project_role_id"
                                error={errors && "project_role_id" in errors}
                                helperText={errors && "project_role_id" in errors ? t(errors["project_role_id"][0]) : ""}
                                value={projectRoleId}
                                onChange={(projectRoleId) => {
                                    setProjectRoleId(projectRoleId)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={onCreate}>
                            {t("CREATE")}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </ContentBody>
    </>;

}

export default ProjectUserCreate;
