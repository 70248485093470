import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ClipLoader} from "react-spinners";

import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';

import billingAccountService from "../../../services/billing-accounts";
import countryService from "../../../services/countries";
import PaymentMethodStripeCreate from "./index-stripe"
import PaymentMethodPaddleCreate from "./index-paddle"


const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function PaymentMethodCreate(props) {

    const params = useParams();
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isStripe, setIsStripe] = useState(null);

    React.useEffect(async () => {
        const billingAccountPromise = billingAccountService.getBillingAccount(params.billingAccountId);
        const stripeCountriesPromise = countryService.getStripeCountries();

        const [billingAccount, stripeCountries] = await Promise.all([billingAccountPromise, stripeCountriesPromise]);
        setIsStripe(stripeCountries.includes(billingAccount.country));
    }, []);

    if (isStripe === null) {
        return (<div className={classes.loader}>
            <ClipLoader color={theme.palette.primary.main}/>
        </div>)
    } else if (isStripe) {
        return <PaymentMethodStripeCreate/>
    } else {
        return <PaymentMethodPaddleCreate />
    }

}

export default PaymentMethodCreate;
