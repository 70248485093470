import yaml from "js-yaml";

/**
 * Converts YAML to JSON
 *
 * @param {string} yamlDataString
 * @returns {string}
 */
function yamlToJson(yamlDataString) {
    if (!yamlDataString) {
        return ""
    }
    yamlDataString = yamlDataString.trim();

    if (!yamlDataString) {
        return ""
    }
    return JSON.stringify(yaml.load(yamlDataString));
}

/**
 * Converts JSON to YAML
 *
 * @param {string} jsonDataString
 * @returns {string}
 */
function jsonToYaml(jsonDataString) {
    const yamlDump = yaml.dump(JSON.parse(jsonDataString))
    if (yamlDump === '{}\n') {
        return ''
    }
    if (yamlDump === '[]\n') {
        return ''
    }
    return yamlDump;
}
const service = {
    yamlToJson,
    jsonToYaml,
};

export default service;
