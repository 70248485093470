import React from "react";
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../assets/img/logo.png";

import Copyright from "../components/copyright";
import ContactDetails from "../components/contact-details";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function PrivacyPolicyView(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="md">
            <Helmet>
                <title>{t("PRIVACY_POLICY")}</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    {t("PRIVACY_POLICY")}
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="p">{t("PRIVACY_POLICY_INTRO")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("CONTACT_DETAILS")}
                    </Typography>
                    <Typography component="p">{t("CONTACT_DETAILS_DETAILS")}</Typography>
                    <ContactDetails component="p" style={{ marginTop: "20px", marginBottom: "20px" }} />
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("PERSONAL_DATA")}
                    </Typography>
                    <Typography component="p">{t("PERSONAL_DATA_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("WHY_WE_COLLECT_YOUR_DATA")}
                    </Typography>
                    <Typography component="p">{t("WE_ARE_COLLECTING_YOUR_DATA_FOR_SEVERAL_REASONS")}</Typography>
                    <ul>
                        <li>
                            <Typography>{t("TO_BETTER_UNDERSTAND_YOUR_NEEDS")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("TO_IMPROVE_OUR_SERVICES_AND_PRODUCTS")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("TO_PROTECT_OUR_SYSTEM_AGAINST_ATTACKS_AND_FRAUD")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("TO_MARKET_OUR_PRODUCTS")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("TO_FULFILL_ALL_LEGAL_REQUIREMENTS")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("TO_BE_ABLE_TO_CREATE_INVOICES")}</Typography>
                        </li>
                    </ul>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("YOUR_RIGHTS")}
                    </Typography>
                    <Typography component="p">{t("YOU_HAVE_THE_FOLLOWING_RIGHTS_ABOUT_YOUR_PERSONAL_DATA")}</Typography>
                    <ul>
                        <li>
                            <Typography>{t("RIGHT_TO_BE_INFORMED")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHT_OF_ACCESS")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHT_TO_RECTIFICATION")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHT_TO_ERASURE")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHT_TO_RESTRICT_PROCESSING")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHT_TO_DATA_PORTABILITY")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHT_TO_OBJECTION")}</Typography>
                        </li>
                        <li>
                            <Typography>{t("RIGHTS_RELATED_TO_AUTOMATED_DECISION_MAKING_INCLUDING_PROFILING")}</Typography>
                        </li>
                    </ul>
                    <Typography component="p">{t("RIGHT_TO_MAKE_A_COMPLAINT_TO_ICO_OR_ANOTHER_SUPERVISORY_AUTHORITY")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("EXTERNAL_SERVICES")}
                    </Typography>
                    <Typography component="p">{t("SERVICES_THAT_WE_USE_AND_THAT_STORE_OR_HANDLE_YOUR_DATA")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("BROWSER_STORAGE")}
                    </Typography>
                    <Typography component="p">{t("BROWSER_STORAGE_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        sendinblue
                    </Typography>
                    <Typography component="p">{t("BREVO_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        Freshdesk
                    </Typography>
                    <Typography component="p">{t("FRESHDESK_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        Cloudflare
                    </Typography>
                    <Typography component="p">{t("CLOUDFLARE_DETAILS")}</Typography>
                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        Google Cloud Platform
                    </Typography>
                    <Typography component="p">{t("GOOGLE_CLOUD_PLATFORM_DETAILS")}</Typography>

                    <Typography component="p" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {t("ESAQA_MAY_UPDATE_PRIVACY_STATEMENT_FROM_TIME_TO_TIME")}
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default PrivacyPolicyView;
