import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";

import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import CountrySelectField from "../../components/country-select-field";
import StateSelectField from "../../components/state-select-field";
import billingAccountService from "../../services/billing-accounts";
import countryService from "../../services/countries";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function BillingAccountCreate(props) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [europeanCountries, setEuropeanCountries] = useState([]);
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [vatRegNo, setVatRegNo] = useState("");
    const [confirmIsACompany, setConfirmIsACompany] = useState(false);
    const [confirmTermsAndConditions, setConfirmTermsAndConditions] = useState(false);
    const [errors, setErrors] = useState({});

    const loadEuropeanCountries = () => {
        countryService.getEuropeanCountries().then(
            (europeanCountries) => {
                countryService.getAllowedCountries().then(
                    (allowedCountries) => {
                        setAllowedCountries(allowedCountries)
                        setEuropeanCountries(europeanCountries)
                    },
                    (response) => {
                        console.log(response);
                    }
                );
            },
            (response) => {
                console.log(response);
            }
        );
    }
    React.useEffect(() => {
        loadEuropeanCountries();
    }, []);

    const onCreate = () => {
        billingAccountService.createBillingAccount(name, companyName, address1, address2, zipCode, city, country, state, vatRegNo).then(
            (response) => {
                history.push("/payment-methods/create/" + response.id + "/");
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };
    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("CREATE_BILLING_ACCOUNT")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        autoFocus
                        margin="dense" size="small"
                        required
                        id="name"
                        label={t("NAME")}
                        name="name"
                        autoComplete="name"
                        error={errors && "name" in errors}
                        helperText={errors && "name" in errors ? t(errors["name"][0]) : t("BILLING_ACCOUNT_NAME_EXPLANATION")}
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("INVOICE_ADDRESS")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="companyName"
                        label={t("COMPANY_NAME")}
                        name="companyName"
                        autoComplete="companyName"
                        error={errors && "company_name" in errors}
                        helperText={errors && "company_name" in errors ? t(errors["company_name"][0]) : ""}
                        value={companyName}
                        onChange={(event) => {
                            setCompanyName(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="address1"
                        label={t("ADDRESS1")}
                        name="address1"
                        autoComplete="address1"
                        error={errors && "address1" in errors}
                        helperText={errors && "address1" in errors ? t(errors["address1"][0]) : ""}
                        value={address1}
                        onChange={(event) => {
                            setAddress1(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        id="address2"
                        label={t("ADDRESS2")}
                        name="address2"
                        autoComplete="address2"
                        error={errors && "address2" in errors}
                        helperText={errors && "address2" in errors ? t(errors["address2"][0]) : ""}
                        value={address2}
                        onChange={(event) => {
                            setAddress2(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="zipCode"
                        label={t("ZIP_CODE")}
                        name="zipCode"
                        autoComplete="zipCode"
                        error={errors && "zip_code" in errors}
                        helperText={errors && "zip_code" in errors ? t(errors["zip_code"][0]) : ""}
                        value={zipCode}
                        onChange={(event) => {
                            setZipCode(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="city"
                        label={t("CITY")}
                        name="city"
                        autoComplete="city"
                        error={errors && "city" in errors}
                        helperText={errors && "city" in errors ? t(errors["city"][0]) : ""}
                        value={city}
                        onChange={(event) => {
                            setCity(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CountrySelectField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        error={errors && "country" in errors}
                        helperText={errors && "country" in errors ? t(errors["country"][0]) : ""}
                        value={country}
                        onChange={(value) => {
                            const newCountry = value ? value.code : ""
                            setCountry(newCountry)
                            setErrors({})
                            if (newCountry && !allowedCountries.includes(newCountry)) {
                                setErrors({"country": ['CHOSEN_COUNTRY_NOT_SUPPORTED_PLEASE_CONTACT_SUPPORT']})
                            }
                        }}
                    />
                </Grid>

                {["US", "CA", "MX", "AU"].includes(country) && (
                    <Grid item xs={12}>
                        <StateSelectField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense" size="small"
                            required
                            error={errors && "state" in errors}
                            helperText={errors && "state" in errors ? t(errors["state"][0]) : ""}
                            value={state}
                            filter={(code) => {
                                return code.startsWith(country + ":");
                            }}
                            onChange={(value) => {
                                setState(value ? value.code : "")
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("TAX_INFORMATION")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required={europeanCountries.includes(country) && country !== 'DE'}
                        id="vatRegNo"
                        label={t("VAT_REG_NO")}
                        name="vatRegNo"
                        autoComplete="vatRegNo"
                        error={errors && "vat_reg_no" in errors}
                        helperText={errors && "vat_reg_no" in errors ? t(errors["vat_reg_no"][0]) : ""}
                        value={vatRegNo}
                        onChange={(event) => {
                            setVatRegNo(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.textField}
                        control={
                            <Checkbox
                                name="confirmTermsAndConditions"
                                checked={confirmTermsAndConditions}
                                onChange={(e) => {
                                    setConfirmTermsAndConditions(e.target.checked);
                                }}
                            />
                        }
                        label={
                            <>
                                {t("CONFIRM_READ_AND_ACCEPT_TERMS_AND_CONDITIONS")}{" "}
                                <Link color="inherit" href="/terms-and-conditions/">
                                    ({t("TERMS_AND_CONDITIONS")})
                                </Link>
                            </>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.textField}
                        control={
                            <Checkbox
                                name="confirmIsACompany"
                                checked={confirmIsACompany}
                                onChange={(e) => {
                                    setConfirmIsACompany(e.target.checked);
                                }}
                            />
                        }
                        label={t("CONFIRM_IS_A_COMPANY")}
                    />
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreate}
                    disabled={!confirmTermsAndConditions || !confirmIsACompany || !country || !allowedCountries.includes(country)}
                >
                    {t("CREATE")}
                </Button>
            </div>
        </ContentBody>
    </>;

}
export default BillingAccountCreate;
