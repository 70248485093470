import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import makeStyles from '@mui/styles/makeStyles';

import Dialog from "../../container/dialog";
import BillingAccountSelectField from "../../components/billing-account-select-field";
import projectService from "../../services/projects";
import billingAccountService from "../../services/billing-accounts";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
    },
}));

function ProjectCreateDialog(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [name, setName] = useState("");
    const [billingAccounts, setBillingAccounts] = useState([]);
    const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
    const [errors, setErrors] = useState( {});

    const { onClose, open } = props;

    React.useEffect(() => {
        billingAccountService.getBillingAccounts().then(
            (billingAccounts) => {
                let selectedBillingAccount = null;
                if (billingAccounts.length > 0) {
                    selectedBillingAccount = billingAccounts[0];
                }
                setBillingAccounts(billingAccounts);
                setSelectedBillingAccount(selectedBillingAccount);
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);

    const onCreate = () => {
        const billingAccountId = selectedBillingAccount ? selectedBillingAccount.id : null;
        projectService.createProject(name, billingAccountId).then(
            (response) => {
                props.onProjectCreate(response);
            },
            (errors) => {
                setErrors(errors);
            }
        );
    };
    return (
        <Dialog maxWidth={"sm"} fullWidth={true} open={open} onClose={onClose} title={t("CREATE_PROJECT")}>
            <DialogContent>
                {billingAccounts.length > 0 ? (
                    <>
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            autoFocus
                            margin="dense" size="small"
                            required
                            id="name"
                            label={t("NAME")}
                            name="name"
                            autoComplete="name"
                            error={errors && "name" in errors}
                            helperText={errors && "name" in errors ? t(errors["name"][0]) : t("NAME_NAME_EXPLANATION")}
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                        />
                        {billingAccounts.length > 1 && (
                            <BillingAccountSelectField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                error={errors && "billing_account_id" in errors}
                                helperText={errors && "billing_account_id" in errors ? t(errors["billing_account_id"][0]) : ""}
                                value={selectedBillingAccount ? selectedBillingAccount.id : null}
                                onChange={(value) => {
                                    setSelectedBillingAccount(value ? value : null);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <Alert variant="outlined" severity="info">
                        {t("CREATE_BILLING_ACCOUNT_BEFORE_PROJECT")}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                {billingAccounts.length > 0 ? (
                    <>
                        <Button onClick={onClose}>{t("CLOSE")}</Button>
                        <Button onClick={onCreate} color="primary">
                            {t("CREATE")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={onClose}>{t("CLOSE")}</Button>
                        <Button
                            onClick={() => {
                                onClose();
                                history.push("/billing-accounts/create/");
                            }}
                            color="primary"
                        >
                            {t("CREATE_BILLING_ACCOUNT")}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );

}

ProjectCreateDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProjectCreate: PropTypes.func.isRequired,
};

export default ProjectCreateDialog;
