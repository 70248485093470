import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../assets/img/logo.png";

import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        margin: theme.spacing(1),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignInView(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    {t("SIGN_IN")}
                </Typography>
                <form className={classes.form} noValidate>
                    <Button
                        fullWidth
                        href="/oidc/authenticate/"
                        variant="contained"
                        style={{ backgroundColor: "#981868", color: "#FFFFFF" }}
                        className={classes.submit}
                    >
                        {t("SIGN_IN")}
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link href="/oidc/authenticate/" color="inherit">
                                {t("DONT_HAVE_AN_ACCOUNT_SING_UP")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default SignInView;
