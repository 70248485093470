import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        background: {
            default: "#FFFFFF",
        },
        primary: {
            main: "#981868",
        },
        secondary: {
            main: "#0b4a23",
        },
    },
    typography: {
        fontSize: 11,
    },
    components: {
        MuiTextField: {
          defaultProps: {
            margin: 'dense',
            size: 'small',
          },
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    height: "48px",
                    minHeight: "48px",
                    "@media (min-width:600px)": {
                        minHeight: "48px",
                    },
                },
            },
        },
        MUIDataTable: {
            styleOverrides: {
                paper: {
                    boxShadow: "none",
                },
            },
        },
    },
});

export default theme;
