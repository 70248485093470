import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

import invoiceService from "../../services/invoices";
import stripeService from "../../services/stripe";
import Table from "../../container/table";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    alertError: {
        marginTop: theme.spacing(2),
    },
}));

function InvoiceListTable(props) {

    const classes = useStyles();
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const loadInvoices = () => {
        setBackdropOpen(true);
        invoiceService.getInvoices(props.billingAccount.id).then(
            (invoices) => {
                const formatter = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                setBackdropOpen(false);
                setInvoices(invoices.map((invoice) => [
                    invoice.id,
                    invoice.date,
                    invoice.number,
                    formatter.format(invoice.price) + " " + invoice.currency,
                    invoice.payment_status,
                    invoice.is_downloadable,
                ]));
            },
            (response) => {
                setBackdropOpen(false);
                console.log(response);
            }
        );
    }

    // React.useEffect(() => {
    //     loadInvoices();
    // }, []);
    React.useEffect(() => {
        loadInvoices();
    }, [props.billingAccount]);

    const onDownload = (rowData) => {
        setBackdropOpen(true);
        invoiceService.downloadInvoice(rowData[0]).then(
            (pdfContent) => {
                setBackdropOpen(false);

                const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
                    const byteCharacters = atob(b64Data);
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        const slice = byteCharacters.slice(offset, offset + sliceSize);

                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }

                    const blob = new Blob(byteArrays, { type: contentType });
                    return blob;
                };
                const blob = b64toBlob(pdfContent["data"], "application/pdf");
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", rowData[2] + ".pdf");
                document.body.appendChild(link);
                link.click();
            },
            (response) => {
                setBackdropOpen(false)
                console.log(response);
                if (response.hasOwnProperty('invoice_id')) {
                    setError(t(response['invoice_id'][0]));
                }

            }
        );
    };
    const onPay = (rowData) => {
        setBackdropOpen(true)
        setError("")
        invoiceService.payInvoice(rowData[0]).then(
            (result) => {
                setBackdropOpen(false)
                const stripe_payment_id = result.stripe_payment_id;

                if (result.is_successful) {
                    loadInvoices();
                } else if (result.hasOwnProperty("provider") && result.provider === 'stripe' && result.hasOwnProperty("stripe_payment_error_code")) {
                    if (result.stripe_payment_error_code === "authentication_required") {
                        // initiate stripe.confirmCardPayment
                        var stripePromise = loadStripe(result.stripe_publishable_key);
                        stripePromise.then((stripe) => {
                            setBackdropOpen(true)
                            stripe
                                .confirmCardPayment(result.stripe_intent.client_secret, {
                                    payment_method: result.stripe_payment_method_id,
                                })
                                .then((result) => {

                                    setBackdropOpen(false)
                                    if (result.error) {
                                        // Show error to your customer
                                        console.log(result.error);
                                        setError(result.error.message)
                                    } else {
                                        if (result.paymentIntent.status === "succeeded") {
                                            // The payment is complete!
                                            stripeService.updateStripePayment(stripe_payment_id).then(
                                                (pdfContent) => {
                                                    setBackdropOpen(false)
                                                    loadInvoices();
                                                },
                                                (response) => {
                                                    setBackdropOpen(false)
                                                    console.log(response);
                                                }
                                            );
                                        } else {
                                            console.log(result);
                                            alert("UNKNOWN_ERROR");
                                        }
                                    }
                                });
                        });
                    } else {
                        // we should make this more beautiful, investigate the potential error codes and show a meaningful error message
                        alert(result.stripe_payment_error_code);
                    }
                } else if (result.hasOwnProperty("provider") && result.provider === 'paddle') {
                    console.log(result);
                    setError(t("PAYMENT_FAILED_CHANGE_PAYMENT_METHOD"))
                } else {
                    console.log(result);
                    alert("UNKNOWN_ERROR");
                }
            },
            (response) => {
                setBackdropOpen(false);
                console.log(response);
            }
        );
    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("DATE") },
        { name: t("NUMBER") },
        { name: t("AMOUNT") },
        {
            name: "",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <span>
                            {value === "not_paid" && (
                                <Button
                                    startIcon={<PaymentIcon />}
                                    onClick={() => {
                                        onPay(tableMeta.rowData);
                                    }}
                                >
                                    {t("PAY_INVOICE")}
                                </Button>
                            )}
                        </span>
                    );
                },
            },
        },
        {
            name: t("DOWNLOAD"),
            options: {
                customBodyRender: (value, tableMeta) => {
                    if (tableMeta.rowData[5]) {
                        return (
                            <IconButton
                                onClick={() => {
                                    onDownload(tableMeta.rowData);
                                }}
                                size="large">
                                <GetAppIcon />
                            </IconButton>
                        );
                    } else {
                        return null
                    }

                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={backdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Table data={invoices} columns={columns} options={options} />
            {error && (
                <Alert variant="outlined" severity="error" className={classes.alertError}>
                    {error}
                </Alert>
            )}
        </>
    );

}

InvoiceListTable.propTypes = {
    billingAccount: PropTypes.object.isRequired,
};

export default InvoiceListTable;
