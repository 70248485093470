import React, {useState} from "react";

import billingAccountService from "../../services/billing-accounts";
import Table from "../../container/table";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


function BillingAccountListTable(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [billingAccounts, setBillingAccounts] = useState([]);

    function loadBillingAccounts() {
        billingAccountService.getBillingAccounts().then(
            (billingAccounts) => {
                setBillingAccounts(billingAccounts.map((billingAccount) => [billingAccount.id, billingAccount.name, billingAccount.permissions]));
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect(() => {
        loadBillingAccounts();
    }, []);
    
    const onCreate = () => {
        history.push("/billing-accounts/create/");
    };
    const onDelete = (rowData) => {
        billingAccountService.deleteBillingAccount(rowData[0]).then(
            () => {
                loadBillingAccounts();
            },
            (response) => {
                console.log(response);
            }
        );
    };
    const onEdit = (rowData) => {
        history.push("/billing-accounts/detail/" + rowData[0] + "/");
    };
    const onUsers = (rowData) => {
        history.push("/billing-accounts/permissions/" + rowData[0] + "/");
    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("NAME") },
        {
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onEdit(tableMeta.rowData);
                            }}
                            disabled={tableMeta.rowData[2].indexOf("update") === -1}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("USERS"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onUsers(tableMeta.rowData);
                            }}
                            disabled={tableMeta.rowData[2].indexOf("grant") === -1}
                            size="large">
                            <PeopleIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("DELETE"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onDelete(tableMeta.rowData);
                            }}
                            disabled={tableMeta.rowData[2].indexOf("delete") === -1}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return <Table data={billingAccounts} columns={columns} options={options} onCreate={onCreate} />;

}

export default BillingAccountListTable;
