import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';

import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import CountrySelectField from "../../components/country-select-field";
import StateSelectField from "../../components/state-select-field";
import countryService from "../../services/countries";
import billingAccountService from "../../services/billing-accounts";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function BillingAccountDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const params = useParams();
    const [europeanCountries, setEuropeanCountries] = useState([]);
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [vatRegNo, setVatRegNo] = useState("");
    const [updateBillingAccountSuccess, setUpdateBillingAccountSuccess] = useState(false);
    const [errors, setErrors] = useState({});

    const loadEuropeanCountries = () => {
        countryService.getEuropeanCountries().then(
            (europeanCountries) => {
                countryService.getAllowedCountries().then(
                    (allowedCountries) => {
                        setAllowedCountries(allowedCountries)
                        setEuropeanCountries(europeanCountries)
                    },
                    (response) => {
                        console.log(response);
                    }
                );
            },
            (response) => {
                console.log(response);
            }
        );
    }
    const loadBillingAccountInfo = () => {
        const billingAccountId = params.id;
        billingAccountService.getBillingAccount(billingAccountId).then(
            (billingAccountDetails) => {
                setName(billingAccountDetails.name)
                setCompanyName(billingAccountDetails.company_name)
                setAddress1(billingAccountDetails.address1)
                setAddress2(billingAccountDetails.address2)
                setZipCode(billingAccountDetails.zip_code)
                setCity(billingAccountDetails.city)
                setCountry(billingAccountDetails.country)
                setState(billingAccountDetails.state)
                setVatRegNo(billingAccountDetails.vat_reg_no)
            },
            (response) => {
                console.log(response);
            }
        );
    }
    React.useEffect(() => {
        loadEuropeanCountries();
        loadBillingAccountInfo();
    }, []);

    const onEdit = () => {
        setErrors({})
        billingAccountService.updateBillingAccount(params.id, name, companyName, address1, address2, zipCode, city, state, vatRegNo).then(
            (response) => {
                setUpdateBillingAccountSuccess(true)
                setTimeout(() => {
                    setUpdateBillingAccountSuccess(false)
                }, 5000);
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("EDIT_BILLING_ACCOUNT")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        autoFocus
                        margin="dense" size="small"
                        required
                        id="name"
                        label={t("NAME")}
                        name="name"
                        autoComplete="name"
                        error={errors && "name" in errors}
                        helperText={errors && "name" in errors ? t(errors["name"][0]) : t("BILLING_ACCOUNT_NAME_EXPLANATION")}
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("INVOICE_ADDRESS")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="companyName"
                        label={t("COMPANY_NAME")}
                        name="companyName"
                        autoComplete="companyName"
                        error={errors && "company_name" in errors}
                        helperText={errors && "company_name" in errors ? t(errors["company_name"][0]) : ""}
                        value={companyName}
                        onChange={(event) => {
                            setCompanyName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="address1"
                        label={t("ADDRESS1")}
                        name="address1"
                        autoComplete="address1"
                        error={errors && "address1" in errors}
                        helperText={errors && "address1" in errors ? t(errors["address1"][0]) : ""}
                        value={address1}
                        onChange={(event) => {
                            setAddress1(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        id="address2"
                        label={t("ADDRESS2")}
                        name="address2"
                        autoComplete="address2"
                        error={errors && "address2" in errors}
                        helperText={errors && "address2" in errors ? t(errors["address2"][0]) : ""}
                        value={address2}
                        onChange={(event) => {
                            setAddress2(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="zipCode"
                        label={t("ZIP_CODE")}
                        name="zipCode"
                        autoComplete="zipCode"
                        error={errors && "zip_code" in errors}
                        helperText={errors && "zip_code" in errors ? t(errors["zip_code"][0]) : ""}
                        value={zipCode}
                        onChange={(event) => {
                            setZipCode(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        id="city"
                        label={t("CITY")}
                        name="city"
                        autoComplete="city"
                        error={errors && "city" in errors}
                        helperText={errors && "city" in errors ? t(errors["city"][0]) : ""}
                        value={city}
                        onChange={(event) => {
                            setCity(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CountrySelectField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required
                        readOnly
                        error={errors && "country" in errors}
                        helperText={errors && "country" in errors ? t(errors["country"][0]) : ""}
                        value={country}
                    />
                </Grid>

                {["US", "CA", "MX", "AU"].includes(country) && (
                    <Grid item xs={12}>
                        <StateSelectField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense" size="small"
                            required
                            error={errors && "state" in errors}
                            helperText={errors && "state" in errors ? t(errors["state"][0]) : ""}
                            value={state}
                            filter={(code) => {
                                return code.startsWith(country + ":");
                            }}
                            onChange={(value) => {
                                setState(value ? value.code : "")
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("TAX_INFORMATION")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense" size="small"
                        required={europeanCountries.includes(country) && country !== 'DE'}
                        id="vatRegNo"
                        label={t("VAT_REG_NO")}
                        name="vatRegNo"
                        autoComplete="vatRegNo"
                        error={errors && "vat_reg_no" in errors}
                        helperText={errors && "vat_reg_no" in errors ? t(errors["vat_reg_no"][0]) : ""}
                        value={vatRegNo}
                        onChange={(event) => {
                            setVatRegNo(event.target.value)
                        }}
                    />
                </Grid>
            </Grid>

            <div className={classes.buttons}>
                <Button variant="contained" color="primary" onClick={onEdit} disabled={!country || !allowedCountries.includes(country)}>
                    {t("EDIT")}
                </Button>
            </div>
            {updateBillingAccountSuccess && (
                <Alert variant="outlined" severity="success">
                    {t("SAVE_SUCCESSFUL")}
                </Alert>
            )}
        </ContentBody>
    </>;

}
export default BillingAccountDetail;
