import React from "react";

import { Route, Switch } from "react-router-dom";
import BillingAccountList from "./billingAccountList";
import BillingAccountCreate from "./billingAccountCreate";
import BillingAccountDetail from "./billingAccountDetail";
import BillingAccountPermission from "./permissions/index";

function Index(props) {
    return (
        <Switch>
            <Route path="/billing-accounts/create/">
                <BillingAccountCreate />
            </Route>
            <Route path="/billing-accounts/detail/:id/">
                <BillingAccountDetail />
            </Route>
            <Route path="/billing-accounts/permissions/:id/">
                <BillingAccountPermission />
            </Route>
            <Route path="/billing-accounts/">
                <BillingAccountList />
            </Route>
        </Switch>
    );
}
export default Index;
