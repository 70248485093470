import React from "react";
import { Route, Switch } from "react-router-dom";
import ProjectInvite from "./projectInvite";

function Index(props) {
    return (
        <Switch>
            <Route path="/invite-project/:projectInviteId/:projectInviteSecret/">
                <ProjectInvite />
            </Route>
        </Switch>
    );
}
export default Index;
