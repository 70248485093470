/**
 * Service with some device functions that do not fit anywhere else
 */
import { ClientJS } from "clientjs";

let clientJs;

activate();

function activate() {
    if (typeof window === "undefined") {
        //we are in a background script and don't have a window
        return;
    }
    clientJs = new ClientJS();
}

/**
 * Returns weather we have a windows machine
 *
 * @returns {boolean} Is this a windows device
 */
function isWindows() {
    return clientJs && clientJs.isWindows()
}

/**
 * Returns weather we have a mac
 *
 * @returns {boolean} Is this a mac device
 */
function isMac() {
    return clientJs && clientJs.isMac()
}

/**
 * Returns weather we have a linux device
 *
 * @returns {boolean} Is this a linux device
 */
function isLinux() {
    return clientJs && clientJs.isLinux()
}

/**
 * Returns weather we have an android device or not
 *
 * @returns {boolean} Is this an android device
 */
function isMobileAndroid() {
    return clientJs && clientJs.isMobileAndroid();
}

/**
 * Returns weather we have an ios device or not
 *
 * @returns {boolean} Is this an ios device
 */
function isMobileIos() {
    return clientJs && clientJs.isMobileIOS();
}

/**
 * Returns weather we have an ios device or not
 *
 * @returns {boolean} Is this an ios device
 */
function isMobile() {
    return clientJs && clientJs.isMobile();
}

/**
 * Returns weather we have a Chrome or not
 *
 * @returns {boolean} Is this an Chrome user
 */
function isChrome() {
    return clientJs && clientJs.isChrome();
}

/**
 * Returns weather we have a Chrome or not
 *
 * @returns {boolean} Is this an Chrome user
 */
function isSafari() {
    return clientJs && clientJs.isSafari();
}

/**
 * Returns weather we have a Firefox or not
 *
 * @returns {boolean} Is this an Firefox user
 */
function isFirefox() {
    return clientJs && clientJs.isFirefox();
}

/**
 * Generates the Device description out of the Vendor, OS, Version and others
 *
 * @returns {string} Returns the device's description
 */
function getDeviceDescription() {
    let description = "";
    if (clientJs && typeof clientJs.getDeviceVendor() !== "undefined") {
        description = description + clientJs.getDeviceVendor() + " ";
    }
    if (clientJs && typeof clientJs.getDevice() !== "undefined") {
        description = description + clientJs.getDevice() + " ";
    }
    if (clientJs && typeof clientJs.getOS() !== "undefined") {
        description = description + clientJs.getOS() + " ";
    }
    if (clientJs && typeof clientJs.getOSVersion() !== "undefined") {
        description = description + clientJs.getOSVersion() + " ";
    }
    if (clientJs && typeof clientJs.getBrowser() !== "undefined") {
        description = description + clientJs.getBrowser() + " ";
    }
    if (clientJs && typeof clientJs.getBrowserVersion() !== "undefined") {
        description = description + clientJs.getBrowserVersion() + " ";
    }
    return description;
}

const deviceService = {
    isWindows: isWindows,
    isLinux: isLinux,
    isMac: isMac,
    isMobileAndroid: isMobileAndroid,
    isMobileIos: isMobileIos,
    isMobile: isMobile,
    isChrome: isChrome,
    isSafari: isSafari,
    isFirefox: isFirefox,
    getDeviceDescription: getDeviceDescription,
};
export default deviceService;
