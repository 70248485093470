import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    contentHeader: {
        padding: theme.spacing(2),
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: theme.palette.divider,
    },
}));

function ContentHeader(props) {
    const classes = useStyles();
    const { children } = props;
    return <div className={classes.contentHeader}>{children}</div>;
}
export default ContentHeader;
