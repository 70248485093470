import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";

import billingAccountService from "../services/billing-accounts";

function BillingAccountDropDown(props) {

    const { t } = useTranslation();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);

    const [billingAccounts, setBillingAccounts] = useState([]);
    const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);

    React.useEffect(() => {
        billingAccountService.getBillingAccounts().then(
            (billingAccounts) => {
                setBillingAccounts(billingAccounts)
                selectBillingAccount(billingAccounts[0])
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);

    const selectBillingAccount = (billingAccount) => {
        setAnchorEl(null);
        setSelectedBillingAccount(billingAccount);
        props.onSelect(billingAccount);
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    };

    return (
        <>
            {selectedBillingAccount ? (
                <>
                    <Button aria-controls="payment-method-dropdown-menu" aria-haspopup="true" onClick={handleOpen} endIcon={<ArrowDropDownIcon />}>
                        {selectedBillingAccount.name}
                    </Button>
                    <Menu id="payment-method-dropdown-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        {billingAccounts.map((billingAccount) => (
                            <MenuItem
                                key={billingAccount.id}
                                onClick={() => {
                                    selectBillingAccount(billingAccount)
                                }}
                            >
                                {billingAccount.name}
                            </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                history.push("/billing-accounts/");
                            }}
                        >
                            {t("MANAGE_BILLING_ACCOUNTS")}
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <Button
                    onClick={() => {
                        history.push("/billing-accounts/create/");
                    }}
                    endIcon={<AddIcon />}
                >
                    {t("CREATE_BILLING_ACCOUNT")}
                </Button>
            )}
        </>
    );

}

BillingAccountDropDown.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

export default BillingAccountDropDown;
