import apiServer from "./api-server";

/**
 * Returns all projects permissions
 *
 * @param projectId
 *
 * @returns {Promise<Array>}
 */
function getProjectPermissionUsers(projectId) {
    return new Promise((resolve, reject) => {
        return apiServer.readProjectPermissionUsers(null, projectId).then(
            (response) => {
                return resolve(response.data.project_permission_users);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_f1492cf5-094f-47cd-b419-fc7c385f9c58");
                reject(response);
            }
        );
    });
}

/**
 * Returns the details of a specific project permission
 *
 * @param projectPermissionUserId
 *
 * @returns {Promise<unknown>}
 */
function getProjectPermissionUser(projectPermissionUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.readProjectPermissionUsers(projectPermissionUserId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_95efeb2c-6620-4d60-9bec-9a27299edb60");
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a project permission
 *
 * @param projectId
 * @param email
 * @param projectRoleId
 *
 * @returns {Promise<unknown>}
 */
function createProjectPermissionUser(projectId, email, projectRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.createProjectPermissionUser(projectId, email, projectRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Update a project permission
 *
 * @param projectPermissionUserId
 * @param projectRoleId
 *
 * @returns {Promise<unknown>}
 */
function updateProjectPermissionUser(projectPermissionUserId, projectRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.updateProjectPermissionUser(projectPermissionUserId, projectRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Deletes a project permission
 *
 * @param projectPermissionUserId
 *
 * @returns {Promise<unknown>}
 */
function deleteProjectPermissionUser(projectPermissionUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteProjectPermissionUser(projectPermissionUserId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getProjectPermissionUsers,
    getProjectPermissionUser,
    deleteProjectPermissionUser,
    createProjectPermissionUser,
    updateProjectPermissionUser,
};

export default service;
