import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import billingAccountPermissionUserService from "../../../services/billing-account-permission-users";
import billingAccountInviteUserService from "../../../services/billing-account-invite-users";
import Table from "../../../container/table";


function BillingAccountUserListTable(props) {

    const { t } = useTranslation();
    const history = useHistory();
    const [billingAccountPermissionUsers, setBillingAccountPermissionUsers] = useState([]);
    const [errors, setErrors] = useState([]);

    const loadBillingAccountUsers = () => {
        billingAccountPermissionUserService.getBillingAccountPermissionUsers(props.billingAccountId).then(
            (billingAccountPermissionUsers) => {
                billingAccountInviteUserService.getBillingAccountInviteUsers(props.billingAccountId).then(
                    (billingAccountInviteUsers) => {
                        setBillingAccountPermissionUsers(billingAccountPermissionUsers
                            .map((billingAccountPermissionUser) => [
                                billingAccountPermissionUser.id,
                                billingAccountPermissionUser.user_email,
                                billingAccountPermissionUser.role,
                                t("ACTIVE"), // pending state
                                billingAccountPermissionUser.own_permission,
                                'PERMISSION',
                            ])
                            .concat(
                                billingAccountInviteUsers.map((billingAccountInviteUser) => [
                                    billingAccountInviteUser.id,
                                    billingAccountInviteUser.email,
                                    billingAccountInviteUser.role,
                                    billingAccountInviteUser.expired ? t("EXPIRED") : t("PENDING"),
                                    false,
                                    'INVITE',
                                ])
                            ))
                    },
                    (response) => {
                        console.log(response);
                    }
                );
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect(() => {
        loadBillingAccountUsers();
    }, []);
    const onCreate = () => {
        history.push("/billing-accounts/permissions/create/" + props.billingAccountId + "/");
    };
    const onDeletePermission = (rowData) => {
        billingAccountPermissionUserService.deleteBillingAccountPermissionUser(rowData[0]).then(
            () => {
                loadBillingAccountUsers();
            },
            (response) => {
                console.log(response);
            }
        );
    };
    const onDeleteInvite = (rowData) => {
        billingAccountInviteUserService.deleteBillingAccountInviteUser(rowData[0]).then(
            () => {
                loadBillingAccountUsers();
            },
            (response) => {
                console.log(response);
            }
        );
    };
    const onEdit = (rowData) => {
        if (rowData[6] === 'PERMISSION') {
            history.push("/billing-accounts/permissions/detail/" + rowData[0] + "/");
        } else {
            history.push("/billing-accounts/permissions/invite/" + rowData[0] + "/");
        }

    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("EMAIL") },
        { name: t("ROLE") },
        {
            name: t("STATUS"),
            options: {
                filter: true,
                sort: false,
                empty: false,
            },
        },
        {
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onEdit(tableMeta.rowData);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("DELETE"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                if (tableMeta.rowData[6] === 'PERMISSION') {
                                    onDeletePermission(tableMeta.rowData);
                                } else {
                                    onDeleteInvite(tableMeta.rowData);
                                }
                            }}
                            disabled={tableMeta.rowData[5]}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("TYPE"), options: {
                filter: true,
                sort: false,
                empty: false,
                display: false
            }
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return (
        <>
            <Table data={billingAccountPermissionUsers} columns={columns} options={options} onCreate={onCreate} />
            {errors.length > 0 && (
                <Alert variant="outlined" severity="error">
                    {errors.map((error) => t(error))}
                </Alert>
            )}
        </>
    );

}

BillingAccountUserListTable.propTypes = {
    billingAccountId: PropTypes.string.isRequired,
};

export default BillingAccountUserListTable;
