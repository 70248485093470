import React from "react";
import { Link, useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import { withStyles } from "@mui/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Cloud from "@mui/icons-material/Cloud";
import MenuBook from "@mui/icons-material/MenuBook";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BookOutlined from "@mui/icons-material/BookOutlined";
import AccountBalance from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Receipt from "@mui/icons-material/Receipt";
import VerifiedUser from "@mui/icons-material/VerifiedUser";

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        overflow: "auto",
    },
    listItemIcon: {
        color: "#981868",
    },
}));

const StyledListItem = withStyles({
    root: {
        "&$selected": {
            backgroundColor: "#981868" + "15",
            "&:hover": {
                backgroundColor: "#981868" + "33",
            },
        },
    },
    selected: {
        color: "#981868",
    },
})(ListItem);


function Sidebar(props) {

    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    const isSelected = (value) => location.pathname === value;

    return (
        <div className={classes.drawerContainer}>
            <List>
                <StyledListItem button component={Link} to="/psono-saas-instance/" selected={isSelected("/psono-saas-instance/")}>
                    <ListItemIcon className={`${isSelected("/psono-saas-instance/") ? classes.listItemIcon : ""}`}>
                        <Cloud />
                    </ListItemIcon>
                    <ListItemText primary={t("PSONO_SAAS")} />
                </StyledListItem>
                <StyledListItem button component={Link} to="/psono-license/" selected={isSelected("/psono-license/")}>
                    <ListItemIcon className={`${isSelected("/psono-license/") ? classes.listItemIcon : ""}`}>
                        <BookOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t("PSONO_LICENSES")} />
                </StyledListItem>
                <StyledListItem button component={Link} to="/billing-accounts/" selected={isSelected("/billing-accounts/")}>
                    <ListItemIcon className={`${isSelected("/billing-accounts/") ? classes.listItemIcon : ""}`}>
                        <AccountBalance />
                    </ListItemIcon>
                    <ListItemText primary={t("BILLING_ACCOUNTS")} />
                </StyledListItem>
                <StyledListItem button component={Link} to="/payment-methods/" selected={isSelected("/payment-methods/")}>
                    <ListItemIcon className={`${isSelected("/payment-methods/") ? classes.listItemIcon : ""}`}>
                        <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("PAYMENT_METHODS")} />
                </StyledListItem>
                <StyledListItem button component={Link} to="/invoices/" selected={isSelected("/invoices/")}>
                    <ListItemIcon className={`${isSelected("/invoices/") ? classes.listItemIcon : ""}`}>
                        <Receipt />
                    </ListItemIcon>
                    <ListItemText primary={t("INVOICES")} />
                </StyledListItem>
            </List>
            <Divider />
            <List>
                <ListItem button component={Link} to="/terms-and-conditions/">
                    <ListItemIcon>
                        <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("TERMS_AND_CONDITIONS")} />
                </ListItem>
                <ListItem button component={Link} to="/legal-notice/">
                    <ListItemIcon>
                        <MenuBook />
                    </ListItemIcon>
                    <ListItemText primary={t("LEGAL_NOTICE")} />
                </ListItem>
                <ListItem button component={Link} to="/privacy-policy/">
                    <ListItemIcon>
                        <VerifiedUser />
                    </ListItemIcon>
                    <ListItemText primary={t("PRIVACY_POLICY")} />
                </ListItem>
            </List>
        </div>
    );
}

export default Sidebar;
