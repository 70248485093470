import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputAdornment from "@mui/material/InputAdornment";
import Backdrop from "@mui/material/Backdrop";

import utils from "../../utils";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import ContentCopy from "../../components/icons/ContentCopy";
import PsonoSaasCustomDomainService from "../../services/psono-saas-custom-domain";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    infoBox: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            paddingTop: "0",
        },
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    textField: {
        width: "100%"
    },
    iconButton: {
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function PsonoSaasCustomDomain(props) {

    const project = useSelector((state) => state.main.project);
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [status, setStatus] = useState("");
    const [psonoSaasCustomDomainId, setPsonoSaasCustomDomainId] = useState(null);
    const [dnsEntry1Type, setDnsEntry1Type] = useState("");
    const [dnsEntry1Name, setDnsEntry1Name] = useState("");
    const [dnsEntry1Value, setDnsEntry1Value] = useState("");
    const [dnsEntry2Type, setDnsEntry2Type] = useState("");
    const [dnsEntry2Name, setDnsEntry2Name] = useState("");
    const [dnsEntry2Value, setDnsEntry2Value] = useState("");
    const [dnsEntry3Type, setDnsEntry3Type] = useState("");
    const [dnsEntry3Name, setDnsEntry3Name] = useState("");
    const [dnsEntry3Value, setDnsEntry3Value] = useState("");
    const [activeDomain, setActiveDomain] = useState("");
    const [domain, setDomain] = useState("");
    const [errors, setErrors] = useState({});

    React.useEffect(() => {
        PsonoSaasCustomDomainService.getPsonoSaasCustomDomain(params.id).then(
            (response) => {
                setPsonoSaasCustomDomainId(response.id)
                setDomain(response.domain || "")
                setActiveDomain(response.domain || "")
                setStatus(response.status || "")
                setDnsEntry1Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_name || "")
                setDnsEntry1Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_type || "")
                setDnsEntry1Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_value || "")
                setDnsEntry2Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_name || "")
                setDnsEntry2Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_type || "")
                setDnsEntry2Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_value || "")
                setDnsEntry3Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_name || "")
                setDnsEntry3Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_type || "")
                setDnsEntry3Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_value || "")
            },
            (errors) => {
                setErrors(errors);
            }
        );
    }, []);

    const onCreate = () => {
        setBackdropOpen(true);
        PsonoSaasCustomDomainService.createPsonoSaasCustomDomain(params.id, domain).then(
            (response) => {
                setPsonoSaasCustomDomainId(response.id)
                setActiveDomain(domain || "")
                setStatus(response.status || "")
                setDnsEntry1Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_name || "")
                setDnsEntry1Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_type || "")
                setDnsEntry1Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_value || "")
                setDnsEntry2Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_name || "")
                setDnsEntry2Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_type || "")
                setDnsEntry2Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_value || "")
                setDnsEntry3Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_name || "")
                setDnsEntry3Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_type || "")
                setDnsEntry3Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_value || "")
                setBackdropOpen(false);
            },
            (errors) => {
                setErrors(errors);
                setBackdropOpen(false);
            }
        );
    };
    const onUpdate = () => {
        setBackdropOpen(true);
        PsonoSaasCustomDomainService.updatePsonoSaasCustomDomain(params.id, domain).then(
            (response) => {
                console.log(response);
                setActiveDomain(domain || "")
                setStatus(response.status || "")
                setDnsEntry1Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_name || "")
                setDnsEntry1Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_type || "")
                setDnsEntry1Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_1_value || "")
                setDnsEntry2Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_name || "")
                setDnsEntry2Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_type || "")
                setDnsEntry2Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_2_value || "")
                setDnsEntry3Name(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_name || "")
                setDnsEntry3Type(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_type || "")
                setDnsEntry3Value(response.status === 'initializing' ? t('INITIALIZING_DOT_DOT_DOT') : response.dns_entry_3_value || "")
                setBackdropOpen(false);
            },
            (errors) => {
                setErrors(errors);
                setBackdropOpen(false);
            }
        );
    };
    const onDelete = () => {
        setBackdropOpen(true);
        PsonoSaasCustomDomainService.deletePsonoSaasCustomDomain(params.id).then(
            (response) => {
                setBackdropOpen(false);
                history.push("/psono-saas-instance/detail/" + params.id + "/");
            },
            (errors) => {
                setErrors(errors);
                setBackdropOpen(false);
            }
        );
    };
    const isDomain = (domain) => {
        domain = domain.toLowerCase().trim()
        if (domain.length < 3) {
            return false
        }
        if (domain.length > 253) {
            return false
        }
        if (domain.endsWith('.') || domain.startsWith('.')) {
            return false
        }
        if (!domain.includes('.')) {
            // we don't support TLDs
            return false
        }
        if (domain.endsWith('example.com') || domain.endsWith('example.net') || domain.endsWith('example.org') || domain.endsWith('psono.app')) {
            // example.com and ... are not allowed by CF
            return false
        }
        const validChars = /^([\u0E00-\u0E7Fa-z0-9-._]+)$/g
        if (!validChars.test(domain)) {
            return false
        }
        return true;
    };

    return <>
        <Backdrop className={classes.backdrop} open={backdropOpen}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("CUSTOM_DOMAIN")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                autoFocus
                                margin="dense" size="small"
                                required
                                id="domain"
                                label={t("DOMAIN")}
                                name="domain"
                                autoComplete="domain"
                                error={errors && "domain" in errors}
                                helperText={errors && "domain" in errors ? t(errors["domain"][0]) : t("CUSTOM_DOMAIN_EXPLAINED")}
                                value={domain}
                                onChange={(event) => {
                                    let newDomain = event.target.value.trim()
                                    const autoremovablePrefixes = ['http://', 'https://']
                                    for (let i = 0; i < autoremovablePrefixes.length; i++)
                                    if (newDomain.startsWith(autoremovablePrefixes[i])) {
                                      newDomain = newDomain.slice(autoremovablePrefixes[i].length);
                                    }
                                    setDomain(newDomain);
                                }}
                            />
                        </Grid>
                    </Grid>
                    {(dnsEntry1Type || dnsEntry2Type || dnsEntry3Type) && (
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("DNS_ENTRIES")}
                            </Typography>
                        </Grid>
                    )}
                    {dnsEntry1Type && (
                        <Grid container>
                            <Grid item xs={2}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry1Type"
                                    label={t("TYPE")}
                                    name="dnsEntry1Type"
                                    autoComplete="dnsEntry1Type"
                                    value={dnsEntry1Type}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry1Name"
                                    label={t("NAME")}
                                    name="dnsEntry1Name"
                                    autoComplete="dnsEntry1Name"
                                    value={dnsEntry1Name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="copy to clipboard"
                                                    onClick={() => {utils.copyToClipboard(() => Promise.resolve(dnsEntry1Name))}}
                                                    size="large">
                                                    <ContentCopy fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry1Value"
                                    label={t("VALUE")}
                                    name="dnsEntry1Value"
                                    autoComplete="dnsEntry1Value"
                                    value={dnsEntry1Value}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="copy to clipboard"
                                                    onClick={() => {utils.copyToClipboard(() => Promise.resolve(dnsEntry1Value))}}
                                                    size="large">
                                                    <ContentCopy fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {dnsEntry2Type && (
                        <Grid container>
                            <Grid item xs={2}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry2Type"
                                    label={t("TYPE")}
                                    name="dnsEntry2Type"
                                    autoComplete="dnsEntry2Type"
                                    value={dnsEntry2Type}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry2Name"
                                    label={t("NAME")}
                                    name="dnsEntry2Name"
                                    autoComplete="dnsEntry2Name"
                                    value={dnsEntry2Name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="copy to clipboard"
                                                    onClick={() => {utils.copyToClipboard(() => Promise.resolve(dnsEntry2Name))}}
                                                    size="large">
                                                    <ContentCopy fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry2Value"
                                    label={t("VALUE")}
                                    name="dnsEntry2Value"
                                    autoComplete="dnsEntry2Value"
                                    value={dnsEntry2Value}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="copy to clipboard"
                                                    onClick={() => {utils.copyToClipboard(() => Promise.resolve(dnsEntry2Value))}}
                                                    size="large">
                                                    <ContentCopy fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {dnsEntry3Type && (
                        <Grid container>
                            <Grid item xs={2}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry3Type"
                                    label={t("TYPE")}
                                    name="dnsEntry3Type"
                                    autoComplete="dnsEntry3Type"
                                    value={dnsEntry3Type}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry3Name"
                                    label={t("NAME")}
                                    name="dnsEntry3Name"
                                    autoComplete="dnsEntry3Name"
                                    value={dnsEntry3Name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="copy to clipboard"
                                                    onClick={() => {utils.copyToClipboard(() => Promise.resolve(dnsEntry3Name))}}
                                                    size="large">
                                                    <ContentCopy fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    autoFocus
                                    margin="dense" size="small"
                                    disabled
                                    id="dnsEntry3Value"
                                    label={t("VALUE")}
                                    name="dnsEntry3Value"
                                    autoComplete="dnsEntry3Value"
                                    value={dnsEntry3Value}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="copy to clipboard"
                                                    onClick={() => {utils.copyToClipboard(() => Promise.resolve(dnsEntry3Value))}}
                                                    size="large">
                                                    <ContentCopy fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {status === 'initializing' && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="info">
                                    {t("CUSTOM_DOMAIN_STATUS_INITIALIZING_EXPLAINED")}
                                </Alert>
                            </Grid>
                        </Grid>
                    )}

                    <div className={classes.buttons}>
                        {!psonoSaasCustomDomainId && (<Button variant="contained" color="primary" onClick={onCreate} disabled={!domain || !isDomain(domain)}>
                            {t("CREATE")}
                        </Button>)}
                        {!!psonoSaasCustomDomainId && (<Button variant="contained" color="primary" onClick={onUpdate} disabled={!domain || activeDomain === domain || !isDomain(domain)}>
                            {t("SAVE")}
                        </Button>)}
                        {!!psonoSaasCustomDomainId && (<Button variant="contained" onClick={onDelete} className={classes.deleteButton}>
                            {t("DELETE")}
                        </Button>)}
                    </div>
                </Grid>
            </Grid>
        </ContentBody>
    </>;

}

export default PsonoSaasCustomDomain;
