import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import Dialog from "../../container/dialog";
import ProjectListTable from "./project-list";


function ProjectListDialog(props) {
    const { t } = useTranslation();
    const { onClose, open, onProjectSelect } = props;
    return (
        <Dialog maxWidth={"sm"} fullWidth={true} open={open} onClose={onClose} title={t("PROJECTS")}>
            <DialogContent>
                <ProjectListTable onProjectSelect={onProjectSelect} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}

ProjectListDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProjectSelect: PropTypes.func.isRequired,
};

export default ProjectListDialog;
