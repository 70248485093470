import React from "react";

import { Route, Switch } from "react-router-dom";
import PaymentMethodList from "./paymentMethodList";
import PaymentMethodCreate from "./paymentMethodCreate";

function Index(props) {
    return (
        <Switch>
            <Route path="/payment-methods/create/:billingAccountId/">
                <PaymentMethodCreate />
            </Route>
            <Route path="/payment-methods/">
                <PaymentMethodList />
            </Route>
        </Switch>
    );
}
export default Index;
