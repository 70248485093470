import React  from "react";
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../assets/img/logo.png";

import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    bold: {
        fontWeight: "bold"
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function TOMView(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="md">
            <Helmet>
                <title>{t("Technical Organizational Measures")}</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    {t("Technical Organizational Measures")}
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="p">
                        esaqa GmbH, Tiergartenstr. 13, 91247 Vorra, Germany (later on "esaqa") will implement and maintain the following technical organizational measures
                    </Typography>


                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        1. Definitions and Interpretation
                    </Typography>
                    <Typography component="p">
                        Unless otherwise defined herein, capitalized terms and expressions used in this Agreement shall have the following meaning.
                    </Typography>
                    <Typography>1.1 <span className={classes.bold}>Customer</span> means the the customer using console.esaqa.com.</Typography>
                    <Typography>1.2 <span className={classes.bold}>Customer Data</span> means data submitted, stored, sent or received via the Services by Customer or End Users. </Typography>
                    <Typography>1.3 <span className={classes.bold}>Services</span> means the services the esaqa provides on console.esaqa.com.</Typography>
                    <Typography>1.4 <span className={classes.bold}>Subprocessor</span> means a third party authorized as
                        another processor under this Data Processing Amendment to have logical access to and process
                        Customer Data in order to provide parts of the Services.</Typography>


                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        2. Data Center and Network Security
                    </Typography>
                    <Typography>2.1 Data Center</Typography>
                    <Typography>2.1.1 esaqa will only store production data in physically secure data centers.</Typography>
                    <Typography>2.1.2 used infrastructure needs to be designed to eliminate single points of failure and
                        minimize the impact of anticipated environmental risks. Dual circuits, switches, networks or
                        other necessary devices help provide this redundancy. The services are designed to allow esaqa
                        to perform certain types of preventative and corrective maintenance without interruption.
                    </Typography>
                    <Typography>2.1.3 esaqa only uses datacenters with redundant and maintainable without impact to continuous
                        operations 24 hours a day, 7 days a week. In most cases a primary as well as an alternate power
                        source, each with equal capacity is provided for critical infrastructure components in
                        datacenters. Backup power is provided by various mechanisms such as uninterruptible power
                        supplies (UPS) batteries which provide enough electricity before diesel generators take over.
                    </Typography>
                    <Typography>2.1.4 esaqa patches all operation systems on are regularly basis.</Typography>


                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        3. Access and Site Controls
                    </Typography>
                    <Typography>3.1 Site Controls</Typography>
                    <Typography>3.1.1 esaqa only uses datacenters that maintain an on-site security operation
                        responsible for all physical data centers security functions 24 hours a day, 7 days a week.
                        The on-site security operation personnel monitors closed circuit TV (CCTV) cameras and all
                        alarms systems. On-site security operation personnel perform internal and external patrols of
                        the data center regularly.</Typography>
                    <Typography>3.1.2 Access to data centers is only allowed in accordance with a formal access
                        procedure. The data centers are housed in facilities that require electronic card key access,
                        with alarms that are linked to the on-site security operation. All entrants to the data center
                        are required to identify themselves as well as show proof of identity to on-site security
                        operations. Only authorized employees, contractors and visitors are allowed entry to the data
                        centers. Only authorized employees and contractors are permitted to request electronic card key
                        access to these facilities. All other entrants requiring temporary data center access must: (i)
                        obtain approval in advance from the data center managers for the specific data center and
                        internal areas they wish to visit; (ii) sign in at on-site security operations; and (iii)
                        reference an approved data center access record identifying the individual as approved.
                    </Typography>
                    <Typography>3.1.3. esaqa’s data centers employ an electronic card key and biometric access control
                        system that is linked to a system alarm. The access control system monitors and records each
                        individual’s electronic card key and when they access perimeter doors, shipping and receiving,
                        and other critical areas. Unauthorized activity and failed access attempts are logged by the
                        access control system and investigated, as appropriate. Authorized access throughout the
                        business operations and data centers is restricted based on zones and the individual’s job
                        responsibilities. The fire doors at the data centers are alarmed. CCTV cameras are in operation
                        both inside and outside the data centers. The positioning of the cameras has been designed to
                        cover strategic areas including, among others, the perimeter, doors to the data center building,
                        and shipping/receiving. On-site security operations personnel manage the CCTV monitoring,
                        recording and control equipment. Secure cables throughout the data centers connect the CCTV
                        equipment. Cameras record on site via digital video recorders 24 hours a day, 7 days a week.
                        The surveillance records are retained for up to 30 days based on activity.
                    </Typography>

                    <Typography>3.2. Access Controls</Typography>
                    <Typography>3.2.1 Customer’s administrators and End Users must authenticate themselves via a central
                        authentication system or via a single sign on system in order to use the Services.
                    </Typography>
                    <Typography>3.2.2 esaqa’s internal data access processes and policies are designed to prevent
                        unauthorized persons and/or systems from gaining access to systems used to process personal
                        data. esaqa designs its systems to: (i) only allow authorized persons to access data they are
                        authorized to access; and (ii) ensure that personal data cannot be read, copied, altered or
                        removed without authorization during processing, use and after recording. The systems are
                        designed to detect any inappropriate access. esaqa control access to production servers, and
                        only provides access to a limited number of authorized personnel. esaqa’s authentication and
                        authorization systems utilize SSH certificates and security keys, and are designed to provide
                        esaqa with secure and flexible access mechanisms. These mechanisms are designed to grant only
                        approved access rights to site hosts, logs, data and configuration information. esaqa requires
                        the use of unique user IDs, strong passwords, two factor authentication and carefully monitored
                        access lists to minimize the potential for unauthorized account use. The granting or
                        modification of access rights is based on: the authorized personnel’s job responsibilities;
                        job duty requirements necessary to perform authorized tasks; and a need to know basis.
                        The granting or modification of access rights must also be in accordance with esaqa’s internal
                        data access policies and training. Access to systems is logged to create an audit trail for
                        accountability. Where passwords are employed for authentication (e.g., login to workstations),
                        password policies that follow at least industry standard practices are implemented. These
                        standards include restrictions on password reuse and sufficient password strength.
                    </Typography>

                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        4. Data
                    </Typography>
                    <Typography>4.1 Data storage, isolation and logging. esaqa stores data in a multi-tenant
                        environment. Subject to any Customer instructions to the contrary (for example, in the form of
                        a data location selection), esaqa replicates Customer Data between multiple geographically
                        dispersed data centers. esaqa also logically isolates Customer Data, and logically separates
                        each End User’s data from the data of other End Users, and data for an authenticated End User
                        will not be displayed to another End User (unless the former End User or an Administrator
                        allows the data to be shared). Customer will be given control over specific data sharing
                        policies. Those policies, in accordance with the functionality
                        of the Services, will enable Customer to determine the product sharing settings applicable to
                        End Users for specific purposes. Customer may choose to use logging functionality that esaqa
                        makes available via the Services.
                    </Typography>
                    <Typography>4.2. Decommissioned disks. Disks containing data may experience performance issues,
                        errors or hardware failure that lead them to be decommissioned (“Decommissioned Disk”). Every
                        Decommissioned Disk is subject to a series of data destruction processes before leaving esaqa’s
                        premises either for reuse or destruction. Decommissioned Disks are erased in a multi-step
                        process before the erased Decommissioned Disk is released to inventory for reuse and
                        redeployment. If, due to hardware failure, the Decommissioned Disk cannot be erased, it is
                        securely stored until it can be destroyed.
                    </Typography>


                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        5. Personnel Security
                    </Typography>
                    <Typography>esaqa personnel are required to conduct themselves in a manner consistent with the
                        company’s guidelines regarding confidentiality, business ethics, appropriate usage, and
                        professional standards. Personnel are required to execute a confidentiality agreement and must
                        acknowledge receipt of, and compliance with, esaqa’s confidentiality and privacy policies.
                        Personnel are provided with security training. Personnel handling Customer Data are required to
                        complete additional requirements appropriate to their role (e.g., certifications). esaqa’s
                        personnel will not process Customer Data without authorization.
                    </Typography>


                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        6. Subprocessor Security
                    </Typography>
                    <Typography>
                        Before onboarding Subprocessors, esaqa conducts an audit of the security and privacy practices
                        of Subprocessors to ensure Subprocessors provide a level of security and privacy appropriate to
                        their access to data and the scope of the services they are engaged to provide. Once esaqa has
                        assessed the risks presented by the Subprocessor, the Subprocessor is required to enter into
                        appropriate security, confidentiality and privacy contract terms.
                    </Typography>
                    <Typography style={{ marginTop: "20px", marginBottom: "20px" }}>
                        Last updated December 17th 2022
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default TOMView;
