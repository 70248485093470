import apiServer from "./api-server";

/**
 * Reads all the infos of the current logged-in user
 *
 * @returns {Promise<unknown>}
 */
function readMe() {
    return new Promise((resolve, reject) => {
        return apiServer.readMe().then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}
/**
 * Updates all the infos of the current logged-in user
 *
 * @returns {Promise<unknown>}
 */
function updateMe(language) {
    return new Promise((resolve, reject) => {
        return apiServer.updateMe(language).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const meService = {
    readMe,
    updateMe,
};

export default meService;