import apiServer from "./api-server";

/**
 *
 * Reads the paddle setup
 *
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function readPaddleAccount(billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPaddleAccount(billingAccountId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const paddleService = {
    readPaddleAccount,
};

export default paddleService;