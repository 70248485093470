import React, { useState } from "react";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import AccountTree from "@mui/icons-material/AccountTree";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';

import action from "../../redux/bound-action-creators";
import projectService from "../../services/projects";
import ProjectListDialog from "./projectListDialog";
import ProjectCreateDialog from "./projectCreateDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        color: "#ffffff",
        textTransform: "none",
    },
}));

function ProjectButton(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [projectListDialogOpen, setProjectListDialogOpen] = useState(false);
    const [projectCreateDialogOpen, setProjectCreateDialogOpen] = useState(false);
    const project = useSelector((state) => state.main.project);
    const [projects, setProjects] = useState([]);

    const loadActiveProject = () => {
        projectService.getProjects().then(
            (projects) => {
                setProjects(projects);
            },
            (response) => {
                console.log(response);
            }
        );
    };

    React.useEffect(() => {
        loadActiveProject();
    }, []);

    const onProjectCreate = (result) => {
        loadActiveProject();
        handleClose();
    };

    const handleClose = () => {
        setProjectListDialogOpen(false)
        setProjectCreateDialogOpen(false)
    };

    const onProjectSelect = (selectedProject) => {
        handleClose();
        projectService.selectProject(selectedProject);
        loadActiveProject();
        action().setProject(selectedProject)
    };

    const handleOpenList = () => {
        setProjectListDialogOpen(true)
    };

    const handleOpenCreate = () => {
        setProjectCreateDialogOpen(true)
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row" alignItems="flex-start">
                <div>
                    {project && (
                        <Button className={classes.button} startIcon={<AccountTree />} onClick={handleOpenList}>
                            {project.name}
                        </Button>
                    )}
                    {!project && !Boolean(projects.length) && (
                        <Button className={classes.button} startIcon={<AccountTree />} onClick={handleOpenCreate}>
                            {t("CREATE_PROJECT")}
                        </Button>
                    )}
                    {!project && Boolean(projects.length) && (
                        <Button className={classes.button} startIcon={<AccountTree />} onClick={handleOpenList}>
                            {t("SELECT_PROJECT")}
                        </Button>
                    )}
                </div>
            </Grid>
            {projectListDialogOpen && <ProjectListDialog open={projectListDialogOpen} onClose={handleClose} onProjectSelect={onProjectSelect} />}
            {projectCreateDialogOpen && (
                <ProjectCreateDialog open={projectCreateDialogOpen} onClose={handleClose} onProjectCreate={onProjectCreate} />
            )}
        </div>
    );

}

export default ProjectButton;
