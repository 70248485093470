import React from "react";

import { Route, Switch } from "react-router-dom";
import BillingAccountInvite from "./billingAccountInvite";

function Index(props) {
    return (
        <Switch>
            <Route path="/invite-billing-account/:billingAccountInviteId/:billingAccountInviteSecret/">
                <BillingAccountInvite />
            </Route>
        </Switch>
    );
}
export default Index;
