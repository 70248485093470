import React from "react";
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import logo from "../assets/img/logo.png";

import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    bold: {
        fontWeight: "bold"
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function DPAView(props) {
    const classes = useStyles();
    const { t } = useTranslation();

        return (
            <Container component="main" maxWidth="md">
                <Helmet>
                    <title>Data Processing Addendum</title>
                </Helmet>
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt="logo" className={classes.logo} />
                    <Typography component="h1" variant="h5">
                        Data Processing Addendum
                    </Typography>
                    <div className={classes.maincontent}>
                        <Typography component="p">This Data Processing Addendum ("DPA") amends and is incorporated into
                            the <Link color="inherit" href="/terms-and-conditions/">Terms and Conditions</Link> (the "Terms")
                            between Customer and esaqa, as applicable only if Customer or its End Users
                            provide esaqa with Personal Data subject to GDPR in connection with Customer’s receipt of the Services
                        </Typography>

                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            1. Definitions and Interpretation
                        </Typography>
                        <Typography component="p">
                            Unless otherwise defined herein, capitalized terms and expressions used in this Agreement shall have the following meaning.
                        </Typography>
                        <Typography>1.1. <span className={classes.bold}>GDPR</span> means the the General Data Protection
                            Regulations of the EU as defined in Regulations 2016/679 of the European Parliament and of the Council of 27 April 2016 and amendments.</Typography>
                        <Typography>1.2. <span className={classes.bold}>Customer</span> means the customer using console.esaqa.com.</Typography>
                        <Typography>1.3. <span className={classes.bold}>Services</span> means the services the esaqa provides on console.esaqa.com.</Typography>
                        <Typography>1.4. <span className={classes.bold}>Customer Data</span> means data submitted, stored,
                            sent or received via the Services by Customer or End Users.
                        </Typography>
                        <Typography>1.5. <span className={classes.bold}>Subprocessor</span> means a third party authorized as
                            another processor under this Data Processing Amendment to have logical access to and process
                            Customer Data in order to provide parts of the Services.</Typography>
                        <Typography>1.6. <span className={classes.bold}>Personal Data</span> means Customer Data that is
                            "personally identifiable information" according to GDPR, provided that Personal Data does
                            not include such information about Customer personnel or representatives who are business
                            contacts of esaqa, where esaqa acts as a controller of such information. </Typography>

                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            2. Duration and scope of DPA
                        </Typography>
                        <Typography>2.1. This DPA will remain in effect so long as esaqa processes Personal Data,
                            notwithstanding the expiration or termination of the Agreement (as defined below).</Typography>
                        <Typography>2.2. This DPA applies solely to processing subject to European Data Protection Laws</Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            3. Customer instructions
                        </Typography>
                        <Typography component="p">esaqa will process Personal Data only in accordance with Customer’s instructions and only for the purposes set forth in the
                            Terms and Conditions, order forms or any other such equivalent documents entered into by esaqa and the Customer (collectively,
                            the “Agreement”). This DPA and the Agreement are a complete expression of such instructions, and Customer’s additional
                            instructions will be binding on esaqa only pursuant to an amendment to the Agreement or this DPA signed by both
                            parties. Customer instructs esaqa to process Personal Data to provide the Services as contemplated by the Agreement.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            4. Security
                        </Typography>
                        <Typography>4.1. esaqa Technical Organizational Measures esaqa will implement and maintain Technical
                            Organizational Measures designed to protect Personal Data against accidental or unlawful
                            destruction, loss, alteration, unauthorized disclosure of or access to Personal Data as
                            described in here <Link color="inherit" href="/tom/">https://console.esaqa.com/tom/</Link>.
                            esaqa may update the Technical Organizational Measures from time to time, provided the
                            updated measures do not decrease the overall protection of Personal Data.
                        </Typography>
                        <Typography>4.2. Security Compliance by esaqa personnel. esaqa will ensure that its personnel who are
                            authorized to access Personal Data are subject to appropriate confidentiality obligations
                            and trained in the proper handling and safeguarding of Personal Data.
                        </Typography>
                        <Typography>4.3. esaqa security assistance. esaqa will reasonably assist Customer as necessary
                            for Customer to comply with its obligations regarding Personal Data under GDPR as applicable.
                        </Typography>
                        <Typography>
                            4.4. Information security incidents. esaqa will notify Customer within 48 hours of any
                            information security incident of which esaqa becomes aware. Such notifications will describe
                            available details of the information security incident, including mitigation efforts taken
                            by esaqa and steps esaqa recommends Customer take to address the information security
                            incident. esaqa's notification of or response to an information security incident is not an
                            acknowledgement of any fault or liability with respect to such information security incident.
                        </Typography>
                        <Typography>
                            4.5 Customer’s security responsibilities and assessment<br />
                            (i) Customer's security
                            responsibilities. Customer is solely responsible for its use of the Services, including (A)
                            making appropriate use of the Services to ensure a level of security appropriate to the risk
                            in respect of the Personal Data; (B) securing the account authentication credentials,
                            systems and devices Customer and its End Users use to access the Services; and (C) backing
                            up Personal Data.<br />
                            (ii) Customer's security assessment. Customer agrees that the Services, the Technical
                            Organizational Measures and esaqa's commitments under this DPA are adequate to meet
                            Customer's needs, including with respect to any security obligations of Customer under GDPR,
                            and provide a level of security appropriate to the risk in respect of the Personal Data.
                        </Typography>
                        <Typography>
                            4.6. Data deletion. esaqa will delete all Personal Data on esaqa's systems upon Customer's
                            request and after the end of the provision of Services, and will delete any copies thereof
                            unless continued storage of Personal Data is required by<br />
                            (i) applicable laws of the European Union or its Member States, with respect to Personal
                            Data subject to European Data Protection Laws, or<br />
                            (ii) other applicable data protection laws, with respect to all other Personal Data.
                            esaqa will comply with such instruction as soon as reasonably practicable and no later than
                            90 days after such expiration or termination. Note that, due to esaqa’s architecture,
                            certain deletions of Personal Data stored within accounts must be performed by Customer
                            and / or End Users. esaqa will assist Customer with this process as reasonably requested.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            5. Data subject rights
                        </Typography>
                        <Typography>
                            5.1. esaqa's data subject request assistance. esaqa will provide Customer with assistance
                            reasonably necessary for Customer to perform its obligations under GDPR or other applicable
                            data protection laws ("Data Subject Right Request") to fulfill requests by data subjects to exercise their rights under
                            applicable data protection laws with respect to Personal Data in esaqa's possession or control.
                        </Typography>
                        <Typography>
                            5.2. Customer's responsibility for requests. If esaqa receives a Data Subject Right Request,
                            esaqa will advise the data subject to submit the request to Customer and Customer will be
                            responsible for responding to the request and, as applicable, directing esaqa to honor such request.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            6. Subprocessors
                        </Typography>
                        <Typography>
                            6.1. Consent to subprocessor engagement. Customer specifically authorizes the engagement of
                            esaqa's affiliates as subprocessors and generally authorizes the engagement of other third
                            parties as subprocessors (“Third Party Subprocessors”) subject to the remainder of this Section 6.
                        </Typography>
                        <Typography>
                            6.2. Information about Subprocessors. Information about Subprocessors, including their
                            functions and locations, is available here <Link color="inherit" href="/subprocessor/">https://consoles.easa.com/subprocessor/</Link> (as may be updated by esaqa from time to time
                            subject to the provisions of this Section 6) or on such other website address esaqa may
                            provide to Customer from time to time (the "List of Subprocessors”).
                        </Typography>
                        <Typography>
                            6.3. Requirements for Subprocessor engagement. esaqa will only engage Third Party Subcontractors
                            where such engagement is reasonably necessary to the performance of the Services, the
                            Third Party Subprocessor is subject to obligations materially similar to those herein with
                            respect to Customer Personal Data and is prevented from using the Personal Data made
                            available to it for any purpose other than to provide the specified services to esaqa, and
                            esaqa has undertaken commercially reasonable efforts to assess the Third Party
                            Subprocessor's ability to properly protect the Personal Data it processes on esaqa's behalf.
                            esaqa will be liable for compliance by Subcontractors with the Terms and this DPA.
                        </Typography>
                        <Typography>
                            6.4. Opportunity to object to Subprocessor changes. When esaqa engages any new Third Party
                            Subprocessor after the effective date of the Agreement, esaqa will notify Customer of the
                            engagement (including the name and location of the relevant Subprocessor and the activities
                            it will perform) by updating the List of Subprocessors or by other written means. If Customer
                            objects to such engagement in a written notice to esaqa within 30 days after being informed
                            of the engagement on reasonable grounds relating to the protection of Personal Data,
                            Customer and esaqa will work in good faith to find a mutually acceptable resolution to
                            address such objection. If the parties are unable to reach a mutually acceptable resolution
                            within a reasonable timeframe, Customer may, as its sole and exclusive remedy, terminate the
                            Agreement and cancel the Services by providing written notice to esaqa and pay esaqa for all
                            amounts due and owing under the Agreement as of the date of such termination.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            7. Reviews and audits of compliance
                        </Typography>
                        <Typography>
                            Customer may audit esaqa’s compliance with its obligations under this DPA up to once per
                            year and on such other occasions as required by GDPR, including when mandated by Customer’s
                            supervisory authority. esaqa will provide Customer or Customer’s supervisory authority with
                            the information and assistance reasonably necessary to conduct the audit. esaqa may object
                            to any third-party auditor that, in esaqa’s reasonable opinion, is not independent, is a
                            competitor of esaqa, or is otherwise manifestly unsuitable. In such case, Customer will
                            appoint another auditor or conduct the audit itself. Customer must submit a proposed audit
                            plan to esaqa at least two weeks before the proposed audit date, and any third-party auditor
                            must sign a non-disclosure agreement mutually acceptable to the parties (such acceptance not
                            to be unreasonably withheld). esaqa will work with Customer to agree on a final audit plan.
                            The audit must be conducted during regular business hours, subject to the agreed final audit
                            plan and esaqa’s safety, security and other relevant policies, and may not unreasonably
                            interfere with esaqa business activities. If the controls or measures to be assessed in the
                            requested audit are addressed in an SOC 2 Type 2, ISO, NIST or similar audit report
                            performed by a qualified third-party auditor within twelve (12) months of Customer’s audit
                            request and esaqa has confirmed there have been no known material changes in the controls
                            audited since the date of such report, Customer will accept such report in lieu of auditing
                            such controls or measures. Customer will promptly notify esaqa of any non-compliance
                            discovered during an audit and provide esaqa any audit reports, unless prohibited by GDPR or
                            otherwise instructed by its supervisory authority. Customer may use the audit reports only
                            to meet Customer’s regulatory audit requirements and/or confirm compliance with the
                            requirements of this DPA. Any audits are at Customer’s sole expense.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            8. Customer responsibilities
                        </Typography>
                        <Typography>
                            Customer will (a) comply with its obligations under GDPR and applicable data protection laws,
                            (b) ensure that its instructions in Section 3 comply with GDPR, and (c) provide all notices
                            to, and obtain any necessary consents from, individuals to whom Personal Data pertains and
                            all other parties as required by applicable laws or regulations for Customer to process
                            Personal Data as contemplated by the Agreement.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            8. Miscellaneous
                        </Typography>
                        <Typography>
                            Except as expressly modified by this DPA, the provisions of the Terms remain in full force
                            and effect. In the event of any conflict or inconsistency between this DPA and the Terms,
                            this DPA will govern. Notwithstanding anything in the Agreement or any order form, the
                            parties acknowledge that esaqa's access to Personal Data is not part of the consideration
                            exchanged by the parties in respect of the Agreement. Any notices required or permitted to
                            be given by esaqa to Customer under this DPA may be given (a) in accordance with any notice
                            clause of the Terms; (b) to esaqa’s primary points of contact with Customer; or (c) to any
                            email provided by Customer for the purpose of providing it with Services related
                            communications or alerts. Customer is solely responsible for ensuring that such email
                            addresses are valid.
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            9. Standard Contractual clauses
                        </Typography>
                        <Typography>
                            As set out in the European Commission's Implementation Decision 2021/914 of June 4th 2021
                            the so called Standard Contractual Clauses (or SCC) are mandatory porivisions and attached
                            as EXHIBIT 1 (Module two - Transfer controller to processor).
                        </Typography>


                        <Typography component="h2" variant="h5" style={{ marginTop: "100px", marginBottom: "20px" }}>
                            EXHIBIT 1 -  STANDARD CONTRACTUAL CLAUSES
                        </Typography>

                        <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            SECTION I
                        </Typography>
                        <p>
                            <span>Clause 1</span>
                        </p>
                        <p>
                            <span>Purpose and scope</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The purpose of these standard contractual clauses
                                        is to ensure compliance with the requirements of Regulation (EU)
                                        2016/679 of the European Parliament and of the Council of 27&nbsp;April
                                        2016 on the protection of natural persons with regard to the processing
                                        of personal data and on the free movement of such data (General Data
                                        Protection Regulation)&nbsp;<a href="#ntr1-L_2021199EN.01003701-E0001">(<span
                                            className="oj-super oj-note-tag">1</span>)</a> for the transfer of
                                        personal data to a third country.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%"/>
                                    <col width="96%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties:</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%"/>
                                                <col width="96%"/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(i)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the natural or legal person(s), public
                                                    authority/ies, agency/ies or other body/ies (hereinafter
                                                    ‘entity/ies’) transferring the personal data, as listed in
                                                    Annex I.A (hereinafter each ‘data exporter’), and</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%"/>
                                                <col width="96%"/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(ii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the entity/ies in a third country
                                                    receiving the personal data from the data exporter, directly
                                                    or indirectly via another entity also Party to these
                                                    Clauses, as listed in Annex I.A (hereinafter each ‘data
                                                    importer’)</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p className="oj-normal">have agreed to these standard contractual clauses
                                        (hereinafter: ‘Clauses’).</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%"/>
                                    <col width="96%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">These Clauses apply with respect to the transfer of
                                        personal data as specified in Annex I.B.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%"/>
                                    <col width="96%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Appendix to these Clauses containing the
                                        Annexes referred to therein forms an integral part of these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e129-37-1" className="oj-ti-grseq-1">
                            <span>Clause 2</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-004-0004" className="oj-ti-grseq-1">
                            <span>Effect and invariability of the Clauses</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%"/>
                                    <col width="96%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">These Clauses set out appropriate safeguards,
                                        including enforceable data subject rights and effective legal remedies,
                                        pursuant to Article&nbsp;46(1) and Article&nbsp;46(2)(c) of Regulation
                                        (EU) 2016/679 and, with respect to data transfers from controllers to
                                        processors and/or processors to processors, standard contractual clauses
                                        pursuant to Article&nbsp;28(7) of Regulation (EU) 2016/679, provided
                                        they are not modified, except to select the appropriate Module(s) or to
                                        add or update information in the Appendix. This does not prevent the
                                        Parties from including the standard contractual clauses laid down in
                                        these Clauses in a wider contract and/or to add other clauses or
                                        additional safeguards, provided that they do not contradict, directly or
                                        indirectly, these Clauses or prejudice the fundamental rights or
                                        freedoms of data subjects.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">These Clauses are without prejudice to obligations
                                        to which the data exporter is subject by virtue of Regulation (EU)
                                        2016/679.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e152-37-1" className="oj-ti-grseq-1">
                            <span>Clause 3</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-005-0005" className="oj-ti-grseq-1">
                            <span>Third-party beneficiaries</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Data subjects may invoke and enforce these Clauses,
                                        as third-party beneficiaries, against the data exporter and/or data
                                        importer, with the following exceptions:</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(i)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 1, Clause 2, Clause 3, Clause 6,
                                                    Clause 7;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(ii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 8 – Module One: Clause 8.5 (e)
                                                    and Clause 8.9(b); Module Two: Clause 8.1(b), 8.9(a), (c),
                                                    (d) and (e); Module Three: Clause 8.1(a), (c) and (d) and
                                                    Clause 8.9(a), (c), (d), (e), (f) and (g); Module Four:
                                                    Clause 8.1 (b) and Clause 8.3(b);</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(iii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 9 – Module Two: Clause 9(a),
                                                    (c), (d) and (e); Module Three: Clause 9(a), (c), (d) and
                                                    (e);</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(iv)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 12 – Module One: Clause 12(a)
                                                    and (d); Modules Two and Three: Clause 12(a), (d) and
                                                    (f);</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(v)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 13;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(vi)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 15.1(c), (d) and (e);</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(vii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 16(e);</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(viii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">Clause 18 – Modules One, Two and Three:
                                                    Clause 18(a) and (b); Module Four: Clause 18.</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Paragraph (a) is without prejudice to rights of
                                        data subjects under Regulation (EU) 2016/679.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e225-37-1" className="oj-ti-grseq-1">
                            <span>Clause 4</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-006-0006" className="oj-ti-grseq-1">
                            <span>Interpretation</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Where these Clauses use terms that are defined in
                                        Regulation (EU) 2016/679, those terms shall have the same meaning as in
                                        that Regulation.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">These Clauses shall be read and interpreted in the
                                        light of the provisions of Regulation (EU) 2016/679.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">These Clauses shall not be interpreted in a way
                                        that conflicts with rights and obligations provided for in Regulation
                                        (EU) 2016/679.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e254-37-1" className="oj-ti-grseq-1">
                            <span>Clause 5</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-007-0007" className="oj-ti-grseq-1">
                            <span>Hierarchy</span>
                        </p>
                        <p className="oj-normal">In the event of a contradiction between these Clauses and the
                            provisions of related agreements between the Parties, existing at the time these
                            Clauses are agreed or entered into thereafter, these Clauses shall prevail.</p>
                        <p id="d1e266-37-1" className="oj-ti-grseq-1">
                            <span>Clause 6</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-008-0008" className="oj-ti-grseq-1">
                            <span>Description of the transfer(s)</span>
                        </p>
                        <p className="oj-normal">The details of the transfer(s), and in particular the
                            categories of personal data that are transferred and the purpose(s) for which they
                            are transferred, are specified in Annex I.B.</p>
                        <p id="d1e278-37-1" className="oj-ti-grseq-1">
                            <span>Clause 7 – Optional</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-009-0009" className="oj-ti-grseq-1">
                            <span>Docking clause</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">An entity that is not a Party to these Clauses may,
                                        with the agreement of the Parties, accede to these Clauses at any time,
                                        either as a data exporter or as a data importer, by completing the
                                        Appendix and signing Annex I.A.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Once it has completed the Appendix and signed Annex
                                        I.A, the acceding entity shall become a Party to these Clauses and have
                                        the rights and obligations of a data exporter or data importer in
                                        accordance with its designation in Annex I.A.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The acceding entity shall have no rights or
                                        obligations arising under these Clauses from the period prior to
                                        becoming a Party.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            SECTION II – OBLIGATIONS OF THE PARTIES
                        </Typography>
                        <p id="d1e312-37-1" className="oj-ti-grseq-1">
                            <span>Clause 8</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-011-0011" className="oj-ti-grseq-1">
                            <span>Data protection safeguards</span>
                        </p>
                        <p className="oj-normal">The data exporter warrants that it has used reasonable efforts
                            to determine that the data importer is able, through the implementation of
                            appropriate technical and organisational measures, to satisfy its obligations under
                            these Clauses.</p>

                        <p className="oj-ti-grseq-1" id="d1e631-37-1">8.1&nbsp;&nbsp;&nbsp;<span
                           >Instructions</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall process the personal data
                                        only on documented instructions from the data exporter. The data
                                        exporter may give such instructions throughout the duration of the
                                        contract.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall immediately inform the data
                                        exporter if it is unable to follow those instructions.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className="oj-ti-grseq-1" id="d1e653-37-1">8.2&nbsp;&nbsp;&nbsp;<span
                           >Purpose limitation</span>
                        </p>
                        <p className="oj-normal">The data importer shall process the personal data only for the
                            specific purpose(s) of the transfer, as set out in Annex I.B, unless on further
                            instructions from the data exporter.</p>
                        <p className="oj-ti-grseq-1" id="d1e664-37-1">8.3&nbsp;&nbsp;&nbsp;<span
                           >Transparency</span>
                        </p>
                        <p className="oj-normal">On request, the data exporter shall make a copy of these
                            Clauses, including the Appendix as completed by the Parties, available to the data
                            subject free of charge. To the extent necessary to protect business secrets or other
                            confidential information, including the measures described in Annex II and personal
                            data, the data exporter may redact part of the text of the Appendix to these Clauses
                            prior to sharing a copy, but shall provide a meaningful summary where the data
                            subject would otherwise not be able to understand the its content or exercise
                            his/her rights. On request, the Parties shall provide the data subject with the
                            reasons for the redactions, to the extent possible without revealing the redacted
                            information. This Clause is without prejudice to the obligations of the data
                            exporter under Articles 13 and&nbsp;14 of Regulation (EU) 2016/679.</p>
                        <p className="oj-ti-grseq-1" id="d1e675-37-1">8.4&nbsp;&nbsp;&nbsp;<span
                           >Accuracy</span>
                        </p>
                        <p className="oj-normal">If the data importer becomes aware that the personal data it
                            has received is inaccurate, or has become outdated, it shall inform the data
                            exporter without undue delay. In this case, the data importer shall cooperate with
                            the data exporter to erase or rectify the data.</p>
                        <p className="oj-ti-grseq-1" id="d1e686-37-1">8.5&nbsp;&nbsp;&nbsp;<span
                           >Duration of processing and erasure or return of data</span>
                        </p>
                        <p className="oj-normal">Processing by the data importer shall only take place for the
                            duration specified in Annex I.B. After the end of the provision of the processing
                            services, the data importer shall, at the choice of the data exporter, delete all
                            personal data processed on behalf of the data exporter and certify to the data
                            exporter that it has done so, or return to the data exporter all personal data
                            processed on its behalf and delete existing copies. Until the data is deleted or
                            returned, the data importer shall continue to ensure compliance with these Clauses.
                            In case of local laws applicable to the data importer that prohibit return or
                            deletion of the personal data, the data importer warrants that it will continue to
                            ensure compliance with these Clauses and will only process it to the extent and for
                            as long as required under that local law. This is without prejudice to Clause 14, in
                            particular the requirement for the data importer under Clause 14(e) to notify the
                            data exporter throughout the duration of the contract if it has reason to believe
                            that it is or has become subject to laws or practices not in line with the
                            requirements under Clause 14(a).</p>
                        <p className="oj-ti-grseq-1" id="d1e697-37-1">8.6&nbsp;&nbsp;&nbsp;<span
                           >Security of processing</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer and, during transmission, also
                                        the data exporter shall implement appropriate technical and
                                        organisational measures to ensure the security of the data, including
                                        protection against a breach of security leading to accidental or
                                        unlawful destruction, loss, alteration, unauthorised disclosure or
                                        access to that data (hereinafter ‘personal data breach’). In assessing
                                        the appropriate level of security, the Parties shall take due account of
                                        the state of the art, the costs of implementation, the nature, scope,
                                        context and purpose(s) of processing and the risks involved in the
                                        processing for the data subjects. The Parties shall in particular
                                        consider having recourse to encryption or pseudonymisation, including
                                        during transmission, where the purpose of processing can be fulfilled in
                                        that manner. In case of pseudonymisation, the additional information for
                                        attributing the personal data to a specific data subject shall, where
                                        possible, remain under the exclusive control of the data exporter. In
                                        complying with its obligations under this paragraph, the data importer
                                        shall at least implement the technical and organisational measures
                                        specified in Annex II. The data importer shall carry out regular checks
                                        to ensure that these measures continue to provide an appropriate level
                                        of security.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall grant access to the
                                        personal data to members of its personnel only to the extent strictly
                                        necessary for the implementation, management and monitoring of the
                                        contract. It shall ensure that persons authorised to process the
                                        personal data have committed themselves to confidentiality or are under
                                        an appropriate statutory obligation of confidentiality.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">In the event of a personal data breach concerning
                                        personal data processed by the data importer under these Clauses, the
                                        data importer shall take appropriate measures to address the breach,
                                        including measures to mitigate its adverse effects. The data importer
                                        shall also notify the data exporter without undue delay after having
                                        become aware of the breach. Such notification shall contain the details
                                        of a contact point where more information can be obtained, a description
                                        of the nature of the breach (including, where possible, categories and
                                        approximate number of data subjects and personal data records
                                        concerned), its likely consequences and the measures taken or proposed
                                        to address the breach including, where appropriate, measures to mitigate
                                        its possible adverse effects. Where, and in so far as, it is not
                                        possible to provide all information at the same time, the initial
                                        notification shall contain the information then available and further
                                        information shall, as it becomes available, subsequently be provided
                                        without undue delay.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall cooperate with and assist
                                        the data exporter to enable the data exporter to comply with its
                                        obligations under Regulation (EU) 2016/679, in particular to notify the
                                        competent supervisory authority and the affected data subjects, taking
                                        into account the nature of processing and the information available to
                                        the data importer.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className="oj-ti-grseq-1" id="d1e735-37-1">8.7&nbsp;&nbsp;&nbsp;<span
                           >Sensitive data</span>
                        </p>
                        <p className="oj-normal">Where the transfer involves personal data revealing racial or
                            ethnic origin, political opinions, religious or philosophical beliefs, or trade
                            union membership, genetic data, or biometric data for the purpose of uniquely
                            identifying a natural person, data concerning health or a person’s sex life or
                            sexual orientation, or data relating to criminal convictions and offences
                            (hereinafter ‘sensitive data’), the data importer shall apply the specific
                            restrictions and/or additional safeguards described in Annex I.B.</p>
                        <p className="oj-ti-grseq-1" id="d1e750-37-1">8.8&nbsp;&nbsp;&nbsp;<span
                           >Onward transfers</span>
                        </p>
                        <p className="oj-normal">The data importer shall only disclose the personal data to a
                            third party on documented instructions from the data exporter. In addition, the data
                            may only be disclosed to a third party located outside the European Union&nbsp;<a
                                id="ntc4-L_2021199EN.01003701-E0004"
                                href="#ntr4-L_2021199EN.01003701-E0004">(<span
                                className="oj-super oj-note-tag">4</span>)</a> (in the same country as the data
                            importer or in another third country, hereinafter ‘onward transfer’) if the third
                            party is or agrees to be bound by these Clauses, under the appropriate Module, or
                            if:</p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(i)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">the onward transfer is to a country benefitting
                                        from an adequacy decision pursuant to Article&nbsp;45 of Regulation (EU)
                                        2016/679 that covers the onward transfer;</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(ii)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">the third party otherwise ensures appropriate
                                        safeguards pursuant to Articles 46 or 47 Regulation of (EU) 2016/679
                                        with respect to the processing in question;</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(iii)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">the onward transfer is necessary for the
                                        establishment, exercise or defence of legal claims in the context of
                                        specific administrative, regulatory or judicial proceedings; or</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(iv)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">the onward transfer is necessary in order to
                                        protect the vital interests of the data subject or of another natural
                                        person.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className="oj-normal">Any onward transfer is subject to compliance by the data
                            importer with all the other safeguards under these Clauses, in particular purpose
                            limitation.</p>
                        <p className="oj-ti-grseq-1" id="d1e796-37-1">8.9&nbsp;&nbsp;&nbsp;<span
                           >Documentation and compliance</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall promptly and adequately
                                        deal with enquiries from the data exporter that relate to the processing
                                        under these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties shall be able to demonstrate compliance
                                        with these Clauses. In particular, the data importer shall keep
                                        appropriate documentation on the processing activities carried out on
                                        behalf of the data exporter.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall make available to the data
                                        exporter all information necessary to demonstrate compliance with the
                                        obligations set out in these Clauses and at the data exporter’s request,
                                        allow for and contribute to audits of the processing activities covered
                                        by these Clauses, at reasonable intervals or if there are indications of
                                        non-compliance. In deciding on a review or audit, the data exporter may
                                        take into account relevant certifications held by the data importer.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data exporter may choose to conduct the audit
                                        by itself or mandate an independent auditor. Audits may include
                                        inspections at the premises or physical facilities of the data importer
                                        and shall, where appropriate, be carried out with reasonable notice.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties shall make the information referred to
                                        in paragraphs (b) and (c), including the results of any audits,
                                        available to the competent supervisory authority on request.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <p id="d1e1173-37-1" className="oj-ti-grseq-1">
                            <span>Clause 9</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-046-0046" className="oj-ti-grseq-1">
                            <span>Use of sub-processors</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data
                                        importer has the data exporter’s general authorisation for the
                                        engagement of sub-processor(s) from an agreed list. The data importer
                                        shall specifically inform the data exporter in writing of any intended
                                        changes to that list through the addition or replacement of
                                        sub-processors at least 14 days in advance,
                                        thereby giving the data exporter sufficient time to be able to object to
                                        such changes prior to the engagement of the sub-processor(s). The data
                                        importer shall provide the data exporter with the information necessary
                                        to enable the data exporter to exercise its right to object.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Where the data importer engages a sub-processor to
                                        carry out specific processing activities (on behalf of the data
                                        exporter), it shall do so by way of a written contract that provides
                                        for, in substance, the same data protection obligations as those binding
                                        the data importer under these Clauses, including in terms of third-party
                                        beneficiary rights for data subjects.&nbsp;<a
                                            id="ntc8-L_2021199EN.01003701-E0008"
                                            href="#ntr8-L_2021199EN.01003701-E0008">(<span
                                            className="oj-super oj-note-tag">8</span>)</a> The Parties agree
                                        that, by complying with this Clause, the data importer fulfils its
                                        obligations under Clause 8.8. The data importer shall ensure that the
                                        sub-processor complies with the obligations to which the data importer
                                        is subject pursuant to these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall provide, at the data
                                        exporter’s request, a copy of such a sub-processor agreement and any
                                        subsequent amendments to the data exporter. To the extent necessary to
                                        protect business secrets or other confidential information, including
                                        personal data, the data importer may redact the text of the agreement
                                        prior to sharing a copy.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall remain fully responsible to
                                        the data exporter for the performance of the sub-processor’s obligations
                                        under its contract with the data importer. The data importer shall
                                        notify the data exporter of any failure by the sub-processor to fulfil
                                        its obligations under that contract.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall agree a third-party
                                        beneficiary clause with the sub-processor whereby – in the event the
                                        data importer has factually disappeared, ceased to exist in law or has
                                        become insolvent – the data exporter shall have the right to terminate
                                        the sub-processor contract and to instruct the sub-processor to erase or
                                        return the personal data.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                        <p id="d1e1281-37-1" className="oj-ti-grseq-1">
                            <span>Clause 10</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-049-0049" className="oj-ti-grseq-1">
                            <span>Data subject rights</span>
                        </p>

                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall promptly notify the data
                                        exporter of any request it has received from a data subject. It shall
                                        not respond to that request itself unless it has been authorised to do
                                        so by the data exporter.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall assist the data exporter in
                                        fulfilling its obligations to respond to data subjects’ requests for the
                                        exercise of their rights under Regulation (EU) 2016/679. In this regard,
                                        the Parties shall set out in Annex II the appropriate technical and
                                        organisational measures, taking into account the nature of the
                                        processing, by which the assistance shall be provided, as well as the
                                        scope and the extent of the assistance required.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">In fulfilling its obligations under paragraphs (a)
                                        and (b), the data importer shall comply with the instructions from the
                                        data exporter.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>




                        <p id="d1e1440-37-1" className="oj-ti-grseq-1">
                            <span>Clause 11</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-054-0054" className="oj-ti-grseq-1">
                            <span>Redress</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall inform data subjects in a
                                        transparent and easily accessible format, through individual notice or
                                        on its website, of a contact point authorised to handle complaints. It
                                        shall deal promptly with any complaints it receives from a data
                                        subject.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">In case of a dispute between a data subject and one
                                        of the Parties as regards compliance with these Clauses, that Party
                                        shall use its best efforts to resolve the issue amicably in a timely
                                        fashion. The Parties shall keep each other informed about such disputes
                                        and, where appropriate, cooperate in resolving them.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Where the data subject invokes a third-party
                                        beneficiary right pursuant to Clause 3, the data importer shall accept
                                        the decision of the data subject to:</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(i)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">lodge a complaint with the supervisory
                                                    authority in the Member State of his/her habitual residence
                                                    or place of work, or the competent supervisory authority
                                                    pursuant to Clause 13;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(ii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">refer the dispute to the competent
                                                    courts within the meaning of Clause 18.</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties accept that the data subject may be
                                        represented by a not-for-profit body, organisation or association under
                                        the conditions set out in Article&nbsp;80(1) of Regulation (EU)
                                        2016/679.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall abide by a decision that is
                                        binding under the applicable EU or Member State law.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(f)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees that the choice made by
                                        the data subject will not prejudice his/her substantive and procedural
                                        rights to seek remedies in accordance with applicable laws.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e1517-37-1" className="oj-ti-grseq-1">
                            <span>Clause 12</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-055-0055" className="oj-ti-grseq-1">
                            <span>Liability</span>
                        </p>

                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Each Party shall be liable to the other Party/ies
                                        for any damages it causes the other Party/ies by any breach of these
                                        Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall be liable to the data
                                        subject, and the data subject shall be entitled to receive compensation,
                                        for any material or non-material damages the data importer or its
                                        sub-processor causes the data subject by breaching the third-party
                                        beneficiary rights under these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Notwithstanding paragraph (b), the data exporter
                                        shall be liable to the data subject, and the data subject shall be
                                        entitled to receive compensation, for any material or non-material
                                        damages the data exporter or the data importer (or its sub-processor)
                                        causes the data subject by breaching the third-party beneficiary rights
                                        under these Clauses. This is without prejudice to the liability of the
                                        data exporter and, where the data exporter is a processor acting on
                                        behalf of a controller, to the liability of the controller under
                                        Regulation (EU) 2016/679 or Regulation (EU) 2018/1725, as
                                        applicable.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties agree that if the data exporter is held
                                        liable under paragraph (c) for damages caused by the data importer (or
                                        its sub-processor), it shall be entitled to claim back from the data
                                        importer that part of the compensation corresponding to the data
                                        importer’s responsibility for the damage.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Where more than one Party is responsible for any
                                        damage caused to the data subject as a result of a breach of these
                                        Clauses, all responsible Parties shall be jointly and severally liable
                                        and the data subject is entitled to bring an action in court against any
                                        of these Parties.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(f)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties agree that if one Party is held liable
                                        under paragraph (e), it shall be entitled to claim back from the other
                                        Party/ies that part of the compensation corresponding to its/their
                                        responsibility for the damage.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(g)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer may not invoke the conduct of a
                                        sub-processor to avoid its own liability.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e1613-37-1" className="oj-ti-grseq-1">
                            <span>Clause 13</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-056-0056" className="oj-ti-grseq-1">
                            <span>Supervision</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">[Where the data exporter is established in an EU
                                        Member State:] The supervisory authority with responsibility for
                                        ensuring compliance by the data exporter with Regulation (EU) 2016/679
                                        as regards the data transfer, as indicated in Annex I.C, shall act as
                                        competent supervisory authority.</p>
                                    <p className="oj-normal">[Where the data exporter is not established in an
                                        EU Member State, but falls within the territorial scope of application
                                        of Regulation (EU) 2016/679 in accordance with its Article&nbsp;3(2) and
                                        has appointed a representative pursuant to Article&nbsp;27(1) of
                                        Regulation (EU) 2016/679:] The supervisory authority of the Member State
                                        in which the representative within the meaning of Article&nbsp;27(1) of
                                        Regulation (EU) 2016/679 is established, as indicated in Annex I.C,
                                        shall act as competent supervisory authority.</p>
                                    <p className="oj-normal">[Where the data exporter is not established in an
                                        EU Member State, but falls within the territorial scope of application
                                        of Regulation (EU) 2016/679 in accordance with its Article&nbsp;3(2)
                                        without however having to appoint a representative pursuant to
                                        Article&nbsp;27(2) of Regulation (EU) 2016/679:] The supervisory
                                        authority of one of the Member States in which the data subjects whose
                                        personal data is transferred under these Clauses in relation to the
                                        offering of goods or services to them, or whose behaviour is monitored,
                                        are located, as indicated in Annex I.C, shall act as competent
                                        supervisory authority.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to submit itself to the
                                        jurisdiction of and cooperate with the competent supervisory authority
                                        in any procedures aimed at ensuring compliance with these Clauses. In
                                        particular, the data importer agrees to respond to enquiries, submit to
                                        audits and comply with the measures adopted by the supervisory
                                        authority, including remedial and compensatory measures. It shall
                                        provide the supervisory authority with written confirmation that the
                                        necessary actions have been taken.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            SECTION III – LOCAL LAWS AND OBLIGATIONS
                            IN CASE OF ACCESS BY PUBLIC AUTHORITIES
                        </Typography>
                        <p id="d1e1654-37-1" className="oj-ti-grseq-1">
                            <span>Clause 14</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-058-0058" className="oj-ti-grseq-1">
                            <span>Local laws and practices affecting compliance with the Clauses</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties warrant that they have no reason to
                                        believe that the laws and practices in the third country of destination
                                        applicable to the processing of the personal data by the data importer,
                                        including any requirements to disclose personal data or measures
                                        authorising access by public authorities, prevent the data importer from
                                        fulfilling its obligations under these Clauses. This is based on the
                                        understanding that laws and practices that respect the essence of the
                                        fundamental rights and freedoms and do not exceed what is necessary and
                                        proportionate in a democratic society to safeguard one of the objectives
                                        listed in Article&nbsp;23(1) of Regulation (EU) 2016/679, are not in
                                        contradiction with these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties declare that in providing the warranty
                                        in paragraph (a), they have taken due account in particular of the
                                        following elements:</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(i)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the specific circumstances of the
                                                    transfer, including the length of the processing chain, the
                                                    number of actors involved and the transmission channels
                                                    used; intended onward transfers; the type of recipient; the
                                                    purpose of processing; the categories and format of the
                                                    transferred personal data; the economic sector in which the
                                                    transfer occurs; the storage location of the data
                                                    transferred;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(ii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the laws and practices of the third
                                                    country of destination– including those requiring the
                                                    disclosure of data to public authorities or authorising
                                                    access by such authorities – relevant in light of the
                                                    specific circumstances of the transfer, and the applicable
                                                    limitations and safeguards&nbsp;<a
                                                        id="ntc12-L_2021199EN.01003701-E0012"
                                                        href="#ntr12-L_2021199EN.01003701-E0012">(<span
                                                        className="oj-super oj-note-tag">12</span>)</a>;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(iii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">any relevant contractual, technical or
                                                    organisational safeguards put in place to supplement the
                                                    safeguards under these Clauses, including measures applied
                                                    during transmission and to the processing of the personal
                                                    data in the country of destination.</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer warrants that, in carrying out
                                        the assessment under paragraph (b), it has made its best efforts to
                                        provide the data exporter with relevant information and agrees that it
                                        will continue to cooperate with the data exporter in ensuring compliance
                                        with these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties agree to document the assessment under
                                        paragraph (b) and make it available to the competent supervisory
                                        authority on request.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to notify the data
                                        exporter promptly if, after having agreed to these Clauses and for the
                                        duration of the contract, it has reason to believe that it is or has
                                        become subject to laws or practices not in line with the requirements
                                        under paragraph (a), including following a change in the laws of the
                                        third country or a measure (such as a disclosure request) indicating an
                                        application of such laws in practice that is not in line with the
                                        requirements in paragraph (a).</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(f)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Following a notification pursuant to paragraph (e),
                                        or if the data exporter otherwise has reason to believe that the data
                                        importer can no longer fulfil its obligations under these Clauses, the
                                        data exporter shall promptly identify appropriate measures (e.g.
                                        technical or organisational measures to ensure security and
                                        confidentiality) to be adopted by the data exporter and/or data importer
                                        to address the situation. The data exporter shall suspend the
                                        data transfer if it considers that no appropriate safeguards for such
                                        transfer can be ensured, or if instructed by the competent supervisory authority to do so. In this
                                        case, the data exporter shall be entitled to terminate the contract,
                                        insofar as it concerns the processing of personal data under these
                                        Clauses. If the contract involves more than two Parties, the data
                                        exporter may exercise this right to termination only with respect to the
                                        relevant Party, unless the Parties have agreed otherwise. Where the
                                        contract is terminated pursuant to this Clause, Clause 16(d) and (e)
                                        shall apply.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e1739-37-1" className="oj-ti-grseq-1">
                            <span>Clause 15</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-059-0059" className="oj-ti-grseq-1">
                            <span>Obligations of the data importer in case of access by public authorities</span>
                        </p>
                        <p className="oj-ti-grseq-1" id="d1e1763-37-1">15.1&nbsp;&nbsp;&nbsp;<span
                           >Notification</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to notify the data
                                        exporter and, where possible, the data subject promptly (if necessary
                                        with the help of the data exporter) if it:</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(i)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">receives a legally binding request from
                                                    a public authority, including judicial authorities, under
                                                    the laws of the country of destination for the disclosure of
                                                    personal data transferred pursuant to these Clauses; such
                                                    notification shall include information about the personal
                                                    data requested, the requesting authority, the legal basis
                                                    for the request and the response provided; or</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(ii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">becomes aware of any direct access by
                                                    public authorities to personal data transferred pursuant to
                                                    these Clauses in accordance with the laws of the country of
                                                    destination; such notification shall include all information
                                                    available to the importer.</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">If the data importer is prohibited from notifying
                                        the data exporter and/or the data subject under the laws of the country
                                        of destination, the data importer agrees to use its best efforts to
                                        obtain a waiver of the prohibition, with a view to communicating as much
                                        information as possible, as soon as possible. The data importer agrees
                                        to document its best efforts in order to be able to demonstrate them on
                                        request of the data exporter.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Where permissible under the laws of the country of
                                        destination, the data importer agrees to provide the data exporter, at
                                        regular intervals for the duration of the contract, with as much
                                        relevant information as possible on the requests received (in
                                        particular, number of requests, type of data requested, requesting
                                        authority/ies, whether requests have been challenged and the outcome of
                                        such challenges, etc.).</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to preserve the
                                        information pursuant to paragraphs (a) to (c) for the duration of the
                                        contract and make it available to the competent supervisory authority on
                                        request.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Paragraphs (a) to (c) are without prejudice to the
                                        obligation of the data importer pursuant to Clause 14(e) and Clause 16
                                        to inform the data exporter promptly where it is unable to comply with
                                        these Clauses.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className="oj-ti-grseq-1" id="d1e1819-37-1">15.2&nbsp;&nbsp;&nbsp;<span
                           >Review of legality and data minimisation</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to review the legality of
                                        the request for disclosure, in particular whether it remains within the
                                        powers granted to the requesting public authority, and to challenge the
                                        request if, after careful assessment, it concludes that there are
                                        reasonable grounds to consider that the request is unlawful under the
                                        laws of the country of destination, applicable obligations under
                                        international law and principles of international comity. The data
                                        importer shall, under the same conditions, pursue possibilities of
                                        appeal. When challenging a request, the data importer shall seek interim
                                        measures with a view to suspending the effects of the request until the
                                        competent judicial authority has decided on its merits. It shall not
                                        disclose the personal data requested until required to do so under the
                                        applicable procedural rules. These requirements are without prejudice to
                                        the obligations of the data importer under Clause 14(e).</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to document its legal
                                        assessment and any challenge to the request for disclosure and, to the
                                        extent permissible under the laws of the country of destination, make
                                        the documentation available to the data exporter. It shall also make it
                                        available to the competent supervisory authority on request.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer agrees to provide the minimum
                                        amount of information permissible when responding to a request for
                                        disclosure, based on a reasonable interpretation of the request.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            SECTION IV – FINAL PROVISIONS
                        </Typography>
                        <p id="d1e1852-37-1" className="oj-ti-grseq-1">
                            <span>Clause 16</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-063-0063" className="oj-ti-grseq-1">
                            <span>Non-compliance with the Clauses and termination</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data importer shall promptly inform the data
                                        exporter if it is unable to comply with these Clauses, for whatever
                                        reason.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">In the event that the data importer is in breach of
                                        these Clauses or unable to comply with these Clauses, the data exporter
                                        shall suspend the transfer of personal data to the data importer until
                                        compliance is again ensured or the contract is terminated. This is
                                        without prejudice to Clause 14(f).</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The data exporter shall be entitled to terminate
                                        the contract, insofar as it concerns the processing of personal data
                                        under these Clauses, where:</p>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(i)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the data exporter has suspended the
                                                    transfer of personal data to the data importer pursuant to
                                                    paragraph (b) and compliance with these Clauses is not
                                                    restored within a reasonable time and in any event within
                                                    one month of suspension;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(ii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the data importer is in substantial or
                                                    persistent breach of these Clauses; or</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <colgroup>
                                            <col width="4%" />
                                                <col width="96%" />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td valign="top">
                                                <p className="oj-normal">(iii)</p>
                                            </td>
                                            <td valign="top">
                                                <p className="oj-normal">the data importer fails to comply with
                                                    a binding decision of a competent court or supervisory
                                                    authority regarding its obligations under these Clauses.</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p className="oj-normal">In these cases, it shall inform the competent
                                        supervisory authority of such
                                        non-compliance. Where the contract involves more than two Parties, the
                                        data exporter may exercise this right to termination only with respect
                                        to the relevant Party, unless the Parties have agreed otherwise.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The
                                        data importer shall certify the deletion of the data to the data
                                        exporter. Until the data is deleted or returned, the data importer shall
                                        continue to ensure compliance with these Clauses. In case of local laws
                                        applicable to the data importer that prohibit the return or deletion of
                                        the transferred personal data, the data importer warrants that it will
                                        continue to ensure compliance with these Clauses and will only process
                                        the data to the extent and for as long as required under that local
                                        law.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(e)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Either Party may revoke its agreement to be bound
                                        by these Clauses where (i) the European Commission adopts a decision
                                        pursuant to Article&nbsp;45(3) of Regulation (EU) 2016/679 that covers
                                        the transfer of personal data to which these Clauses apply; or (ii)
                                        Regulation (EU) 2016/679 becomes part of the legal framework of the
                                        country to which the personal data is transferred. This is without
                                        prejudice to other obligations applying to the processing in question
                                        under Regulation (EU) 2016/679.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p id="d1e1915-37-1" className="oj-ti-grseq-1">
                            <span>Clause 17</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-064-0064" className="oj-ti-grseq-1">
                            <span>Governing law</span>
                        </p>
                        <p className="oj-normal">These Clauses shall be governed by the law of one of
                            the EU Member States, provided such law allows for third-party beneficiary rights.
                            The Parties agree that this shall be the law of Germany.</p>

                        <p id="d1e1952-37-1" className="oj-ti-grseq-1">
                            <span>Clause 18</span>
                        </p>
                        <p id="L_2021199EN.01003701-g-065-0065" className="oj-ti-grseq-1">
                            <span>Choice of forum and jurisdiction</span>
                        </p>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(a)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">Any dispute arising from these Clauses shall be
                                        resolved by the courts of an EU Member State.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(b)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties agree that those shall be the courts of Nuremberg, Germany.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(c)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">A data subject may also bring legal proceedings
                                        against the data exporter and/or data importer before the courts of the
                                        Member State in which he/she has his/her habitual residence.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col width="4%" />
                                    <col width="96%" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p className="oj-normal">(d)</p>
                                </td>
                                <td valign="top">
                                    <p className="oj-normal">The Parties agree to submit themselves to the
                                        jurisdiction of such courts.</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <hr className="oj-note" />
                            <p className="oj-note">
                                <a id="ntr1-L_2021199EN.01003701-E0001" href="#ntc1-L_2021199EN.01003701-E0001">(<span
                                    className="oj-super">1</span>)</a>&nbsp;&nbsp;Where the data exporter is a
                                processor subject to Regulation (EU) 2016/679 acting on behalf of a Union
                                institution or body as controller, reliance on these Clauses when engaging
                                another processor (sub-processing) not subject to Regulation (EU) 2016/679 also
                                ensures compliance with Article&nbsp;29(4) of Regulation (EU) 2018/1725 of the
                                European Parliament and of the Council of 23&nbsp;October 2018 on the protection
                                of natural persons with regard to the processing of personal data by the Union
                                institutions, bodies, offices and agencies and on the free movement of such
                                data, and repealing Regulation (EC) No&nbsp;45/2001 and Decision
                                No&nbsp;1247/2002/EC (<a
                                href="./../../../../legal-content/EN/AUTO/?uri=OJ:L:2018:295:TOC">OJ L&nbsp;295,
                                21.11.2018, p.&nbsp;39</a>), to the extent these Clauses and the data protection
                                obligations as set out in the contract or other legal act between the controller
                                and the processor pursuant to Article&nbsp;29(3) of Regulation (EU) 2018/1725
                                are aligned. This will in particular be the case where the controller and
                                processor rely on the standard contractual clauses included in Decision
                                2021/915.</p>
                            <p className="oj-note">
                                <a id="ntr2-L_2021199EN.01003701-E0002" href="#ntc2-L_2021199EN.01003701-E0002">(<span
                                    className="oj-super">2</span>)</a>&nbsp;&nbsp;This requires rendering the
                                data anonymous in such a way that the individual is no longer identifiable by
                                anyone, in line with recital 26 of Regulation (EU) 2016/679, and that this
                                process is irreversible.</p>
                            <p className="oj-note">
                                <a id="ntr3-L_2021199EN.01003701-E0003" href="#ntc3-L_2021199EN.01003701-E0003">(<span
                                    className="oj-super">3</span>)</a>&nbsp;&nbsp;The Agreement on the European
                                Economic Area (EEA Agreement) provides for the extension of the European Union’s
                                internal market to the three EEA States Iceland, Liechtenstein and Norway. The
                                Union data protection legislation, including Regulation (EU) 2016/679, is
                                covered by the EEA Agreement and has been incorporated into Annex XI thereto.
                                Therefore, any disclosure by the data importer to a third party located in the
                                EEA does not qualify as an onward transfer for the purpose of these Clauses.</p>
                            <p className="oj-note">
                                <a id="ntr4-L_2021199EN.01003701-E0004" href="#ntc4-L_2021199EN.01003701-E0004">(<span
                                    className="oj-super">4</span>)</a>&nbsp;&nbsp;The Agreement on the European
                                Economic Area (EEA Agreement) provides for the extension of the European Union’s
                                internal market to the three EEA States Iceland, Liechtenstein and Norway. The
                                Union data protection legislation, including Regulation (EU) 2016/679, is
                                covered by the EEA Agreement and has been incorporated into Annex XI thereto.
                                Therefore, any disclosure by the data importer to a third party located in the
                                EEA does not qualify as an onward transfer for the purpose of these Clauses.</p>
                            <p className="oj-note">
                                <a id="ntr5-L_2021199EN.01003701-E0005" href="#ntc5-L_2021199EN.01003701-E0005">(<span
                                    className="oj-super">5</span>)</a>&nbsp;&nbsp;See Article&nbsp;28(4) of
                                Regulation (EU) 2016/679 and, where the controller is an EU institution or body,
                                Article&nbsp;29(4) of Regulation (EU) 2018/1725.</p>
                            <p className="oj-note">
                                <a id="ntr6-L_2021199EN.01003701-E0006" href="#ntc6-L_2021199EN.01003701-E0006">(<span
                                    className="oj-super">6</span>)</a>&nbsp;&nbsp;The Agreement on the European
                                Economic Area (EEA Agreement) provides for the extension of the European Union’s
                                internal market to the three EEA States Iceland, Liechtenstein and Norway. The
                                Union data protection legislation, including Regulation (EU) 2016/679, is
                                covered by the EEA Agreement and has been incorporated into Annex XI thereto.
                                Therefore, any disclosure by the data importer to a third party located in the
                                EEA does not qualify as an onward transfer for the purposes of these Clauses.
                            </p>
                            <p className="oj-note">
                                <a id="ntr7-L_2021199EN.01003701-E0007" href="#ntc7-L_2021199EN.01003701-E0007">(<span
                                    className="oj-super">7</span>)</a>&nbsp;&nbsp;This includes whether the
                                transfer and further processing involves personal data revealing racial or
                                ethnic origin, political opinions, religious or philosophical beliefs, or trade
                                union membership, genetic data or biometric data for the purpose of uniquely
                                identifying a natural person, data concerning health or a person’s sex life or
                                sexual orientation, or data relating to criminal convictions or offences.</p>
                            <p className="oj-note">
                                <a id="ntr8-L_2021199EN.01003701-E0008" href="#ntc8-L_2021199EN.01003701-E0008">(<span
                                    className="oj-super">8</span>)</a>&nbsp;&nbsp;This requirement may be
                                satisfied by the sub-processor acceding to these Clauses under the appropriate
                                Module, in accordance with Clause 7.</p>
                            <p className="oj-note">
                                <a id="ntr9-L_2021199EN.01003701-E0009" href="#ntc9-L_2021199EN.01003701-E0009">(<span
                                    className="oj-super">9</span>)</a>&nbsp;&nbsp;This requirement may be
                                satisfied by the sub-processor acceding to these Clauses under the appropriate
                                Module, in accordance with Clause 7.</p>
                            <p className="oj-note">
                                <a id="ntr10-L_2021199EN.01003701-E0010"
                                   href="#ntc10-L_2021199EN.01003701-E0010">(<span
                                    className="oj-super">10</span>)</a>&nbsp;&nbsp;That period may be extended
                                by a maximum of two more months, to the extent necessary taking into account the
                                complexity and number of requests. The data importer shall duly and promptly
                                inform the data subject of any such extension.</p>
                            <p className="oj-note">
                                <a id="ntr11-L_2021199EN.01003701-E0011"
                                   href="#ntc11-L_2021199EN.01003701-E0011">(<span
                                    className="oj-super">11</span>)</a>&nbsp;&nbsp;The data importer may offer
                                independent dispute resolution through an arbitration body only if it is
                                established in a country that has ratified the New York Convention on
                                Enforcement of Arbitration Awards.</p>
                            <p className="oj-note">
                                <a id="ntr12-L_2021199EN.01003701-E0012"
                                   href="#ntc12-L_2021199EN.01003701-E0012">(<span
                                    className="oj-super">12</span>)</a>&nbsp;&nbsp;As regards the impact of such
                                laws and practices on compliance with these Clauses, different elements may be
                                considered as part of an overall assessment. Such elements may include relevant
                                and documented practical experience with prior instances of requests for
                                disclosure from public authorities, or the absence of such requests, covering a
                                sufficiently representative time-frame. This refers in particular to internal
                                records or other documentation, drawn up on a continuous basis in accordance
                                with due diligence and certified at senior management level, provided that this
                                information can be lawfully shared with third parties. Where this practical
                                experience is relied upon to conclude that the data importer will not be
                                prevented from complying with these Clauses, it needs to be supported by other
                                relevant, objective elements, and it is for the Parties to consider carefully
                                whether these elements together carry sufficient weight, in terms of their
                                reliability and representativeness, to support this conclusion. In particular,
                                the Parties have to take into account whether their practical experience is
                                corroborated and not contradicted by publicly available or otherwise accessible,
                                reliable information on the existence or absence of requests within the same
                                sector and/or the application of the law in practice, such as case law and
                                reports by independent oversight bodies.</p>


                        <hr className="oj-doc-sep" />
                        <div id="L_2021199EN.01005701">
                            <div>
                                <p className="oj-doc-ti" id="d1e32-57-1">APPENDIX</p>
                                <p className="oj-normal">EXPLANATORY NOTE:</p>
                                <p className="oj-normal">It must be possible to clearly distinguish the information
                                    applicable to each transfer or category of transfers and, in this regard, to
                                    determine the respective role(s) of the Parties as data exporter(s) and/or data
                                    importer(s). This does not necessarily require completing and signing separate
                                    appendices for each transfer/category of transfers and/or contractual relationship,
                                    where this transparency can achieved through one appendix. However, where necessary
                                    to ensure sufficient clarity, separate appendices should be used.</p>
                            </div>
                        </div>

                        <hr className="oj-doc-sep" />
                        <div id="L_2021199EN.01005801">
                            <div>

                                <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                                    ANNEX I
                                </Typography>
                                <p className="oj-ti-grseq-1" id="d1e38-58-1">A.&nbsp;&nbsp;&nbsp;<span
                                    className="oj-bold">LIST OF PARTIES</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-bold">Data exporter(s):</span>
                                </p>
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <colgroup>
                                        <col width="4%" />
                                            <col width="4%" />
                                                <col width="92%" />
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td valign="top">
                                        </td>
                                        <td valign="top">
                                            <p className="oj-normal">1.</p>
                                        </td>
                                        <td valign="top">
                                            <span>Name: Customer (as defined in the DPA)</span>
                                            <p className="oj-normal">Address: Customer's address (as provided during the registration process or order flow)</p>
                                            <p className="oj-normal">Contact person’s name, position and contact
                                                details: Customer's contact details (as provided during the registration process or order flow)</p>
                                            <p className="oj-normal">Role (controller/processor): Controller</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p className="oj-normal">
                                    <span className="oj-bold">Data importer(s):</span>
                                </p>
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <colgroup>
                                        <col width="4%" />
                                            <col width="4%" />
                                                <col width="92%" />
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td valign="top">
                                        </td>
                                        <td valign="top">
                                            <p className="oj-normal">1.</p>
                                        </td>
                                        <td valign="top">
                                            <span>Name: esaqa GmbH</span>
                                            <p className="oj-normal">Address: Tiergartenstr. 13, 91247 Vorra Germany</p>
                                            <p className="oj-normal">Contact person’s name, position and contact
                                                details: Sascha Pfeiffer, CEO, support@esaqa.com</p>
                                            <p className="oj-normal">Role (controller/processor): Processor</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p className="oj-ti-grseq-1" id="d1e111-58-1">B.&nbsp;&nbsp;&nbsp;<span
                                    className="oj-bold">DESCRIPTION OF TRANSFER</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">Categories of data subjects whose personal data is transferred</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- Employees and / or contractors of Customer</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">Categories of personal data transferred</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- Account information (e.g. name, email address, telephone)</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- Other information (e.g. job title, gender, date of birth)</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- Device information (e.g. IP address, MAC address, browser agent)</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">Sensitive data transferred (if applicable) and applied restrictions or safeguards that fully take into consideration the nature of the data and the risks involved, such as for instance strict purpose limitation, access restrictions (including access only for staff having followed specialised training), keeping a record of access to the data, restrictions for onward transfers or additional security measures.</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- There is no sensitive data as defined in 8.7 transfered.</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">The frequency of the transfer (e.g. whether the data is transferred on a one-off or continuous basis).</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- On a continuous basis</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">Nature of the processing</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- Account information is used to administer accounts and provide user support; End User data is used to provide the Services (e.g. autofill, password generation, ...)</span>
                                </p>
                                <p className="oj-normal">
                                    <span
                                        className="oj-italic">Purpose(s) of the data transfer and further processing</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- To provide Services and support</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">The period for which the personal data will be retained, or, if that is not possible, the criteria used to determine that period</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- Throughout the Terms of the Agreement plus the period from expiry of the Terms until deletion of Personal Data by esaqa in accordance with the Agreement.</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">For transfers to (sub-) processors, also specify subject matter, nature and duration of the processing</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- A list of all subprocessors and their processors can be found here <Link color="inherit" href="/subprocessor/">https://consoles.easa.com/subprocessor/</Link></span>
                                </p>
                                <p className="oj-ti-grseq-1" id="d1e181-58-1">C.&nbsp;&nbsp;&nbsp;<span
                                    className="oj-bold">COMPETENT SUPERVISORY AUTHORITY</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">Identify the competent supervisory authority/ies in accordance with Clause 13</span>
                                </p>
                                <p className="oj-normal">
                                    <span className="oj-italic">- The Bavarian Data Protection Commissioner Source: <Link color="inherit" href="https://www.datenschutz-bayern.de/">https://www.datenschutz-bayern.de</Link> </span>
                                </p>
                            </div>
                        </div>



                        <hr className="oj-doc-sep" />
                        <div id="L_2021199EN.01006001">
                            <div>
                                <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                                    ANNEX II
                                </Typography>
                                <p id="d1e38-60-1" className="oj-ti-grseq-1">
                                    <span className="oj-bold">TECHNICAL AND ORGANISATIONAL MEASURES INCLUDING TECHNICAL AND ORGANISATIONAL MEASURES TO ENSURE THE SECURITY OF THE DATA</span>
                                </p>
                                <p className="oj-normal">esaqa's Technical Organizational Measures can be found here <Link color="inherit" href="/tom/">https://console.esaqa.com/tom/</Link></p>
                            </div>
                        </div>


                        <hr className="oj-doc-sep" />
                        <div id="L_2021199EN.01006101">
                            <div>
                                <Typography component="h3" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                                    ANNEX III
                                </Typography>
                                <p id="d1e38-61-1" className="oj-ti-grseq-1">
                                    <span className="oj-bold">LIST OF SUB-PROCESSORS</span>
                                </p>
                                <p className="oj-normal">esaqa's List of Subprocessors can be found here <Link color="inherit" href="/subprocessor/">https://console.esaqa.com/subprocessor/</Link></p>
                            </div>
                        </div>


                        <Typography style={{ marginTop: "20px", marginBottom: "20px" }}>
                            Last updated December 17th 2022
                        </Typography>


                    </div>
                </div>
                <Box mt={2}>
                    <Copyright />
                </Box>
            </Container>
        );
}

export default DPAView;
