import apiServer from "./api-server";

/**
 * Returns all available psono saas regions
 *
 * @param projectId
 *
 * @returns {Promise<Array>}
 */
function getPsonoSaasRegions(projectId) {
    return new Promise((resolve, reject) => {
        return apiServer.readPsonoSaasRegions(projectId).then(
            (response) => {
                return resolve(response.data.psono_saas_regions);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_77db3b52-7104-480a-962a-9c785ecbbc1c");
                reject(response);
            }
        );
    });
}

const service = {
    getPsonoSaasRegions,
};

export default service;
