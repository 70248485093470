/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import billingAccountService from "../services/billing-accounts";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
}));

function BillingAccountSelectField(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [billingAccounts, setBillingAccounts] = useState([]);

    const { fullWidth, variant, margin, size, helperText, error, required, onChange, value, className } = props;

    React.useEffect(() => {
        billingAccountService.getBillingAccounts().then(
            (billingAccounts) => {
                setBillingAccounts(billingAccounts);
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);

    let defaulValue = null;
    if (value && billingAccounts && billingAccounts.length) {
        defaulValue = billingAccounts.find(function (billingAccount) {
            return billingAccount.id === value;
        });
    }

    return (
        <Autocomplete
            options={billingAccounts}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => {
                return option ? option.name : "";
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {option.name}
                </li>
            )}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            value={defaulValue}
            renderInput={(params) => (
                <TextField
                    className={className}
                    {...params}
                    label={t("BILLING_ACCOUNT")}
                    required={required}
                    margin={margin}
                    size={size}
                    variant={variant}
                    helperText={helperText}
                    error={error}
                    fullWidth={fullWidth}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );

}

BillingAccountSelectField.defaultProps = {
    error: false,
};

BillingAccountSelectField.propTypes = {
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    size: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default BillingAccountSelectField;
