/**
 * Store service
 */

import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import { persistReducer, createMigrate } from "redux-persist";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import {
    SET_REQUESTS_IN_PROGRESS,
} from "../redux/action-types";

import rootReducer from "../redux/reducer";
import storageService from "./storage";


let store

export const initStore = async () => {
    const config = {
        channel: "redux_state_sync",
        blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    };

    const middlewares = [thunkMiddleware, createStateSyncMiddleware(config)];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        const loggerMiddleware = createLogger({
            predicate: (getState, action) => action.type !== SET_REQUESTS_IN_PROGRESS
        });
        middlewares.push(loggerMiddleware);
    }

    const migrations = {}

    const persistConfig = {
        key: 'consolePersist',
        blacklist: ['transient'],
        version: 0,
        storage: storageService.get('state'),
        debug: false,
        migrate: createMigrate(migrations, { debug: false }),
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    store = createStore(persistedReducer, applyMiddleware(...middlewares));

    initMessageListener(store);

    return store;
};

export const getStore = () => {
    return store;
}
