import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

function ContactDetails(props) {

    const { t } = useTranslation();

    return (<Typography {...props}>
        esaqa GmbH
        <br />
        Tiergartenstr. 13
        <br />
        91247 Vorra
        <br />
        {t("GERMANY")}
        <br />
        <br />
        {t("MANAGING_DIRECTOR")}: Sascha Pfeiffer
        <br />
        {t("COMMERCIAL_REGISTER")}: {t("REGISTRY_COURT_NUREMBERG")} HRB 37978
        <br />
        {t("VAT_REG_NO")}: DE333156173
        <br />
        {t("EMAIL")}: support@esaqa.com
    </Typography>)
}

export default ContactDetails