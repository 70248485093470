import apiServer from "./api-server";

let countriesSingleton;


/**
 * GET: Returns all countries
 *
 * @returns {Promise<Array>}
 */
async function getCountries() {
    if (!countriesSingleton) {
        countriesSingleton = apiServer.readCountries();
    }

    const response = await countriesSingleton;
    return response.data.countries
}

/**
 * GET: Returns all european countries
 *
 * @returns {Promise<Object>}
 */
async function getEuropeanCountries() {
    if (!countriesSingleton) {
        countriesSingleton = apiServer.readCountries();
    }

    const response = await countriesSingleton;
    return response.data.european_countries;
}

/**
 * GET: Returns all european countries
 *
 * @returns {Promise<Object>}
 */
async function getAllowedCountries() {
    if (!countriesSingleton) {
        countriesSingleton = apiServer.readCountries();
    }
    const response = await countriesSingleton;
    return response.data.allowed_countries;
}

/**
 * GET: Returns all Paddle countries
 *
 * @returns {Promise<Object>}
 */
async function getPaddleCountries() {
    if (!countriesSingleton) {
        countriesSingleton = apiServer.readCountries();
    }
    const response = await countriesSingleton;
    return response.data.paddle_countries;
}

/**
 * GET: Returns all Stripe countries
 *
 * @returns {Promise<Object>}
 */
async function getStripeCountries() {
    if (!countriesSingleton) {
        countriesSingleton = apiServer.readCountries();
    }
    const response = await countriesSingleton;
    return response.data.stripe_countries;
}

const service = {
    getCountries,
    getEuropeanCountries,
    getAllowedCountries,
    getPaddleCountries,
    getStripeCountries,
};

export default service;
