import {
    SET_PROJECT,
    LOGOUT,
    SET_REQUESTS_IN_PROGRESS,
} from "./action-types";

function setProject(project) {
    return (dispatch) => {
        dispatch({
            type: SET_PROJECT,
            project,
        });
    };
}


function logout() {
    return (dispatch) => {
        dispatch({
            type: LOGOUT,
        });
    };
}


function setRequestsInProgress(requestCounterOpen, requestCounterClosed) {
    return (dispatch) => {
        dispatch({
            type: SET_REQUESTS_IN_PROGRESS,
            requestCounterOpen: requestCounterOpen,
            requestCounterClosed: requestCounterClosed,
        });
    };
}

const actionCreators = {
    setProject,
    logout,
    setRequestsInProgress,
};

export default actionCreators;
